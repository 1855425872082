import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import './MatchShare.css';

function useQuery() {
  const { search } = useLocation();
  console.log(search);
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MatchShare = () => {
  let query = useQuery();
  let bookingId = query.get("bookingId");


  useEffect(() => {
    const OSplatform = navigator.userAgent;
    try {
      window.location.replace(`com.justpadel.courtsofchoice://?path=splitpay&bookingId=${bookingId}`);
      // window.location.replace(`justpadel://?path=singlepost&postId=${postId}`);
      // window.location.replace(`exp://192.168.0.127:19000?path=singlepost&postId=${postId}`);
      
    if(OSplatform.includes('Windows') || OSplatform.includes('Linux')){
        setTimeout("window.location = 'https://play.google.com/store/apps/details?id=com.justpadel.courtsofchoice';", 2000);
    }else if (OSplatform.includes('Macintosh') || OSplatform.includes('iPhone')) {
        setTimeout("window.location = 'https://apps.apple.com/us/app/just-padel-courts-of-choice/id1587271749';", 2000);
    }else {
        setTimeout("window.location = 'https://play.google.com/store/apps/details?id=com.justpadel.courtsofchoice';", 2000);
    }
    } catch (error) {
      console.log("Errror");
    }
  }, []);

  return (
    <div className="appshare_container">
      <div className="inner-container">
        <h2>Justpadel for android and ios</h2>
        <h3>Download our app</h3>
        <div className="png-container">
          <a href="https://play.google.com/store/apps/details?id=com.justpadel.courtsofchoice">
            <img
              src="https://justpadel-courtimages.s3.amazonaws.com/android.png"
              alt="android.png"
              width="200"
              height="70"
            />
          </a>
          <a href="https://apps.apple.com/us/app/just-padel-courts-of-choice/id1587271749">
            <img
              src="https://justpadel-courtimages.s3.amazonaws.com/apple.jpg"
              alt="android.png"
              width="200"
              height="62"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MatchShare;
