import React, { useState, useEffect } from "react";
import "../CustomerDetailes/CustomerDetails.css";
import { LoginMethod, RegistrationMethod, VerifyEmail } from "../HOC/logic";
import { useDispatch } from "react-redux";
import { loginUser } from "../Redux/Action/index";
import "./Modal.css";
import { AiFillApple } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import AppleSignin from "react-apple-signin-auth";
import { environment } from "../../env";
import axios from "../../CustomAxios";
import oldaxios from "axios";
import { AiFillFacebook } from "react-icons/ai";
import { GetUserDetails } from "../HOC/logicWithCustomAxios";
// import { FacebookProvider, LoginButton } from "react-facebook";
import ReactGA from "react-ga";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import swal from "sweetalert";

const ModalComponent = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [revealpwd, setRevealpwd] = useState(false);
  const [loginDetails, setloginDetails] = useState({
    loginEmail: "",
    loginPassword: "",
  });
  const history = useHistory();
  const [loginError, setLoginError] = useState({
    ErrorMessage: "Please Provide Valid Credentials",
    error: false,
  });
  const [registrationDetails, setRegistrationDetails] = useState({
    first_name: "",
    email: "",
    phone_no: "",
    password: "",
    cnf_password: "",
    source: "website",
  });
  const [registrationSuccessMessage, setRegistrationSuccessMessage] = useState({
    SuccessMessage: "Registration Successful. Login Now",
    success: false,
  });
  const [emailExistsMessage, setEmailExistsMessage] = useState({
    Message: "Email is already exist",
    error: false,
  });
  const [value1, setValue1] = useState();

  /*------------------------------Google Login Code starts here ---------------------------------------------*/
  const onLoginSuccess = (res) => {
    let UserDetails = res.profileObj;
    const validateEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { email, name } = UserDetails;
    let sourceApp = "google";
    let Gemail = "";
    if (validateEmail.test(UserDetails.email) === false) {
      Gemail = UserDetails.email + "@justpadel.com";
    } else {
      Gemail = UserDetails.email;
    }
    const checkEmailUrl = environment.baseUrl + "email/";

    let checkEmailPayload = {
      email: Gemail,
    };

    oldaxios
      .post(checkEmailUrl, checkEmailPayload)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.Message === "It is a unique email") {
            let registrationPayload = {
              source: sourceApp,
              email: Gemail,
              password: Gemail,
              first_name: UserDetails.name,
            };
            const registrationUrl =
              environment.baseUrl + "api/user/registration/";
            oldaxios
              .post(registrationUrl, registrationPayload)
              .then((response) => {
                const { statusCode, data } = response;
                if (response.status == 201) {
                  callLoginSoial(Gemail, Gemail, "google");
                } else {
                  console.log("Server error");
                }
              })
              .catch(function (error) {});
          } else {
            callLoginSoial(Gemail, Gemail, "google");
          }
        } else {
          console.log("Server error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onLoginFailure = (res) => {
    console.log("Login Failed:", res);
  };

  const onSignoutSuccess = () => {
    alert("You have been logged out successfully");
    console.clear();
  };
  /*------------------------------Google Login Code Ends Here -----------------------------------------------*/

  /*------------------------------Facebook Login Code starts Here ---------------------------------------------*/
  const componentClicked = () => {};

  const responseFacebook = (value) => {
    if (!value.email) {
      swal({
        title: "Warning",
        text: "Please Provide EmailID of Facebook",
        icon: "warning",
        button: "Ok",
      });
      return;
    }
    const validateEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const user = value;
    let FBid = "";
    const userNameToEmail = user?.name?.split(" ")[0].toLowerCase();
    if (validateEmail.test(user?.email) === false) {
      FBid = userNameToEmail + "@justpadel.com";
    } else {
      FBid = user?.email;
    }

    if (FBid) {
      let sourceApp = "facebook";
      const registrationUrl = environment.baseUrl + "api/user/registration/";
      let registrationPayload = {
        source: sourceApp,
        email: FBid,
        password: FBid,
        first_name: user?.name,
      };

      const checkEmailUrl = environment.baseUrl + "email/";

      let checkEmailPayload = {
        email: FBid,
      };

      axios
        .post(checkEmailUrl, checkEmailPayload)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.Message === "It is a unique email") {
              axios
                .post(registrationUrl, registrationPayload)
                .then((response) => {
                  const { statusCode, data } = response;
                  if (response.status == 201) {
                    callLoginSoial(FBid, FBid, "facebook");
                  } else {
                    console.log("Server error");
                  }
                })
                .catch(function (error) {
                  console.log("Error", error);
                });
            } else {
              callLoginSoial(FBid, FBid, "facebook");
            }
          } else {
            console.log("Server error");
          }
        })
        .catch(function (error) {
          console.log("Error", error);
        });
    }

    // ------------------------
  };
  /*------------------------------Facebook Login Code Ends Here -----------------------------------------------*/

  /*------------------------------Apple Login Code starts Here -----------------------------------------------*/

  /*------------------------------Apple Login Code Ends Here -------------------------------------------------*/

  /*------------------------------ This function used for handle login change  ------------------------------*/
  function handleLoginChange(event) {
    const { name, value } = event.target;
    setloginDetails({ ...loginDetails, [name]: value });
  }

  /*------------------------------ This function used for login  ------------------------------*/
  const handleLogin = (data) => {
    setLoginError({
      ErrorMessage: " ",
      error: false,
    });
    //Google Analytics
    ReactGA.event({
      category: "Login",
      action: "Sign In",
      label: "Sign In",
    });
    LoginMethod(data.email, data.password, "website").then((response) => {
      if (response.error_code === 24) {
        setLoginError({
          ErrorMessage: response.message,
          error: true,
        });
      } else if (response.error_code === 23) {
        dispatch(
          loginUser(
            response.access,
            response.role,
            response.userid,
            response.username
          )
        );
        setloginDetails({ loginEmail: "", loginPassword: "" });
        GetUserDetails(data.email).then((response) => {
          localStorage.setItem("LoggedUserDetails", JSON.stringify(response));
          props.close(!props.close);
        });
      } else {
        // setLoginError({ ErrorMessage: "Logged Successful", error: true });
        dispatch(
          loginUser(
            response.access,
            response.role,
            response.userid,
            response.username
          )
        );
        setloginDetails({ loginEmail: "", loginPassword: "" });
        GetUserDetails(data.email).then((response) => {
          localStorage.setItem("LoggedUserDetails", JSON.stringify(response));
          props.close(!props.close);
        });
        // props.close(!props.close);
      }
    });
  };
  /*------------------------------ This function used for handle login change  ------------------------------*/
  function handleRegistrationChange(event) {
    const { name, value } = event.target;
    setRegistrationDetails({ ...registrationDetails, [name]: value });
  }

  /*------------------------------ This function used for handle Registration  ------------------------------*/
  const handleRegistration = () => {
    let countrycode = countryCode.split(" ")[0];
    let phonenumber = phoneNumber.split(countrycode)[1];
    let newContact = countrycode + "-" + phonenumber;
    registrationDetails.phone_no = newContact;

    if (
      registrationDetails.first_name === "" ||
      registrationDetails.email === "" ||
      registrationDetails.phoneNumber === "" ||
      registrationDetails.password === "" ||
      phonenumber.length < 7
    ) {
      alert("Please Enter The Details Below");
    } else if (
      registrationDetails.password !== registrationDetails.cnf_password
    ) {
      alert("Please Enter the password that match correctly");
    } else {
      VerifyEmail(registrationDetails.email).then((response) => {
        if (response.data.Message === "Email already Exists") {
          setEmailExistsMessage({
            Message: "Email is already Exists",
            error: true,
          });
        } else {
          //Google Analytics
          ReactGA.event({
            category: "Register",
            action: "Sign Up",
            label: "Sign Up",
          });
          RegistrationMethod(registrationDetails)
            .then((response) => {
              if (response.message === "Request failed with status code 404") {
                setRegistrationSuccessMessage({
                  SuccessMessage:
                    "Error...While Registering, Try again after some time",
                  success: true,
                });
                setEmailExistsMessage({
                  Message: "Email is already Exists",
                  error: false,
                });
              } else if (
                response.data.message === "Registration Sucessfull Done"
              ) {
                setRegistrationSuccessMessage({
                  SuccessMessage: "Registration Successful. Login Now",
                  success: true,
                });
                setRegistrationDetails({
                  first_name: "",
                  email: "",
                  phone_no: "",
                  password: "",
                  cnf_password: "",
                  source: "website",
                });
                setEmailExistsMessage({
                  Message: "Email is already Exists",
                  error: false,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  };

  const handleCancel = (data) => {
    if (data === "registration") {
      setRegistrationDetails({
        first_name: "",
        email: "",
        phone_no: "",
        password: "",
        cnf_password: "",
        source: "website",
      });
      setRegistrationSuccessMessage({ success: false });
    } else if (data === "login") {
      setloginDetails({ loginEmail: "", loginPassword: "" });
      setLoginError({ error: false });
    } else {
      console.log("Please select proper button");
    }
  };

  const callLoginSoial = async (Email, Password, source) => {
    if (Email !== "undefined@justpadel.com") {
      await LoginMethod(Email, Password, source).then((response) => {
        if (response.error_code === 24) {
          setLoginError({
            ErrorMessage: "Please Provide Valid Credentials",
            error: true,
          });
        } else if (response.error_code === 23) {
          dispatch(
            loginUser(
              response.access,
              response.role,
              response.userid,
              response.username
            )
          );
          setloginDetails({ loginEmail: "", loginPassword: "" });
          GetUserDetails(Email).then((response) => {
            localStorage.setItem("LoggedUserDetails", JSON.stringify(response));
            props.close(!props.close);
          });
        } else {
          dispatch(
            loginUser(
              response.access,
              response.role,
              response.userid,
              response.username
            )
          );
          setloginDetails({ loginEmail: "", loginPassword: "" });
          GetUserDetails(Email).then((response) => {
            localStorage.setItem("LoggedUserDetails", JSON.stringify(response));
            props.close(!props.close);
          });
        }
      });
    }
  };

  return (
    <div>
      <div
        className="modal-wrapper"
        style={{
          transform: props.show ? "translateY(0vh)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0",
          zIndex: props.show ? "1" : "-1",
        }}
      >
        <div className="modal-header">
          <span className="close-modal-btn">Sign in to continue</span>
        </div>
        <div className="modal-body">
          <div className="Login-Registration-Form">
            <div className="width-left">
              <div className="sign-title">
                <h2>Already existing user? Login Here</h2>
              </div>
              <form
                onSubmit={handleSubmit(handleLogin)}
                className="inner-login"
              >
                <div className="inner-lhs" style={{ width: "100%" }}>
                  <div className="textarea-rhs">
                    <div className="input-feilds">
                      <label htmlFor="login-email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="text"
                        id="login-email"
                        name="email"
                        placeholder="Example@gmail.com"
                        {...register("email", { required: true })}
                      />
                      {errors.email && (
                        <span>
                          <h2 className="text-danger">Email is required</h2>
                        </span>
                      )}
                    </div>

                    <div className="input-feilds">
                      <label htmlFor="login-password" className="form-label">
                        Password
                      </label>
                      <div style={{ width: "100%" }}>
                        <div
                          className="showPassword"
                          style={{
                            display: "flex",
                            border: "1px solid #e2e2e2",
                          }}
                        >
                          <input
                            // type="password"
                            type={revealpwd ? "text" : "password"}
                            id="login-password"
                            // value={loginDetails.loginPassword}
                            name="password"
                            // onChange={handleLoginChange}
                            placeholder="********"
                            {...register("password", { required: true })}
                            style={{ width: "90%", border: "none" }}
                          />
                          <span
                            style={{
                              padding: "5px 5px 5px 10px",
                              width: "10%",
                              borderLeft: "1px solid #e2e2e2",
                            }}
                            onClick={() =>
                              setRevealpwd((prevState) => !prevState)
                            }
                          >
                            {revealpwd ? (
                              <AiOutlineEye className="icon-design" />
                            ) : (
                              <AiOutlineEyeInvisible className="icon-design" />
                            )}
                          </span>
                        </div>
                        {errors.password && (
                          <span>
                            <h2 className="text-danger">
                              {" "}
                              Password is required
                            </h2>
                          </span>
                        )}

                        <div
                          className="forget-main"
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: 10,
                          }}
                        >
                          {loginError.error === true ? (
                            <h2
                              className="text-danger"
                              style={{ width: "100%" }}
                            >
                              {loginError.ErrorMessage}
                            </h2>
                          ) : (
                            " "
                          )}
                          <Link
                            to="/validate-email"
                            className=" form-label forget-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sub-btn">
                    <button className="login" type="submit">
                      Login
                    </button>
                  </div>
                  <div className="div-or">OR</div>
                  <div className="sub-btngg">
                    <GoogleLogin
                      clientId="70525544697-l9ik35u2ks7db1tgqssve1s7r6l7psq5.apps.googleusercontent.com"
                      buttonText="Login with Google"
                      onSuccess={onLoginSuccess}
                      onFailure={onLoginFailure}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                      className="loginGoogle"
                      onClick={onLoginSuccess}
                    />

                    <FacebookLogin
                      appId="966402757265826"
                      autoLoad={false}
                      fields="name,email,picture"
                      onClick={componentClicked}
                      callback={responseFacebook}
                      className="loginfb"
                      icon={<AiFillFacebook className="FbIcon" />}
                      render={(renderProps) => (
                        <button onClick={componentClicked}>
                          {/* Continue with Facebook */}
                        </button>
                      )}
                    />

                    {/* <FacebookLogin
                          appId="966402757265826"
                          autoLoad={true}
                          fields="name,email,picture"
                          scope="public_profile,user_friends"
                          callback={responseFacebook}
                          icon="fa-facebook" /> 
                      */}

                    {/* 
                        <FacebookProvider appId="76036270457706">
                            <LoginButton
                                scope="email"
                                onCompleted={handleResponse}
                                onError={handleError}
                                icon={<AiFillFacebook className="FbIcon"/>}
                            >
                                <span><button onClick={handleResponse}>Continue with Facebook</button></span>
                            </LoginButton>
                        </FacebookProvider> */}
                  </div>
                </div>
                {/* </div> */}
              </form>
            </div>

            <hr className="middle-name" />
            <div className="width-right">
              <div className="sign-title">
                <h2>Register Here</h2>
              </div>
              <div className="inner-login">
                <div className="inner-rhs" style={{ width: "100%" }}>
                  {emailExistsMessage.error === true
                    ? `${emailExistsMessage.Message}`
                    : ""}
                  <div className="textarea-rhs">
                    <div className="input-feilds">
                      <label htmlFor="fname" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="fname"
                        name="first_name"
                        onChange={handleRegistrationChange}
                        value={registrationDetails.first_name}
                        required
                        placeholder="John Doe"
                      />
                    </div>
                    <div className="input-feilds">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={handleRegistrationChange}
                        value={registrationDetails.email}
                        required
                        placeholder="Example@gmail.com"
                      />
                    </div>

                    <div className="input-feilds">
                      <label htmlFor="phone_no" className="form-label">
                        Phone
                      </label>
                      <PhoneInput
                        defaultCountry="AE"
                        value={countryCode}
                        name="phone_no"
                        required
                        className="phone-input"
                        onChange={(text) => {
                          setCountryCode(formatPhoneNumberIntl(text));
                          setPhoneNumber(text);
                        }}
                      />
                    </div>

                    <div className="input-feilds">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        onChange={handleRegistrationChange}
                        value={registrationDetails.password}
                        required
                        placeholder="********"
                      />
                    </div>
                    <div className="input-feilds">
                      <label
                        htmlFor="cnf_password"
                        className="form-label"
                        style={{ width: "100%" }}
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="cnf_password"
                        name="cnf_password"
                        onChange={handleRegistrationChange}
                        value={registrationDetails.cnf_password}
                        required
                        placeholder="********"
                      />
                    </div>
                  </div>
                  <div className="sub-btn">
                    <button className="register" onClick={handleRegistration}>
                      Register Now
                    </button>

                    {/* <button className="cancel" onClick={()=>handleCancel("registration")}>Cancel</button> */}
                  </div>
                  <div className="registre-msg">
                    {registrationSuccessMessage.success === true
                      ? `${registrationSuccessMessage.SuccessMessage}`
                      : " "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal-footer">
                    <p>This is Modal footer</p>
                </div> */}
      </div>
    </div>
  );
};
export default ModalComponent;
