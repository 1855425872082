import React, { useState, useEffect } from "react";
import Banner from "../images/banner_3.jpg";
import "./booknow.css";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import FooterMap from "../Footer/FooterMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPhone,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { loginUser } from "../Redux/Action/index";
import { useDispatch, useSelector } from "react-redux";
import {
  GetMainCourt,
  GetPadelholicLevel,
  LoggedOut,
} from "../HOC/logicWithCustomAxios";
import { LoginMethod } from "../HOC/logic";
import ReactGA from "react-ga";
import GreetingUserDetails from "../GreetingUserDetails/GreetingUserDetails";

function BookNow() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mainCourtData, setMainCourtData] = useState([]);
  const loginDetails = {
    loginEmail: "justpadel_admin@gmail.com",
    loginPassword: "Justpadel$2022#",
    source: "website",
  };
  const [loginError, setLoginError] = useState({
    ErrorMessage: "Please Provide Valid Credentials",
    error: false,
  });
  const [userData, setUserData] = useState();
  const [padelholicLevel, setPadelholicLevel] = useState();

  let userId = parseInt(localStorage.getItem("userId"));

  useEffect(() => {
    window.scrollTo(0, 0);
    //Google Analytics
    ReactGA.plugin.execute("ecommerce", "clear", null);
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.initialize("UA-192903986-3");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.event({
      category: "Home",
      action: "All Courts",
      label: "Book Now",
    });

    let loggedUserID = localStorage.getItem("userId");
    if (parseInt(loggedUserID)) {
      updateMainCourtData();
    } else {
      // handleLogin();
      setTimeout(() => {
        updateMainCourtData();
      }, 1000);
    }
    setUserData(JSON.parse(localStorage.getItem("LoggedUserDetails")));
  }, []);

  const updateMainCourtData = () => {
    GetMainCourt().then((res) => {
      if (res) {
        let responseModified;
        responseModified = res?.forEach((mainLoc) => {
          mainLoc.timeslot1 = mainLoc.timeslot.split(",")[0];
          // console.log(mainLoc.timeslot1);
          mainLoc.timeslot2 = mainLoc.timeslot.split(",")[1];
          if (mainLoc.courtName == "Mina Rashid") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/paQJG76QdPCbK6rK7";
          }
          if (mainLoc.courtName == "Mina Rashid Outdoor") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/piyJDkjoWdLiGZ6SA";
          }
          if (mainLoc.courtName == "Mina Rashid Ladies Only") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/vb6XWjHdTxy5YBTe8";
          }
          if (mainLoc.courtName == "Uptown School") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/3fY1uY2bxsFyxsxKA";
          }
          if (mainLoc.courtName == "Bright Learners School") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/PTABKCcxqC4kVXuN6";
          }
          if (mainLoc.courtName == "Kite Beach") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/9xR8Vpm3gv33WT217";
          }
          if (mainLoc.courtName == "Dubai Ladies Club") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/bDAnXXe4m2sLjKKJA";
          }
          if (mainLoc.courtName == "Ripe Market") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/sHq1FkKPUw4i5kfh9";
          }
          if (mainLoc.courtName == "Kent College") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/qS71Za1Fjc22zaWj6";
          }
          if (mainLoc.courtName == "Almarri Academy") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/uQpa3CN7A5o6hSCM8";
          }
          if (mainLoc.courtName == "Arab Unity School") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/8BP6ScWyy5v2VD6aA";
          }
          if (mainLoc.courtName == "Brighton College & Bloom World Academy") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/6CNDU1CQHe35aBfq9";
          }
          if (mainLoc.courtName == "Greenfield International School") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/4PXKKjbmxSNQ17rK6";
          }
          if (mainLoc.courtName == "Mina Rashid - Ladies") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/paQJG76QdPCbK6rK7";
          }
          if (mainLoc.courtName == "Al Noor Training Centre") {
            mainLoc.phoneNo = "tel:+971559928022";
            mainLoc.location = "https://goo.gl/maps/iAF6mHQESM7Ld7vi8";
          }
        });
        let FilteredData = res.sort((a,b)=> {
          if (a.city < b.city) { return -1; }
          if (a.city > b.city) { return 1; }
          return 0;
        });
        setMainCourtData(FilteredData);
      }
    });
  };

  /*------------------------------ This function used for login  ------------------------------*/
  const handleLogin = () => {
    let userId = localStorage.getItem("userId");
    // console.log("userId", userId);
    if (userId !== 1 && userId !== null) {
      updateMainCourtData();
      return;
    }
    LoginMethod(loginDetails.loginEmail, loginDetails.loginPassword).then(
      (response) => {
        if (response.error_code === 24) {
          setLoginError({
            ErrorMessage: "Please Provide Valid Credentials",
            error: true,
          });
        } else if (response.error_code === 23) {
          setLoginError({ ErrorMessage: response.message, error: true });
        } else {
          setLoginError({ ErrorMessage: "Logged Successful", error: true });
          dispatch(
            loginUser(
              response.access,
              response.role,
              response.userid,
              response.username
            )
          );
          updateMainCourtData();
          // PadelholicLevel(userData?.no_of_games);
        }
      }
    );
  };

  const Redirect = () => {
    history.push(
      `/tournament-payment?name=Kamelsh&last=Gupta&amount=250&email=kamleshguta594@gmail.com&phone_no=9637199927`
    );
  };
  const PadelholicLevel = (games) => {
    GetPadelholicLevel().then((response) => {
      const padelHolicLevel = games;
      const padelLevels = response;
      let matchingPadelLevel = padelLevels.find(
        (element) => element.games >= padelHolicLevel
      );
      if (matchingPadelLevel == null) {
        matchingPadelLevel = padelLevels[padelLevels.length - 1];
      }
      setPadelholicLevel(matchingPadelLevel.level);
      localStorage.setItem("PadelHolicLeveIs", matchingPadelLevel.level);
    });
  };

  const GotoMyBookings = () => {
    history.push("/booking-list");
  };

  return (
    <div>
      <Navbar />
      <div className="banner-container">
        <div className="img-container">
          <img src={Banner} alt="justpadel.logo" className="banner" />
          <div className="background-overlay"></div>
          <div className="padel-title">
            <h1>PADEL COURT LOCATIONS</h1>
          </div>
        </div>

        <div className="GreetingOuterContainer">
          <GreetingUserDetails />
        </div>

        <div className="booking-containers">
          {mainCourtData &&
            mainCourtData?.map((data, index) => {
              return (
                <div className="courts">
                  <img
                    src={data?.maincourtimage}
                    alt={data?.maincourtimage}
                    className="padelImg"
                  />
                  <div className="court-details-container">
                    <div className="court-details">
                      <div className="court-inner">
                        <div>
                          <h4>{data?.courtName}</h4>
                        </div>
                        <div className="court-icons">
                          <a href={data?.phoneNo}>
                            <FontAwesomeIcon
                              icon={faPhone}
                              className="faPhone"
                            />
                          </a>
                          <a href={data?.location}>
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="faPaperPlane"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="court-count">
                        <h5>
                          {data?.padelCourts} {data.courttype.courttype} Courts
                        </h5>
                      </div>
                      <div className="court-time">
                        <div>
                          {data?.timeslot1 ? (
                            <h5>
                              <FontAwesomeIcon
                                icon={faClock}
                                className="faClock"
                              />{" "}
                              {data?.timeslot1}
                            </h5>
                          ) : (
                            ""
                          )}
                          {data?.timeslot2 ? (
                            <h5>
                              <FontAwesomeIcon
                                icon={faClock}
                                className="faClock"
                              />{" "}
                              {data?.timeslot2}
                            </h5>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="court-time">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          height="17"
                          viewBox="0 0 24 24"
                          width="17"
                        >
                          <g>
                            <path d="m8.5 20h-3.5c-.6 0-1-.4-1-1s.4-1 1-1h3.5c.6 0 1 .4 1 1s-.4 1-1 1z"></path>
                          </g>
                          <g>
                            <path d="m7.5 20c-.4 0-.8-.1-1.2-.3s-.7-.5-.9-.8c-.2-.4-.4-.8-.4-1.2s0-.8.2-1.2.4-.7.8-1c.3-.3.7-.4 1.1-.5s.8 0 1.2.1.7.4 1 .7c.4.4.4 1 0 1.4s-1 .4-1.4 0c-.1-.1-.1-.1-.2-.1s-.2 0-.2 0c-.1 0-.2 0-.2.1-.1.1-.1.1-.2.2v.2c0 .1 0 .2.1.2 0 .1.1.1.2.2-.1 0 0 0 .1 0 .6 0 1 .4 1 1s-.4 1-1 1z"></path>
                          </g>
                          <g>
                            <path d="m7 14c-.6 0-1-.4-1-1v-9c0-.6.4-1 1-1s1 .4 1 1v9c0 .6-.4 1-1 1z"></path>
                          </g>
                          <g>
                            <path d="m16.9 14h-1.9c-.6 0-1-.4-1-1s.4-1 1-1h1.9c.1 0 .2 0 .3-.1.1 0 .1-.1.2-.2 0-.1.1-.2.1-.2 0-.1 0-.2-.1-.2l-2.2-3.7c-.3-.6-.2-1.2.3-1.5.5-.2 1.1-.1 1.4.4l2.2 3.7c.2.4.3.8.3 1.3 0 .4-.1.9-.3 1.3s-.5.7-.9.9-.9.3-1.3.3z"></path>
                          </g>
                          <g>
                            <path d="m11 14c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1z"></path>
                          </g>
                        </svg>
                        <h5 style={{ marginLeft: 5 }}>
                          {" "}
                          {data?.price} AED - 45 Min{" "}
                        </h5>
                      </div>
                      <div className="court-time">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          height="17"
                          viewBox="0 0 24 24"
                          width="17"
                        >
                          <g>
                            <path d="m8.5 20h-3.5c-.6 0-1-.4-1-1s.4-1 1-1h3.5c.6 0 1 .4 1 1s-.4 1-1 1z"></path>
                          </g>
                          <g>
                            <path d="m7.5 20c-.4 0-.8-.1-1.2-.3s-.7-.5-.9-.8c-.2-.4-.4-.8-.4-1.2s0-.8.2-1.2.4-.7.8-1c.3-.3.7-.4 1.1-.5s.8 0 1.2.1.7.4 1 .7c.4.4.4 1 0 1.4s-1 .4-1.4 0c-.1-.1-.1-.1-.2-.1s-.2 0-.2 0c-.1 0-.2 0-.2.1-.1.1-.1.1-.2.2v.2c0 .1 0 .2.1.2 0 .1.1.1.2.2-.1 0 0 0 .1 0 .6 0 1 .4 1 1s-.4 1-1 1z"></path>
                          </g>
                          <g>
                            <path d="m7 14c-.6 0-1-.4-1-1v-9c0-.6.4-1 1-1s1 .4 1 1v9c0 .6-.4 1-1 1z"></path>
                          </g>
                          <g>
                            <path d="m16.9 14h-1.9c-.6 0-1-.4-1-1s.4-1 1-1h1.9c.1 0 .2 0 .3-.1.1 0 .1-.1.2-.2 0-.1.1-.2.1-.2 0-.1 0-.2-.1-.2l-2.2-3.7c-.3-.6-.2-1.2.3-1.5.5-.2 1.1-.1 1.4.4l2.2 3.7c.2.4.3.8.3 1.3 0 .4-.1.9-.3 1.3s-.5.7-.9.9-.9.3-1.3.3z"></path>
                          </g>
                          <g>
                            <path d="m11 14c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1z"></path>
                          </g>
                        </svg>
                        <h5 style={{ marginLeft: 5 }}>
                          {" "}
                          {data?.padelcourtPrice} AED - Extra 45 Min{" "}
                        </h5>
                      </div>
                      <div className="court-has">
                        {data?.features?.split(",").map((feature) => (
                          <small>{feature}</small>
                        ))}
                      </div>

                      {data?.booking_facility === "Call Now Facility" ? (
                        <a
                          href={`tel:${data?.contact_number}`}
                          className="book-btn"
                        >
                          <h5 style={{ marginBottom: "0" }}>CALL NOW</h5>
                        </a>
                      ) : (
                        <Link
                          className="book-btn"
                          to={`/court/${data.courtName
                            .split(/[\s]+/)
                            .join("-")
                            .toLowerCase()}`}
                        >
                          <h5 style={{ marginBottom: "0" }}>BOOK COURT</h5>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="facilities-container">
        <div className="elementor-heading-title">
          <h1>All our facilities includes</h1>

          <div className="facilities-img-container">
            <div className="img-const">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/bottles-pf3080k5kglw5sj4pizqnkmhs09unxv8k1fwxg3vog.png" />
              <h4>Refreshments</h4>
            </div>
            <div className="img-const">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/parking-pf30tqlvimcghcz5ux1ca3a00l78k43wvk7w7nwbvk.png" />
              <h4>Parking</h4>
            </div>
            <div className="img-const">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/medical-kit-pf314gxjara4st846hl9j36s195lyjgop0izmp72hk.png" />
              <h4>First Aid</h4>
            </div>
            <div className="img-const">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/padel-pf30trjpl2i5otuup583hoqmjmhv1836vuyna3gpj0.png" />
              <h4>Rental</h4>
            </div>
            <div className="img-const">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/washroom-pf30tshjwaf14kwfjxulf2sx7cxyzibdjtiv67tjj4.png" />
              <h4>Washrooms</h4>
            </div>
            <div className="img-const">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/mask-pf311tzud9fcgh7wlezor1qw61b0ua8pbmgezfwaao.png" />
              <h4>Covid Safety</h4>
            </div>
            <div className="img-const">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/spray-pf311vviqxhx3p56afsxw19tct1r9og5zvrdxzthy8.png" />
              <h4>Clean Courts</h4>
            </div>
          </div>
        </div>

        <div className="elementor-heading-title">
          <h1>Exclusive offers available for</h1>
          <div className="facilities-offers-container">
            {/* <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Untitled-design-16-pyurhemkx0uof5fwhyuwbn00k5mkv5ct82agnkcwlc.png" />
              <strong>15% Discount</strong>
              <h4>Mashrek Bank Customers</h4>
            </div> */}
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Talabat-Logo-q0vt3pac7xvhtvd4thuaoffr3pi47uzmaq4zpbp43k.png" />
              <strong>25% Discount</strong>
              <h4>Talabat Employees</h4>
            </div>
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/2022/01/Essad.jpg" />
              <strong>25% Discount</strong>
              <h4>Esaad Card Holders</h4>
            </div>
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/2022/01/Fazaa.jpg" />
              <strong>25% Discount</strong>
              <h4>Fazaa Card Holders</h4>
            </div>
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/2022/01/Emirates.jpg" />
              <strong>25% Discount</strong>
              <h4>Emirates Platinum Card Holders</h4>
            </div>
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/2022/01/Dewa-01.jpg" />
              <strong>25% Discount</strong>
              <h4>Dewa Employees</h4>
            </div>
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/2022/01/DP-World.jpg" />
              <strong>25% Discount</strong>
              <h4>DP World Employees</h4>
            </div>
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/PO-Marinas-py6j2j51yf7i7logl69f41ye8y44m48nugt95nn7cw.jpg" />
              <strong>30% Discount</strong>
              <h4>P&O Marinas Employees</h4>
            </div>
            <div className="img-offers">
              <img
                width={130}
                height={100}
                src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Sanad-Card-pyrb07w0u4g3ia7tmv0oz0ltwxlg7g5ei9p4pwmz9c.jpg"
              />
              <strong>25% Discount</strong>
              <h4>SANAD Card Holders</h4>
            </div>
            {/* <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Al-Noor-Training-Centre-pp2y0k8exmb0io74843jardewevjn0afn7sq6c46ww.jpg" />
              <strong>20% Discount</strong>
              <h4>Al Noor Training Centre Staff & Parents</h4>
            </div> */}
            {/* <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/ARAB-pp2y24mqbog7v9x72sh5gd52jj5kguibuyxuyxskjk.jpg" />
              <strong>20% Discount</strong>
              <h4>Arab Unity School Staff & Parents</h4>
            </div> */}
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Brighton-pp2xzkijpwy49hn00onpo0dwbrrliicmub09xtl5hc.jpg" />
              <strong>20% Discount</strong>
              <h4>Brighton College & Bloom World Academy</h4>
            </div>
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Dubai-Ladies-Club-pyrb052i9mc5gyq9xwzxzgxy2p7pvh1j3uwzz4rx1c.png" />
              <strong>25% Discount</strong>
              <h4>Dubai Ladies Club Members</h4>
            </div>
            <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Dwight-pp2y03bbilnupovoyws91vn47h6xsgf9kw1zjcta0w.jpg" />
              <strong>20% Discount</strong>
              <h4>Dwight School Staff & Parents</h4>
            </div>
            {/* <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Kent-College-pp2y2ljtqp3do98mbzsfp8vd8gu6bedhxaollx3hfk.jpg" />
              <strong>20% Discount</strong>
              <h4>Kent College Staff & Parents</h4>
            </div> */}
            {/* <div className="img-offers">
              <img src="https://justpadel.ae/wp-content/uploads/elementor/thumbs/Uptown-pp2y16sjhn5w9backdukyloh5nscrqrzqbgdoz6qrk.jpg" />
              <strong>20% Discount</strong>
              <h4>Uptown International School Staff & Parents</h4>
            </div> */}
          </div>
        </div>
      </div>

      <FooterMap />
    </div>
  );
}

export default BookNow;
