import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Container } from 'reactstrap'
import axios from '../../CustomAxios';
import { environment } from '../../env';
import Navbar from '../Navbar/Navbar'
import FooterMap from "../Footer/FooterMap"
import { useDispatch } from 'react-redux';
import { loginUser } from '../Redux/Action';
import { LoginMethod } from '../HOC/logic';
import justPadelWhite from '../../Assets/Images/GiftCard.webp'
import { Spinner } from "reactstrap";
import Select from 'react-select'
import '../QuickPay/Quickpay.css';



const GiftCard = () => {
    const [signature, setSignature] = useState();
    const [spinner, setSpinner] = useState(false);
    const [loginError, setLoginError] = useState({ ErrorMessage: "Please Provide Valid Credentials", error: false });
    const dispatch = useDispatch();
    const [id, setId] = useState();
    const [totalAmount, setTotalAmount] = useState(0);

    const options1 = [
        { value: 200, label: '45 mins - 200 AED' },
        { value: 275, label: '90 mins - 275 AED' },
    ]

    const { register, handleSubmit, reset, watch, formState, control, getValues, setValue } = useForm({ mode: 'onChange', defaultValues: { total_amount: 0 } });
    const { errors, isValid } = formState;


    const onSubmit = (data) => {
        setSpinner(true);
        const payload = {
            sender_name: data?.sender_name,
            receiver_name: data?.receiver_name,
            receiver_email: data?.receiver_email,
            select_gift_type: data?.gift_type.label,
            quantity: parseInt(data?.quantity),
            total_amount: totalAmount,
            payment_status: "pending",
            transaction_log: "no transaction log",
            phone: data?.phone
        }

        const PostGiftCardURL = `${environment.baseUrl}giftcard/`;
        const result = axios.post(PostGiftCardURL, payload)
            .then((Response) => {
                setId(Response.data.Id);
                payments(Response.data.Id);         
            })

    };

    const handleLogin = () => {
        const loginDetails = { loginEmail: 'justpadel_admin@gmail.com', loginPassword: 'Justpadel$2022#', source: 'website' };
        LoginMethod(loginDetails.loginEmail, loginDetails.loginPassword).then((response) => {
            if (response.error_code === 24) {
                setLoginError({ ErrorMessage: "Please Provide Valid Credentials", error: true });
            } else if (response.error_code === 23) {
                setLoginError({ ErrorMessage: response.message, error: true });
                dispatch(loginUser(response.access, response.role, response.userid, response.username));
            } else {
                setLoginError({ ErrorMessage: "Logged Successful", error: true });
                dispatch(loginUser(response.access, response.role, response.userid, response.username));
            }
        });
    };

    const email = watch("receiver_email");

    const payments = (ids) => {
        setSpinner(true);
        const genSignatureUrl = `${environment.baseUrl}checkout`;
        const params = JSON.stringify({
            "currency": "AED",
            "amount": `${totalAmount * 100}`,
            "customer_email": `${email}`,
            "language": "en",
            "order_description": `GiftCardPay${ids}`,
            //"order_description": "Mina Rashid 4",
            // "merchant_reference": `${name}`,
            "merchant_reference": `${`GiftCardPay-${ids}`}`,
            //Testing payment gateway starts here
            // "access_code": 'MTkAuRmgVl270B82kqey',
            // "merchant_identifier": 'fc9fe2bb',
            // "parse_phrase": '51MO1sxGDZPtHyGATVpuyG!@',
            //Testing payment gateway end here
            "command": "PURCHASE",
            "return_url": "https://api.justpadelae.com/giftcard-payment-return/"
        });
        const userToken = localStorage.getItem('Token');

        console.log("params", params);

        axios.post(genSignatureUrl, params, {
            "headers": {
                "content-type": "application/json",
                'Authorization': 'Bearer ' + userToken
            },
        })
            .then(function (data) {
                if (data.status === 200 || data.status === 201) {
                    console.log("Signature", data?.data.signature);
                    setSignature(data?.data.signature);

                    setTimeout(() => {
                        PaymentLoad();
                    }, 2000);
                } else {
                    alert('Server error')
                    setSpinner(false);
                }
            })
            .catch(function (error) {
                console.log(error)
                setSpinner(false);
            });
    };
    const PaymentLoad = () => {
        document.getElementById("load_payment").submit();
    }

    const giftType = watch("gift_type");
    const Quantity = watch("quantity");

    useEffect(() => {
        handleLogin();
    }, []);

    useEffect(() => {
        if (giftType && Quantity) {
            setTotalAmount(giftType.value * parseInt(Quantity));
        }
    }, [giftType, Quantity])



    return (
        <div className='quick-pay-outer-container'>
            <div className="center-spinner">
                {spinner ? (
                    <div className="backgroungSpinner">
                        <Spinner />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <Navbar />

            <Container>

                <div className='quickpay-Container'>

                    <div className='gift-card-conatiner'>
                        <img src="https://justpadel-courtimages.s3.amazonaws.com/Gift-Card-Christmas-Offer-Just-Padel2.png" alt="https://justpadel-courtimages.s3.amazonaws.com/Gift-Card-Christmas-Offer-Just-Padel2.png" className='w-100'  />
                    </div>

                    <div className='quick-pay-right-field'>

                        <form className="form product-edit"
                            onSubmit={handleSubmit(onSubmit)}>
                            <div className="form__half">
                                <div className="form__form-group ">
                                    <h4 className='Quick-pay-field-heading'>CHRISTMAS GIFT CARD</h4>
                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group  quick-pay-container">
                                    <div className='label-name-container'>
                                        <span className="form__form-group-label Quick-pay-label">Sender Name</span>
                                    </div>
                                    <div className="form__form-group-field">
                                        <input type="text" name='sender_name' className='Quick-pay-fields'
                                            placeholder='e.g Just Padel' {...register("sender_name", { required: true })} />
                                    </div>
                                    {errors.sender_name && <span className="error-msg">Please Enter Sender Name</span>}
                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group  quick-pay-container">
                                    <div className='label-name-container'>
                                        <span className="form__form-group-label Quick-pay-label">Receiver Name</span>
                                    </div>
                                    <div className="form__form-group-field">
                                        <input type="text" name='receiver_name' className='Quick-pay-fields'
                                            placeholder='e.g Just Padel' {...register("receiver_name", { required: true })} />
                                    </div>
                                    {errors.receiver_name && <span className="error-msg">Please Enter Receiver Name</span>}
                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group quick-pay-container">
                                    <div className='label-name-container'>
                                        <span className="form__form-group-label Quick-pay-label">Receiver Email</span>
                                    </div>

                                    <div className="form__form-group-field">
                                        <input type="email" name='receiver_email' className='Quick-pay-fields'
                                            placeholder='e.g tyler.stewart@gmail.com' {...register("receiver_email", { required: true })} />
                                    </div>
                                    {errors.receiver_email && <span className="error-msg">Please Enter Valid Email</span>}
                                </div>
                            </div>

                            <div className="form__half h-100">
                                <div className="form__form-group quick-pay-container">
                                    <div className='label-name-container'>
                                        <span className="form__form-group-label Quick-pay-label" style={{ zIndex: 10 }}>Select Gift Type</span>
                                    </div>

                                    <div className="form__form-group-field">

                                        <div className="form__form-group-field">

                                            <Controller
                                                name="gift_type"
                                                control={control}
                                                defaultValue={null}
                                                className="h-25"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={options1}
                                                        placeholder={"Select Gift Type"}
                                                    />
                                                )}
                                            />

                                        </div>
                                        {errors.subject && <span className="error-msg">Please Select Gift Type</span>}

                                    </div>
                                </div>

                            </div>

                            <div className="form__half">
                                <div className="form__form-group  quick-pay-container">
                                    <div className='label-name-container'>
                                        <span className="form__form-group-label Quick-pay-label">Mobile Number</span>
                                    </div>
                                    <div className="form__form-group-field">
                                        <input type="text" name='phone' className='Quick-pay-fields'
                                            placeholder='e.g +971 99 5528 022' {...register("phone", { required: true, })} />
                                    </div>
                                    {errors.phone && <span className="error-msg">Please Enter Mobile Number</span>}
                                </div>
                            </div>

                            <div className="form__half">
                                <div className="form__form-group  quick-pay-container">
                                    <div className='label-name-container'>
                                        <span className="form__form-group-label Quick-pay-label">Quantity</span>
                                    </div>
                                    <div className="form__form-group-field">
                                        <input type="number" name='quantity' className='Quick-pay-fields'
                                            placeholder='e.g 1' {...register("quantity", { required: true, })} />
                                    </div>
                                    {errors.quantity && <span className="error-msg">Please Enter Quantity</span>}
                                </div>
                            </div>

                            <div className="form__form-group quick-pay-container">
                                <span className="form__form-group-label Quick-pay-label fs-6">Total</span>
                                <div className="form__form-group-field">
                                    <p class='text-black-50'>{totalAmount} AED.</p>
                                </div>
                            </div>

                            <span className='termsAndConditions'>By Continuing You Agree To Our Company{" "}
                                <a href='https://justpadel.ae/padel-privacy-policy/' target="_blank" rel="noopener noreferrer">Policies</a>
                                {" "}And{" "}
                                <a href='https://justpadel.ae/terms-and-conditions/' target="_blank" rel="noopener noreferrer">Terms of Service</a></span>
                            <button className='Quick-pay-buttons-save' type='submit' disabled={!isValid}>Proceed to payment</button>
                            <div className="col-buttons">
                            </div>
                        </form>
                    </div>
                </div>
                <div className='quickpay-Container' style={{flexDirection:'column', padding:'20px'}}>
                    <p>Terms & Conditions:</p>
                    <ul>
                        <li>Valid until 31st December 2022.</li>
                        <li>This gift card is valid for one-time use only.</li>
                        <li>Can be redeemed only in Mina Rashid location between 6 AM and 2:15 PM.</li>
                        <li>Valid for Private Coaching only.</li>
                        <li>Cannot be used combined with existing programs/offers.</li>
                        <li>For additional info, call/whatsapp +971 559928022</li>
                    </ul>
                </div>
            </Container>


            {
                signature ?
                    <form action="https://checkout.payfort.com/FortAPI/paymentPage" method='post' name='frm' target="_self" id="load_payment" >
                        <input type='hidden' name='currency' value='AED' />
                        <input type='hidden' name='amount' value={totalAmount * 100} />
                        <input type='hidden' name='customer_email' value={email} />
                        <input type='hidden' name='command' value='PURCHASE' />
                        <input type='hidden' name='language' value='en' />
                        <input type='hidden' name='merchant_identifier' value='TXGKcfsS' />
                        <input type='hidden' name='access_code' value='9NKhRXOyuU6C1vrXB4rL' />
                        <input type='hidden' name='signature' value={signature} />
                        <input type='hidden' name='merchant_reference' value={`GiftCardPay-${id}`} />
                        <input type='hidden' name='order_description' value={`GiftCardPay${id}`} />
                        <input type='hidden' name='return_url' value="https://api.justpadelae.com/giftcard-payment-return/" />
                    </form>
                    : ''
            }
            <FooterMap />

        </div >
    )
}

export default GiftCard;