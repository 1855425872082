import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import axios from '../../CustomAxios';
import { environment } from '../../env';
import { LoginMethod } from '../HOC/logic';
import { KidsSummerCampMethod } from '../HOC/logicWithCustomAxios';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../Redux/Action';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const amountVerification = (amount) => {
    if (amount.includes(",")) {        
      return amount.replace(/,/g, "");
    } else {
      return amount;
    }
  };

const KidsSummerCamp = () => {
    const [loginError, setLoginError] = useState({ ErrorMessage: "Please Provide Valid Credentials", error: false });
    const dispatch = useDispatch();
    const history = useHistory();
    const [signature, setSignature] = useState();
    let query = useQuery();
    let name = query.get("name");
    let amount = amountVerification(query.get(`amount`)?.split('.')[0]);
    let email = query.get("email");
    let phone_no = query.get("phone_no");
    let age = query.get("age");

    const handleLogin = () => {
        const loginDetails = { loginEmail: 'justpadel_admin@gmail.com', loginPassword: 'Justpadel$2022#', source: 'website' };
        LoginMethod(loginDetails.loginEmail, loginDetails.loginPassword).then((response) => {
            if (response.error_code === 24) {
                setLoginError({ ErrorMessage: "Please Provide Valid Credentials", error: true });
            } else {
                setLoginError({ ErrorMessage: "Logged Successful", error: true });
                dispatch(loginUser(response.access, response.role, response.userid, response.username));
                KidsSummerCampDataPost();
            }
        });
    };

    const KidsSummerCampDataPost = () => {
        let SenderDetails = {
            name: name,
            amount: parseInt(amount),
            email: email,
            phone: phone_no,
            transaction_log: 'NO Transacation Log',
            age: age
        }
        console.log("SenderDetails", SenderDetails);

        KidsSummerCampMethod(SenderDetails).then((response) => {
            if (response.Messgae === "Data is saved successfuly") {
                localStorage.setItem("KidsSummerCampPaymentID", response.id);
                console.log("KidsSummerCampPaymentID", response.id);
                KidsSummerCampPayments(response.id);
            } else {
                console.log("Error Somethings goes wrong", response)
            }
        });
    };


    const KidsSummerCampPayments = (ids) => {
        const genSignatureUrl = `${environment.baseUrl}checkout`;
        const params = JSON.stringify({
            "currency": "AED",
            "amount": `${amount * 100}`,
            "customer_email": `${email}`,
            "language": "en",
            "order_description": `JustPadelEventsPays${ids}`,
            //"order_description": "Mina Rashid 4",
            // "merchant_reference": `${name}`,
            "merchant_reference": `TournamentBookingIds-${ids}`,
            //Testing payment gateway starts here
            // "access_code": 'MTkAuRmgVl270B82kqey',
            // "merchant_identifier": 'fc9fe2bb',
            // "parse_phrase": '51MO1sxGDZPtHyGATVpuyG!@',
            //Testing payment gateway end here
            "command": "PURCHASE",
            "return_url": "https://api.justpadelae.com/kids-summer-camp-payment-return/"
        });
        const userToken = localStorage.getItem('Token');

        axios.post(genSignatureUrl, params, {
            "headers": {
                "content-type": "application/json",
                'Authorization': 'Bearer ' + userToken
            },
        })
            .then(function (data) {
                if (data.status === 200 || data.status === 201) {
                    setSignature(data?.data.signature);
                    setTimeout(() => {
                        PaymentLoad();
                    }, 2000);
                } else {
                    alert('Server error')

                }
            })
            .catch(function (error) {
                console.log(error)

            });
    };

    const PaymentLoad = () => {
        document.getElementById("load_payment").submit();
    }


    useEffect(() => {
        handleLogin();
    }, [])


    return (
        <div>{
            signature ?
                <form action="https://checkout.payfort.com/FortAPI/paymentPage" method='post' name='frm' target="_self" id="load_payment" >

                    <input type='hidden' name='currency' value='AED' />

                    <input type='hidden' name='amount' value={amount * 100} />

                    <input type='hidden' name='customer_email' value={email} />

                    <input type='hidden' name='command' value='PURCHASE' />

                    <input type='hidden' name='language' value='en' />

                    <input type='hidden' name='merchant_identifier' value='TXGKcfsS' />

                    <input type='hidden' name='access_code' value='9NKhRXOyuU6C1vrXB4rL' />

                    <input type='hidden' name='signature' value={signature} />

                    <input type='hidden' name='merchant_reference' value={`TournamentBookingIds-${parseInt(localStorage.getItem("KidsSummerCampPaymentID"))}`} />

                    <input type='hidden' name='order_description' value={`JustPadelEventsPays${parseInt(localStorage.getItem("KidsSummerCampPaymentID"))}`} />

                    <input type='hidden' name='return_url' value='https://api.justpadelae.com/kids-summer-camp-payment-return/' />

                </form>
                : ''
        }</div>
    )
}

export default KidsSummerCamp