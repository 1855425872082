import React from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { useHistory } from 'react-router-dom';

const GoogleSignIn = () => {
  const history = useHistory();

    const onLoginSuccess = (res) =>{
        console.log("response", res.profileObj.email);
        //window.location.replace(`justpadel://?path=googleLogin&email=${res.profileObj.email}`);
        window.location.replace(`exp://192.168.0.104:19000?path=googleLogin&email=${res.profileObj.email}`);
    }
    const onLoginFailure = ( ) =>{
        alert("error while login ");
    }

  return (
    <div>
      <GoogleLogin
        clientId="70525544697-l9ik35u2ks7db1tgqssve1s7r6l7psq5.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={onLoginSuccess}
        onFailure={onLoginFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={false}
        className="loginGoogle"
      />
    </div>
  );
};

export default GoogleSignIn;
