import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Container } from "reactstrap";
import axios from "../../CustomAxios";
import { environment } from "../../env";
import Navbar from "../Navbar/Navbar";
import FooterMap from "../Footer/FooterMap";
import { useDispatch } from "react-redux";
import { loginUser } from "../Redux/Action";
import { useParams, useHistory } from "react-router-dom";
import { LoginMethod } from "../HOC/logic";
import "../QuickPay/Quickpay.css";
import justPadelWhite from "../../Assets/Images/MaskGroup.png";
import QuickPayLogo from "../../Assets/Images/QuickPayLogo.png";
import { Spinner } from "reactstrap";
import Select from "react-select";

const SplitPay = () => {
  const [signature, setSignature] = useState();
  const [spinner, setSpinner] = useState(false);
  const [courtLocation, setCourtLocation] = useState([]);
  const [loginError, setLoginError] = useState({
    ErrorMessage: "Please Provide Valid Credentials",
    error: false,
  });
  const [loggedIn, setLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const { brandambassador } = useParams();

  const minPlayOption = [
    { value: "90 Min", label: "90 Min" },
    { value: "45 Min", label: "45 Min" },
  ];

  const { register, handleSubmit, reset, watch, formState, control } = useForm({
    mode: "onChange",
  });
  const { errors, isValid } = formState;

  const onSubmit = (data) => {
    data.transaction_log = "No Transaction log";
    data.minPlay = data?.minPlay?.value;
    data.subject = subject?.value;
    data.ambassadorname = brandambassador;
    data.additional_comments = `This Link is shared by brand ambassador ${brandambassador} for ${data.minPlay} gameplay.`;
    setSpinner(true);
    const QuickPaymentURL = `${environment.baseUrl}quicks-pay`;
    const result = axios
      .post(QuickPaymentURL, data)
      .then((quickPaymentResponse) => {
        setId(quickPaymentResponse.data.Id);
        payments(quickPaymentResponse.data.Id);
      });
  };

  const handleLogin = async () => {
    const loginDetails = {
      loginEmail: "justpadel_admin@gmail.com",
      loginPassword: "Justpadel$2022#",
      source: "website",
    };
    await LoginMethod(loginDetails.loginEmail, loginDetails.loginPassword).then(
      (response) => {
        if (response.error_code === 24) {
          setLoginError({
            ErrorMessage: "Please Provide Valid Credentials",
            error: true,
          });
        } else if (response.error_code === 23) {
          setLoginError({ ErrorMessage: response.message, error: true });
          dispatch(
            loginUser(
              response.access,
              response.role,
              response.userid,
              response.username
            )
          );
          setLoggedIn(true);
        } else {
          setLoginError({ ErrorMessage: "Logged Successful", error: true });
          dispatch(
            loginUser(
              response.access,
              response.role,
              response.userid,
              response.username
            )
          );
          setLoggedIn(true);
        }
      }
    );
  };

  const name = watch("name");
  const email = watch("email");
  const amount = parseFloat(watch("amount"));
  const subject = watch("subject");
  const minPlay = watch("minPlay");

  const payments = (ids) => {
    const genSignatureUrl = `${environment.baseUrl}checkout`;
    const params = JSON.stringify({
      currency: "AED",
      amount: `${amount * 100}`,
      customer_email: `${email}`,
      language: "en",
      order_description: `JustPadelQuickPay${ids}`,
      //"order_description": "Mina Rashid 4",
      // "merchant_reference": `${name}`,
      merchant_reference: `${`JustPadelQuickPay-${ids}`}`,
      //Testing payment gateway starts here
      // "access_code": 'MTkAuRmgVl270B82kqey',
      // "merchant_identifier": 'fc9fe2bb',
      // "parse_phrase": '51MO1sxGDZPtHyGATVpuyG!@',
      //Testing payment gateway end here
      command: "PURCHASE",
      return_url: "https://api.justpadelae.com/quicks-pay-payment-return",
    });
    console.log("params", params);
    const userToken = localStorage.getItem("Token");

    axios
      .post(genSignatureUrl, params, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + userToken,
        },
      })
      .then(function (data) {
        if (data.status === 200 || data.status === 201) {
          console.log("Signature", data?.data.signature);
          setSignature(data?.data.signature);

          setTimeout(() => {
            PaymentLoad();
          }, 2000);
        } else {
          alert("Server error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const PaymentLoad = () => {
    document.getElementById("load_payment").submit();
  };

  const GetMainCourts = async () => {
    const GetMainCourtsURL = `${environment.baseUrl}maincourts-admin/`;
    const Locations = [];
    const locationData = await axios.get(GetMainCourtsURL);
    locationData?.data?.map((data) => {
      Locations.push({
        label: data.courtName,
        value: data.courtName,
        courtPrice: data.price,
        padelcourtPrice: data.padelcourtPrice,
      });
    });
    setCourtLocation(Locations);
  };

  useEffect(() => {
    reset();
    handleLogin();
  }, []);

  useEffect(() => {
    if (loginError) {
      setTimeout(() => {
        GetMainCourts();
      }, 2000);
    }
  }, [loginError]);

  const priceWithVat = (amount) => {
    const vat = (amount / 100) * 5;
    return amount + vat;
  };

  useEffect(() => {
    if (subject && minPlay) {
      console.log("subject", subject);
      if (minPlay?.value == "90 Min") {
        if (brandambassador == "aya") {
          if (
            subject.value.indexOf("Mina Rashid") > -1 ||
            subject.value.indexOf("Kite Beach") > -1
          ) {
            reset({
              amount: 90.0,
              subject: subject,
              email: email,
              minPlay: minPlay,
            });
          } else {
            reset({
              amount: 70.0,
              subject: subject,
              email: email,
              minPlay: minPlay,
            });
          }
        } else {
          reset({
            amount: (
              priceWithVat(subject.courtPrice + subject.padelcourtPrice) / 4
            ).toFixed(2),
            subject: subject,
            email: email,
            minPlay: minPlay,
          });
        }
      } else {
        reset({
          amount: (priceWithVat(subject.courtPrice) / 4).toFixed(2),
          subject: subject,
          email: email,
          minPlay: minPlay,
        });
      }
    }
  }, [subject, minPlay]);

  return (
    <div className="quick-pay-outer-container">
      <div className="center-spinner">
        {spinner && (
          <div className="backgroungSpinner">
            <Spinner />
          </div>
        )}
      </div>
      <Navbar />

      <Container>
        <div className="quickpay-Container">
          <div className="quick-pay-left-cart">
            <div className="quick-pay-justPadelBlue">
              <div className="quick-box-justPadelBlue">
                <img src={QuickPayLogo} alt={QuickPayLogo} width={30} />
                <span className="text-headline">
                  Just Padel Pro Sports Academy
                </span>
              </div>
              <span className="quick-pay">SPLIT PAY</span>
              <span className="quick-pay-sef">Simple. Easy. Fast.</span>
            </div>
            <div className="quick-box-justPadelWhite">
              <img src={justPadelWhite} alt={justPadelWhite} width={100} />
              <span className="just-padel-Name">Just Padel</span>
              <span className="just-padel-court-Name">
                Padel Court of Choice
              </span>
            </div>
          </div>

          <div className="quick-pay-right-field">
            <form
              className="form product-edit"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form__half">
                <div className="form__form-group ">
                  <h4 className="Quick-pay-field-heading">
                    Fill out the details below:
                  </h4>
                </div>
              </div>
              <div className="form__half">
                <div className="form__form-group  quick-pay-container">
                  <div className="label-name-container">
                    <span className="form__form-group-label Quick-pay-label">
                      Full Name
                    </span>
                  </div>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      name="name"
                      className="Quick-pay-fields"
                      placeholder="e.g Just Padel"
                      {...register("name", { required: true })}
                    />
                  </div>
                  {errors.name && (
                    <span className="error-msg">Please Enter Name</span>
                  )}
                </div>
              </div>
              <div className="form__half">
                <div className="form__form-group quick-pay-container">
                  <div className="label-name-container">
                    <span className="form__form-group-label Quick-pay-label">
                      Email ID
                    </span>
                  </div>

                  <div className="form__form-group-field">
                    <input
                      type="email"
                      name="email"
                      className="Quick-pay-fields"
                      placeholder="e.g tyler.stewart@gmail.com"
                      {...register("email", { required: true })}
                    />
                  </div>
                  {errors.email && (
                    <span className="error-msg">Please Enter Valid Email</span>
                  )}
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group quick-pay-container">
                  <div className="label-name-container">
                    <span className="form__form-group-label Quick-pay-label">
                      Phone
                    </span>
                  </div>

                  <div className="form__form-group-field">
                    <input
                      type="text"
                      name="phone"
                      className="Quick-pay-fields"
                      placeholder="e.g +971 99 5528 022"
                      {...register("phone", { required: true })}
                    />
                  </div>
                  {errors.phone && (
                    <span className="error-msg">
                      Please Enter Valid Phone Number
                    </span>
                  )}
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group quick-pay-container">
                  <div className="label-name-container">
                    <span
                      className="form__form-group-label Quick-pay-label"
                      style={{ zIndex: 1 }}
                    >
                      Select Location
                    </span>
                  </div>
                  <div className="form__form-group-field">
                    <div className="form__form-group-field">
                      <Controller
                        name="subject"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={courtLocation}
                            placeholder={"Select Location"}
                          />
                        )}
                      />
                    </div>
                    {errors.subject && (
                      <span className="error-msg">Please Select Location</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group quick-pay-container">
                  <div className="label-name-container">
                    <span
                      className="form__form-group-label Quick-pay-label"
                      style={{ zIndex: 1 }}
                    >
                      Pay for one person
                    </span>
                  </div>
                  <div className="form__form-group-field">
                    <div className="form__form-group-field">
                      <Controller
                        name="minPlay"
                        control={control}
                        // defaultValue={minPlayOption[0]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={minPlayOption}
                            placeholder={"Select Pay for one person"}
                          />
                        )}
                      />
                    </div>
                    {errors.minPlay && (
                      <span className="error-msg">
                        Please Select Pay for one person
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group quick-pay-container">
                  <div className="label-name-container">
                    <span className="form__form-group-label Quick-pay-label">
                      Amount in AED
                    </span>
                  </div>
                  <div className="form__form-group-field">
                    <input
                      type="number"
                      name="amount"
                      className="Quick-pay-fields"
                      placeholder="e.g 100.00"
                      {...register("amount", { required: true })}
                      step="any"
                    />
                  </div>
                  {errors.amount && (
                    <span className="error-msg">Please Enter Amount</span>
                  )}
                </div>
              </div>
              <span className="termsAndConditions">
                By Continuing You Agree To Our Company{" "}
                <a
                  href="https://justpadel.ae/padel-privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Policies
                </a>{" "}
                And{" "}
                <a
                  href="https://justpadel.ae/terms-and-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </span>
              <button
                className="Quick-pay-buttons-save"
                type="submit"
                disabled={!isValid}
              >
                Proceed to payment
              </button>
              <div className="col-buttons"></div>
            </form>
          </div>
        </div>
      </Container>

      {signature ? (
        <form
          action="https://checkout.payfort.com/FortAPI/paymentPage"
          method="post"
          name="frm"
          target="_self"
          id="load_payment"
        >
          <input type="hidden" name="currency" value="AED" />
          <input type="hidden" name="amount" value={amount * 100} />
          <input type="hidden" name="customer_email" value={email} />
          <input type="hidden" name="command" value="PURCHASE" />
          <input type="hidden" name="language" value="en" />
          <input type="hidden" name="merchant_identifier" value="TXGKcfsS" />
          <input
            type="hidden"
            name="access_code"
            value="9NKhRXOyuU6C1vrXB4rL"
          />
          <input type="hidden" name="signature" value={signature} />
          <input
            type="hidden"
            name="merchant_reference"
            value={`JustPadelQuickPay-${id}`}
          />
          <input
            type="hidden"
            name="order_description"
            value={`JustPadelQuickPay${id}`}
          />
          <input
            type="hidden"
            name="return_url"
            value="https://api.justpadelae.com/quicks-pay-payment-return"
          />
        </form>
      ) : (
        ""
      )}
      <FooterMap />
    </div>
  );
};

export default SplitPay;
