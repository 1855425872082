import React, { useState } from 'react'
import { Row, Button} from 'reactstrap'
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router";
import { environment } from "../../env";
import Navbar from "../Navbar/Navbar";
import FooterMap from "../Footer/FooterMap";
import Icon from '@mdi/react';
import swal from "sweetalert";
import'./ResetPassword.css';


const ResetPassword = () => {
  const history = useHistory();


  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [PasswordValid, setPasswordValid] = useState(true);

  const password = watch("password");
  const confirm_password = watch("confirm_password");
  const [pwd2, setPwd2] = useState();
  const [pwd, setPwd] = useState();

  const [revealpwd, setRevealpwd] = useState(false)
  const [revealpwd2, setRevealpwd2] = useState(false)


  const onSubmit = (data) => {
    if (password !== confirm_password) {
      setPasswordValid(false);
    } else {
      setTimeout(() => {
      }, 2000);
      setPasswordValid(true);
      const forgetEmail = localStorage.getItem('forgetEmail');
      const password_is = {
        email: forgetEmail,
        password: confirm_password
      }
      const resetPasswordUrl = `${environment.baseUrl}resetpassword`;
      axios.post(resetPasswordUrl, password_is)
        .then((response) => {
          if (response.status !== 200) {
            alert("There is a problem while updating the password")
          }
          else {
            swal({
              title: "Success",
              text: "Password has been updated Successfully",
              icon: "success",
              button: "Close",
            }).then(() => {
              history.push("/");
            });
            // alert("Password has updated Successfully")
            // history.push("/")
          }
        })
    }
  };

  return (
    <div>
      <Navbar />
        <div className='reset'>
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">Welcome to
                <span className="account__logo"> JUST
                  <span className="account__logo-accent"> PADEL</span>
                </span>
              </h3>
              <h4 className="account__subhead subhead">Padel Courts of Choice</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
              <div className="reset-password">
                <div className="form login-form">
                  <div className="form__form-group" style={{marginBottom:15,marginTop:30}}>
                    <span
                      className="form__form-group-label"
                      style={{ fontFamily: "sans-serif", fontSize: 15,marginBottom:50 }}
                    >
                      Password
                    </span>
                    <div className="form__form-group-input justify-content-center" style={{ border: "none" }}>
                      <div className='password-border'>
                        <input
                          type= {revealpwd ? "text" : "password"}
                          className="input-without-border-radius reset-input"
                          placeholder="Enter Password"
                          {...register("password", { required: true, minLength: 8 })}
                          // style={{ width: "90%", fontSize:13,paddingLeft:8 }}
                          value={pwd}
                          onChange={(e) => setPwd(e.target.value)}
                        />
                        <span className='eye-input'  
                        onClick={() => setRevealpwd((prevState) => !prevState)}
                        >
                        {revealpwd ?  <AiOutlineEye className='reset-icon-font' /> : <AiOutlineEyeInvisible className='reset-icon-font' />}
                          </span>
                      </div>
                      {errors.password && errors.password.type === "minLength" && <span className="text-danger">Please enter the password length in minimum 8 characters</span>}
                    </div>
                  </div>


                  <div className="form__form-group">
                    <span
                      className="form__form-group-label"
                      style={{ fontFamily: "sans-serif", fontSize: 15 }}
                    >
                      Confirm Password
                    </span>
                    <div className="form__form-group-input justify-content-center">
                      <div className='password-border' >

                        <input
                          type= {revealpwd2 ? "text" : "password"}
                          className="input-without-border-radius reset-input"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          value={pwd2}
                          onChange={(e) => setPwd2(e.target.value)}
                          {...register("confirm_password", { required: true, minLength: 8 })}
                        />
                        <span className='eye-input'  
                        onClick={() => setRevealpwd2((prevState) => !prevState)}
                        >
                        {revealpwd2 ?  <AiOutlineEye style={{ fontSize: "14px" }} /> : <AiOutlineEyeInvisible style={{ fontSize: "14px" }} />}
                          </span>
                      </div>

                      {errors.confirm_password && errors.confirm_password.type === "minLength" && <span className="text-danger">Please enter the password length in minimum 8 characters</span>}
                    </div>
                    {PasswordValid ? "" : <span className='text-danger'> Please Enter Matching Password</span> } 
                  </div>


                  <div className="form__form-group" style={{ marginTop: 30 }}>

                    <Button className="account__btn-reset" color="warning">
                      Reset Password
                    </Button>

                  </div>
                </div>
                </div>
              </Row>
            </form>
          </div>
        </div>
      </div>
      <FooterMap />
      </div>
    </div>
  );
};

export default ResetPassword;
