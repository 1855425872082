import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Col, Button, ButtonToolbar, Card, CardBody } from 'reactstrap'
import axios from '../../CustomAxios';
import { environment } from '../../env';
import swal from "sweetalert";
import Navbar from '../Navbar/Navbar'
import FooterMap from "../Footer/FooterMap"
import { useLocation } from "react-router-dom";
import './ThankyouPage.css';
import moment from 'moment';


const ThankyouPage = () => {
  const [transactionDetails, setTransactionDetails] = useState();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();
  let id = query.get("id");

  const GetQuickPayDetails = (id) => {
    const QuickPayDetailsUrl = `${environment.baseUrl}quicks-pay/${id}/`;
    const result = axios.get(QuickPayDetailsUrl).then((response) => {
      setTransactionDetails(response.data);
    })
  }

  useEffect(() => {
    GetQuickPayDetails(id);
  }, []);


  return (
    <div>
      <Navbar />
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form className="form product-edit form-edit" style={{ display: "flex", flexDirection: "column" }}>

              <h2>Thank you for the payment!</h2><br />
              <div className="top-date-container">
                <span>Invoice Date: <strong>{moment(transactionDetails?.created_date?.split("T")[0]).format("DD-MM-YYYY")}{" "}{moment(transactionDetails?.created_date?.split("T")[1].split(".")[0], ["HH.mm.ss",]).format("hh:mm A")}</strong></span>
              </div>
              <div className="Thankyou-page-container">
                <div className="Thankyou-page-left">
                  <strong>Dear {transactionDetails?.name},</strong>
                  <strong>Greetings from Just Padel!</strong>
                  <strong>Your Quick Pay Payment is successful.</strong>
                  <strong>Payment ID :#{transactionDetails?.id}</strong>
                </div>
                <div className="Thankyou-page-Right">

                </div>
              </div>
              <table className="Thankyou-page-table">
                <tr>
                  <td className="Thankyou-td">
                    <img src="https://justpadel-courtimages.s3.amazonaws.com/clt.png" alt="icon" /> Name
                  </td>
                  <td className="Thankyou-td">{transactionDetails?.name}</td>
                </tr>
                <tr>
                  <td className="Thankyou-td">
                    <img src="https://justpadel-courtimages.s3.amazonaws.com/mail5.png" alt="icon" /> Email
                  </td>
                  <td className="Thankyou-td">{transactionDetails?.email}</td>
                </tr>
                <tr>
                  <td className="Thankyou-td">
                    <img src="https://justpadel-courtimages.s3.amazonaws.com/phone5.png" alt="icon" /> Phone
                  </td>
                  <td className="Thankyou-td">{transactionDetails?.phone}</td>
                </tr>
              </table>
              <div className="top-header-container">
                <h3>Payment Details :</h3>
              </div>

              <table className="Thankyou-page-colorTable">
                <tr >
                  <th className="Thankyou-page-colorTable-th">#</th>
                  <th className="Thankyou-page-colorTable-th">Payment For</th>
                  <th className="Thankyou-page-colorTable-th">Amount</th>
                  <th className="Thankyou-page-colorTable-th">Status</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>{transactionDetails?.subject}</td>
                  <td>{transactionDetails?.amount} AED</td>
                  <td style={{textTransform:'capitalize'}}>{transactionDetails?.payment_status}</td>
                </tr>
              </table>

              <div className="top-header-container">
                <strong className="thanks-greeting">If you need any assistance’s, Contact our Customer Support Team at +971 55 9928 022. Thank you!</strong>
              </div>

            </form>
          </CardBody>
        </Card>
      </Col>
      <FooterMap />
    </div>
  )
}

export default ThankyouPage