import React, {useState} from 'react'
import "./footer.css"
import PadelFooter from '../images/Artboard-1@11x-1.png'
import JustPadelName from '../images/Just-Padel-Black-Font-Logo.png'
import Visa from '../images/Untitled-3-01.png'
import Apple from '../images/Apple-Store-large-pcfm03us8azjxack9xwpyxnx768wodmkdq11834x1e.png'
import Android from '../images/Google-Play-large-pcfm0ztaoo7aw1253bq1bplle9vdy35fu47jjhtj5u.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faPhone, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom'
import WhatsAppLogo from "../../Assets/Images/whatsapp_logo.jpg"
library.add(fab)

const Footer = () => {
    const [center, setCenter] = useState({lat: 11.0168, lng: 76.9558 });
    const [zoom, setZoom] = useState(11);
    const AnyReactComponent = ({text}) => <div>{text}</div>;
    return (
        <div>
        {/* <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    
                    defaultCenter={center}
                    defaultZoom={zoom}
                >
                    <AnyReactComponent
                        lat={11.0168}
                        lng={76.9558}
                    />
                </GoogleMapReact>
            </div> */}

            <div className="footer-container">
                <div className="inner-footer">
                    <div className="footer-details">
                        <img src={PadelFooter} alt="padel-footer.jpg" className="PadelFooter"/>
                        <img src={JustPadelName} alt="padel-footer.jpg" className="JustPadelName"/>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/Justpadelae" target="_blank" className="social-circle">
                                <FontAwesomeIcon icon={["fab", "facebook"]} className="circleIcon"/>
                            </a>
                           <a href="https://www.instagram.com/justpadel_ae/" target="_blank" className="social-circle">
                                <FontAwesomeIcon icon={["fab", "instagram"]} className="circleIcon"/>
                            </a>
                           <a href="https://www.tiktok.com/@justpadel_ae" target="_blank" className="social-circle">
                                <FontAwesomeIcon icon={["fab", "tiktok"]} className="circleIcon"/>
                            </a>
                           <a href="https://www.linkedin.com/company/just-padel-ae/" target="_blank" className="social-circle">
                                <FontAwesomeIcon icon={["fab", "linkedin-in"]} className="circleIcon"/>
                            </a>
                           <a href="https://www.youtube.com/channel/UCdC3rPVxPjzjLVJzd8OPgcg" target="_blank" className="social-circle">
                                <FontAwesomeIcon icon={["fab", "youtube"]} className="circleIcon"/>
                            </a>
                       </div>
                    </div>
                    <div className="footer-details">
                        <div className="relate-link">
                            <h1>Quick Links</h1>
                        </div>
                        <div className="child-link-container">
                            <div className="child-link">
                                <h5> <a href="https://justpadel.ae/">Home</a></h5>
                                <h5><a href="https://justpadel.ae/about-us/">About Us</a></h5>
                                <h5><a href="https://justpadel.ae/rates/">Court Rates</a></h5>
                                <h5><a href="https://justpadel.ae/browse-events/">Browse Events</a></h5>
                                <h5><a href="https://justpadel.ae/contact-us/">Contact Us</a></h5>
                                <h5><a href="https://justpadel.ae/download-app/">Download the App</a></h5>
                                <h5><a href="https://justpadel.ae/careers/">Careers</a></h5>
                            </div>
                            <div className="child-link">
                                <h5><a href="https://justpadel.ae/blog/">Our Blogs</a></h5>
                                <h5><a href="https://justpadel.ae/invest-with-us/">Invest With us</a></h5>
                                <h5><a href="https://justpadel.ae/courts/">Our Locations</a></h5>
                                <h5><a href="https://justpadel.ae/wp-content/uploads/2022/01/MEMBERSHIP-PACKAGES-1-1.pdf">Our Packages</a></h5>
                                <h5><a href="https://justpadel.ae/padel-rules/">Padel Rules</a></h5>
                                <h5><a href="https://justpadel.ae/delivery-shipping-policy/">Delivery Policy</a></h5>
                                <h5><a href="https://justpadel.ae/padel-legal-notice/">Legal Notice</a></h5>
                            </div>
                        </div>
                    </div>
                    <div className="footer-details">
                        <div className="relate-link">
                            <h1>Contact US</h1>
                        </div>
                        <div className="footer-info">
                            <div className="child-info">
                                <div className="info-icons">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="infoIcon" />
                                </div>
                                <h5>Head Office: Just Play Sports Complex, 15A Street, Al Quoz Industrial 1 - Dubai</h5>
                            </div>
                            <div className="child-info">
                                <div className="info-icons">
                                    <FontAwesomeIcon icon={faPhone} className="infoIcon" />
                                </div>
                                <div className="info-contact">
                                    <h4>+971 55 9928 022</h4>
                                    <h4>+971 4 546 3800</h4>
                                </div>

                            </div>
                            <div className="child-info">
                                <div className="info-icons">
                                    <FontAwesomeIcon icon={faEnvelopeOpen} className="infoIcon" />
                                </div>
                                <h5>
                                <a href='mailto:info@justpadel.ae' style={{color:"black", textDecoration: "none"}}>info@justpadel.ae</a>
                                </h5>
                                {/* <a href="mailto:info@justpadel.ae" style="color:black;">info@justpadel.ae</a> */}
                            </div>
                            <div className="child-info chwp">
                                <div className="info-icons " > 
                                <a href="https://api.whatsapp.com/send?phone=971559928022" target="_blank" rel="nofollow">
                                    <img width="50" height="50" style={{borderRadius:"50%", border:"2px solid rgb(231, 231, 231)"}} src={WhatsAppLogo} class="attachment-full size-full" alt="" loading="lazy" />

                                    </a>
                                </div>
                                <div className='footer-wp' >
                                    <h3 className="whatsapp-Chat-title-title">
                                    <a href="https://api.whatsapp.com/send?phone=971559928022" target="_blank" style={{textDecoration: "none"}}  rel="nofollow">Chat with us </a>
                                    </h3> 
                                    
                                    <span className="whatsapp-Chat-title-subtitle" >  
                                        <a href="https://api.whatsapp.com/send?phone=971559928022" style={{textDecoration: "none",marginBottom:"15px"}} target="_blank" rel="nofollow">on WhatsApp</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="footer-Line"/>
                <div className="cpyright-container ">
                    <div className="inner-cpy-right">
                        <h5>Just Padel ©2023. All Rights Reserved.</h5>
                        <div className="conditions">
                            <h4><a href="https://justpadel.ae/padel-privacy-policy/">Privacy Policy</a></h4>
                            <h4><a href="https://justpadel.ae/terms-and-conditions/">Terms of Service</a></h4>
                            <h4><a href="https://justpadel.ae/refund-cancellation-policy/">Cancellation & Refund Policy</a></h4>
                            <img src={Visa} alt="" />
                        </div>
                    </div>
                    <div className="stores">
                        <a href="https://apps.apple.com/in/app/just-padel-courts-of-choice/id1587271749" target="_blank">
                        <img src={Apple} alt="" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.justpadel.courtsofchoice" target="_blank">
                        <img src={Android} alt="" />
                        </a>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Footer;
