import axios from 'axios';
import { environment } from './env';
import createHistory from 'history/createBrowserHistory';
import { LoginMethod } from './components/HOC/logic';
import swal from "sweetalert";

const customAxios = axios.create({
    baseURL: `${environment.baseUrl}`,
});

const requestHandler = request => {
    // const state = store.getState();
    // const userToken = state.LoginReducers.loggedUserDetails[0].token;
    // localStorage.setItem('Token',userToken);
    const userTokenLocal = localStorage.getItem('Token');
    request.headers.Authorization = `Bearer ${userTokenLocal}`;
    return request;
};

const responseHandler = response => {
    const history = createHistory();
    if (response.status === 401) {
        window.location.href = '/';
    }
    return response;
};

const errorHandler = (error) => {

    console.log("error.response.data.code >>",error.response.data.code);

    if (error?.response?.data?.code === "token_not_valid") {
        swal({
          title: "Your session has expired due to inactivity",
          text: `please login again to continue`,
          icon: "warning",
          timer: 5000,
          buttons: false,
        }).then(function () {
          localStorage.removeItem("userId");
          localStorage.removeItem("Token");
          localStorage.removeItem("LoggedUserDetails");
          window.location = "/";
        });
        return;
      }
      swal({  
        title: "Something went wrong!",
        text: `${error}`,
        icon: "warning",
        // timer: 5000,
        button: "Ok",
        // showConfirmButton: true,
      })

    // window.location.href = '/';
    // if (error.message === 'Request failed with status code 403'){
    //     const loginDetails = { loginEmail: 'justpadel_admin@gmail.com', loginPassword: 'Justpadel$2022#', source:'website' };
    //     LoginMethod(loginDetails.loginEmail, loginDetails.loginPassword).then((response) => {
    //         if (response.error_code === 24) {
    //         } else if (response.error_code === 23) {
    //         } else {
    //           localStorage.setItem('Token',response.access);
    //           localStorage.setItem("userId", response.userid);
    //         }
    //       });
          
    // }
    return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


export default customAxios;