/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Navbar from '../Navbar/Navbar';
import axios from '../../CustomAxios';
import { environment } from '../../env';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import "./payment.css";
import ReactGA from 'react-ga';

const Payment = () => {
    const [htmlContent, setHtmlContent] = useState("");
    const [payload, setPayload] = useState({})
    const [signature, setSignature] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    // const user = useSelector(state => state.loginReducer.userData);
    const user = JSON.parse(localStorage.getItem("LoggedUserDetails"));
    // const userToken = useSelector((state) => state.LoginReducers.loggedUserDetails)[0].token;
    const userToken = localStorage.getItem('Token');

    ReactGA.event({
        category: 'Payment',
        action: 'Payment Page',
        label: 'Payment Page'
    });

    useEffect(() => {
        setTimeout(() => {
            payments();
        }, 1000);

    }, []);
    //   [route?.params]
    const payments = () => {
        const localPayload = JSON.parse(localStorage.getItem("newPayload"));
        setPayload(localPayload);
        const genSignatureUrl = `${environment.baseUrl}checkout`;
        const params = JSON.stringify({
            "currency": "AED",
            "amount": `${Math.ceil(parseFloat((localPayload.price * 100)))}`,
            "customer_email": `${user.email}`,
            "language": "en",
            "order_description": `${localPayload?.court}`,
            //"order_description": "Mina Rashid 4",
            "merchant_reference": `${localPayload?.order_id}`,
            //Testing payment gateway starts here
            // "access_code": 'MTkAuRmgVl270B82kqey',
            // "merchant_identifier": 'fc9fe2bb',
            // "parse_phrase": '51MO1sxGDZPtHyGATVpuyG!@',
            //Testing payment gateway end here
            "command": "PURCHASE",
            "return_url": "https://api.justpadelae.com/payment_return_web/"
        });


        axios.post(genSignatureUrl, params, {
            "headers": {
                "content-type": "application/json",
                'Authorization': 'Bearer ' + userToken
            },
        })
            .then(function (data) {
                if (data.status === 200 || data.status === 201) {
                    console.log("Signature", data?.data);
                    setSignature(data?.data);
                    setTimeout(() => {
                        document.getElementById("load_payment").submit();
                    }, 1000);
                } else {
                    alert('Server error')
                    ReactGA.event({
                        category: 'Payment',
                        action: 'Payment Failed',
                        label: 'Payment Failed'
                    });
                }
            })
            .catch(function (error) {
                console.log(error)
                //   props.showLoader(false)
                ReactGA.event({
                    category: 'Payment',
                    action: 'Payment Failed',
                    label: 'Payment Failed'
                });
            });
    }

    return (
        <>
            <Navbar />
            <Loader type="TailSpin" color="#FDAE00" className="center" />
            <form action="https://checkout.payfort.com/FortAPI/paymentPage" method='post' name='frm' target="_self" id="load_payment" >

                <input type='hidden' name='currency' value='AED' />

                <input type='hidden' name='amount' value={Math.ceil(parseFloat((payload.price * 100)))} />

                <input type='hidden' name='customer_email' value={user.email} />

                <input type='hidden' name='command' value='PURCHASE' />

                <input type='hidden' name='language' value='en' />

                <input type='hidden' name='merchant_identifier' value='TXGKcfsS' />

                <input type='hidden' name='access_code' value='9NKhRXOyuU6C1vrXB4rL' />

                <input type='hidden' name='signature' value={signature?.signature} />

                <input type='hidden' name='merchant_reference' value={payload?.order_id} />

                <input type='hidden' name='order_description' value={payload?.court} />

                <input type='hidden' name='return_url' value='https://api.justpadelae.com/payment_return_web/' />

            </form>
        </>
    )
}

export default Payment;
