/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useReducer } from "react";
import Calendar from "react-calendar";
import "./bookingList.css";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams, Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import moment from "moment";
import axios from "../../CustomAxios";
import { environment } from "../../env";
import Modal from "../Modal";
import { Spinner } from "reactstrap";
import FooterMap from "../Footer/FooterMap";
import {
  GetPadelholicLevel,
  LoggedOut,
  GetMainCourt,
} from "../HOC/logicWithCustomAxios";
import ReactGA from "react-ga";
import GreetingUserDetails from "../GreetingUserDetails/GreetingUserDetails";


const addSubMinutesReducer = (state, action) => {
  switch (action) {
    case "add":
      return state + 45;
    case "sub":
      return state - 45;
    case "reset":
      return 0;
    default:
      throw new Error("unexpected actions");
  }
};
const selectedSlotReducer = (state, action) => {
  switch (action.type) {
    case "set":
      return action.itemDisplayVal;
    case "reset":
      return "06:00 AM";
    default:
      throw new Error("unexpected actions");
  }
};

const inititalSlotData = [];
const updateSlotsReducers = (state = inititalSlotData, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "set":
      return action.slotsData;
  }
};

function BookongList() {
  const [value, onChange] = useState(new Date());
  const [mainCourtData, setMainCourtData] = useState([]);
  const [filteredCourts, setFilteredCourts] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [selectedSlot, setSelectedSlot] = useState("06:00 PM");
  const [selectedSlotRange, setSelectedSlotRange] =
    useState("06:00 PM-06:45 PM");
  const [selectedDate, setSelectedDate] = useState();
  const [slotsArr, setSlotsArr] = useState([]);
  const [allSlots, setAllSlots] = useState([]);
  const [timeCounter, setTimeCounter] = useState(45);
  const [slotData, setslotData] = useState([]);
  const [selectedCourtId, setSelectedCourtId] = useState("");
  const [selectedSlotPrice, setSelectedSlotPrice] = useState();
  const [selectedCourt, setSelectedCourt] = useState("");
  const [selectedCourtDisplayName, setSelectedCourtDisplayName] = useState("");

  const [mainCourt, setMainCourt] = useState();
  const [userCartDetails, setUserCartDetails] = useState([]);
  const [modalShow, setmodalShow] = useState(false);
  const [timeSlot, setTimeSlot] = useState(false);
  const [courtSelected, setCourtSelected] = useState(true);
  const [resetAllOnCancel, setResetAllOnCancel] = useState("");
  const [mainCourtList, setMainCourtList] = useState([]);
  const [userData, setUserData] = useState();

  //-----------------------------------------------------------------
  // let userData = JSON.parse(localStorage.getItem("LoggedUserDetails"));

  const [timeSlotMinutes, dispatchAddSubMinutes] = useReducer(
    addSubMinutesReducer,
    0
  );
  const [rSelectedSlot, dispatchSelectedSlot] = useReducer(
    selectedSlotReducer,
    "06:00 PM"
  );
  const [rSlotData, dispatchSlotData] = useReducer(updateSlotsReducers, []);
  const [promotions, setPromotions] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [padelholicLevel, setPadelholicLevel] = useState();
  const [routeChange, setRouteChange] = useState(false);

  const filteredMainCourtData = mainCourtList.filter((data) => (data.id !== 12 && data.booking_facility !== 'Call Now Facility'));

  const getMainCourt = async (mainCourtId) => {
    const mainCourtsUrl = `${environment.baseUrl}maincourts/${mainCourtId}`;
    const result = await axios(mainCourtsUrl);
    setMainCourtData(result.data);
    let analyticsLbl = "Court View - " + result?.data.courtName;
    ReactGA.event({
      category: "Court View",
      action: analyticsLbl,
      label: analyticsLbl,
    });
  };

  const getUserCartDetails = async () => {
    const userId = localStorage.getItem("userId");

    const userCartUrl = `${environment.baseUrl}get_cart_user/${userId}`;
    const result = await axios(userCartUrl).then((response) => {
      if (response?.data?.data.length > 0) {
        const cartTimeslots = JSON.parse(
          response?.data?.data[0]?.timeslot.replace(/'/g, '"')
        );
        if (cartTimeslots.length > 0) {
          setUserCartDetails(cartTimeslots);
        }
      }
    });
  };

  const GetAllMainCourt = async (id) => {
    const mainCourtsUrl = `${environment.baseUrl}maincourts-admin/`;
    const result = await axios(mainCourtsUrl);
    setMainCourtList(result.data);
    const filteredMainCourt = result.data?.filter(
      (item) => item["courtName"].toLowerCase() === id
    );
    setMainCourt(filteredMainCourt[0]?.id);
    localStorage.setItem("newSelectedMainCourtId", filteredMainCourt[0]?.id);
    getMainCourt(filteredMainCourt[0]?.id);
  };

  const handleRouteChange = (e) => {
    //history.push(e.target.value);

    var link = `${environment.siteUrl}${e.target.value}`;

    window.location.href = link;
  };
  // useEffect(() => {
  //     return history.listen((location) => {
  //         if (location.pathname.includes("court/")) {
  //           let routeId = location.pathname.split("/");
  //           routeId = routeId[routeId.length - 1];
  //           window.scrollTo(0, 0);
  //           GetAllMainCourt(routeId.split(/[\-]+/).join(" "));
  //
  //           getUserCartDetails();
  //           setUserDetails(
  //             JSON.parse(localStorage.getItem("LoggedUserDetails"))
  //           );
  //         }
  //     })
  //  },[history])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("Token") !== null && localStorage.getItem("userId") !== 1) {
      GetAllMainCourt(id.split(/[\-]+/).join(" "));
      getUserCartDetails();
      setUserDetails(JSON.parse(localStorage.getItem("LoggedUserDetails")));
      PadelholicLevel(userData?.no_of_games);
      setUserData(JSON.parse(localStorage.getItem("LoggedUserDetails")));
    } else {
      openModalHandler();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("Token") !== null) {
      setMainCourt(mainCourt);
      setUserDetails(userDetails);
      handleDateSelection();
    } else {
      setmodalShow(true);
    }
  }, [mainCourt, userDetails]);

  const resetSelection = () => {
    setSelectedSlot("06:00 PM");
    setSelectedSlotRange("06:00 PM-06:45 PM");
    setslotData([]);
    setSelectedCourtId("");
    setSelectedCourt("");
    setSelectedCourtDisplayName("");
    setSelectedSlotPrice();
    setTimeCounter(0);
  };

  const handleDateSelection = (dt) => {
    onChange(dt);
    setSpinner(true);
    resetSelection();
    const date = moment(dt).format("YYYY-MM-DD");
    setSelectedDate(date);

    const availableSlotsPayload = {
      date: date,
      maincourtId: mainCourt
        ? mainCourt
        : parseInt(localStorage.getItem("newSelectedMainCourtId")),
    };

    const availableslotUrl = `${environment.baseUrl}new-availableslots/`;

    var result = axios
      .post(availableslotUrl, availableSlotsPayload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {

          const unique = [];

          response.data.map(x => unique.filter(a => a.time == x.time && a.date == x.date).length > 0 ? null : unique.push(x));

          const availableSlotByDate = unique.filter((slot) => {
            return slot.date === date;
          });
          let modifiedSlots = [];
          availableSlotByDate.forEach((slot) => {
            let newSlot = {
              date: slot.date,
              id: slot.id,
              time: slot.time,
            };
            const availableSlots = JSON.parse(
              slot.availableslot.replace(/'/g, '"')
            );
            let newAvailableSlots = availableSlots.filter((aslot) => {
              return aslot.main_court == mainCourt
                ? mainCourt
                : parseInt(localStorage.getItem("newSelectedMainCourtId"));
            });
            newSlot["availableslot"] = newAvailableSlots;
            newSlot["available"] = newAvailableSlots.length ? true : false;
            modifiedSlots.push(newSlot);
          });
          setSpinner(false);

          let modifiedSlotArray = modifiedSlots.map((slot) => ({
            ...slot,
            displayVal: slot.time.split("-")[0].trim(),
            timestamp: moment(
              date + " " + slot.time.split("-")[0].trim(),
              "YYYY-MM-DD, hh:mm A"
            ).format("X"),
          }));
          modifiedSlotArray = modifiedSlotArray
            .sort((a, b) => b.timestamp - a.timestamp)
            .reverse();

          setSlotsArr(modifiedSlotArray);
          setAllSlots(modifiedSlotArray);
        } else {
          alert("Server error");
        }
      });
  };

  function handleTimeSelection(e) {
    setslotData([]);
    setSelectedCourtId("");
    setSelectedCourt("");
    setSelectedCourtDisplayName("");
    setSelectedSlotPrice();
    setTimeCounter(0);
    const time = e.target.value;

    setSelectedSlot(time.split("-")[0].trim());

    setSelectedSlotRange(time);
    dispatchSelectedSlot({ type: "set", itemDisplayVal: time.split("-")[0] });
    const filteredSlotsByTime = slotsArr.filter((slot) => slot.time === time);

    if (filteredSlotsByTime?.length > 0) {
      const availableCourts = eval(filteredSlotsByTime[0]?.availableslot);
      // !important uncomment after main cort field added
      // eslint-disable-next-line radix
      const availableCourtsUnique = [];
      const map = new Map();

      for (const item of availableCourts) {
        if (!map.has(item.courtname)) {
          map.set(item.courtname, true); // set any value to Map
          availableCourtsUnique.push({
            courtname: item.courtname,
            display_name: item.display_name,
            main_court: item.main_court,
            Price: item.Price,
            courttype: item.court_type,
            ladiesOnly: item.ladiesOnly,
            id: item.id,
            promotion: item.promotion,
          });
        }
      }
      // setFilteredCourts(availableCourtsUnique.sort((a, b) => (a.courtname > b.courtname) ? 1 : -1) ?? []);
      const newava = availableCourtsUnique.sort(
        (a, b) => a.courtname - b.courtname
      );
      setFilteredCourts(availableCourtsUnique ?? []);
    }
  }
  // -------------commented because alert pop up comming on court selection------------
  // function handleCourtSelection(item) {
  //     setslotData([]);
  //     setTimeCounter(0);
  //     setSelectedCourtId(item.id);
  //     setSelectedCourt(item.courtname);
  //     setSelectedSlotPrice(item?.Price);
  //     setCourtSelected(false);
  //     addMin(item, "onCourtSelection");
  // }
  const handleCourtSelection = async (item) => {
    const newCourtId = item?.id;
    setCourtSelected(false);
    if (selectedCourtId !== newCourtId) {
      setslotData([]);
      dispatchSelectedSlot({ type: "set", itemDisplayVal: slotsArr[0]?.time });
      dispatchSlotData({ type: "set", slotsData: [] });
      dispatchAddSubMinutes("reset");
      getMainCourt(mainCourt);
      setSelectedCourtId(item?.id);
      setSelectedCourt(item?.courtname);
      setSelectedCourtDisplayName(item?.display_name);
      setSelectedSlotPrice(item?.Price);
      setPromotions(item?.promotion);
      addMin(item, "onCourtSelection");
    }
  };

  const addMin = async (item, param2) => {
    let slotsArray = [];

    const nonStateSelectedCourt = item?.courtname
      ? item?.courtname
      : selectedCourt;

    const nonStateSelectedCourtDisplayName = item?.display_name
      ? item?.display_name
      : selectedCourtDisplayName;
    const nonStateSelectedCourtId = item?.id ? item?.id : selectedCourtId;
    const nonStateSelectedSlotPrice = toString(item?.Price)
      ? item?.Price
      : selectedSlotPrice;

    slotsArray = param2 === "onCourtSelection" ? [] : rSlotData;

    if (!nonStateSelectedCourt) {
      alert("Please Select Court");
      return;
    }

    let dateObj1 = moment(
      selectedDate + ", " + rSelectedSlot,
      "YYYY-MM-DD, hh:mm A"
    )
      .add(45, "minutes")
      .format("hh:mm A");
    let prevdateObj1 = moment(
      selectedDate + ", " + rSelectedSlot,
      "YYYY-MM-DD, hh:mm A"
    )
      .add(-45, "minutes")
      .format("hh:mm A");

    const timeslot = rSelectedSlot + "-" + dateObj1;
    const prevtimeslot = prevdateObj1 + "-" + rSelectedSlot;
    const booking_date = selectedDate;
    const court = parseInt(nonStateSelectedCourtId);
    const maincourt = parseInt(mainCourt);
    const promotion = item?.promotion ? item.promotion : promotions;

    const matchingSlot = allSlots.filter(a => { return a.time === timeslot });
    if (!matchingSlot[0]?.available) {
      alert("Slot isn't available");
      return;
    }

    const slotsArrNew = slotsArr.filter((i) => timeslot.includes(i.time));
    if (slotsArrNew.length == 0) {
      alert("There is no available slots for booking");
      return;
    } else {
      dispatchAddSubMinutes("add");
      // setTimeCounter(prev => timeCounter + 45)
      let secondSlotPrice = true;
      let dbprice;
      if (timeSlotMinutes >= 45 && param2 !== "onCourtSelection") {
        secondSlotPrice = true;
        const padelcourtUrl = `${environment.baseUrl}padelcourt-promotion/${court}/${booking_date}`;
        await axios.get(padelcourtUrl).then((response) => {
          dbprice = response.data.additional_slot_price;
        });
      } else {
        secondSlotPrice = false;
      }

      const price = secondSlotPrice ? dbprice : nonStateSelectedSlotPrice;
      const court_type = secondSlotPrice ? "child" : "parent";
      const maincourt_name = mainCourtData.courtName;
      const display_name = nonStateSelectedCourtDisplayName
      dispatchSelectedSlot({ type: "set", itemDisplayVal: dateObj1 });

      slotsArray.push({
        timeslot,
        booking_date,
        court,
        court_name: nonStateSelectedCourt,
        maincourt,
        price,
        maincourt_name,
        promotion,
        court_type,
        display_name
      });
      dispatchSlotData({ type: "set", slotsData: slotsArray });
      setslotData(slotsArray);
    }
  };

  // const addMin = async () => {
  //     let slotsArray = [];
  //     slotsArray = slotData;
  //
  //     if (!selectedCourt) {
  //         alert("Please Select Court")
  //         return;
  //     }
  //
  //     let dateObj1 = moment(selectedDate + ', ' + selectedSlot, 'YYYY-MM-DD, hh:mm A').add(45, 'minutes').format('hh:mm A');
  //     let prevdateObj1 = moment(selectedDate + ', ' + selectedSlot, 'YYYY-MM-DD, hh:mm A').add(-45, 'minutes').format('hh:mm A');
  //     const timeslot = selectedSlot + "-" + dateObj1;
  //     const prevtimeslot = prevdateObj1 + "-" + selectedSlot;
  //
  //     const booking_date = selectedDate;
  //     const court = parseInt(selectedCourtId);
  //     const maincourt = parseInt(mainCourt);
  //     const price = selectedSlotPrice;
  //     const maincourt_name = mainCourtData.courtName;
  //     // const prevtimeslot = prevdateObj1+"-"+selectedSlot;

  //     const slotsArrNew = slotsArr.filter(i => timeslot.includes(i.time));
  //     if (slotsArrNew.length === 0) {
  //         alert("There is no available slots for booking");
  //         // selectedSlot = prevtimeslot;
  //         setSelectedSlot(timeslot)
  //         return;
  //     } else {

  //         setTimeCounter(prev => timeCounter + 45)
  //         let pp = true;
  //         let dbprice;
  //         if (timeCounter >= 45) {
  //             pp = true;
  //             const padelcourtUrl = `${environment.baseUrl}padelcourt/${court}`;
  //             await axios.get(padelcourtUrl)
  //                 .then((response) => {
  //                     dbprice = response.data.additional_slot_price;
  //                 });
  //         }
  //         else {
  //             pp = false;
  //         }
  //
  //         const price = (pp ? dbprice : selectedSlotPrice);
  //         const maincourt_name = mainCourtData.courtName;

  //         setTimeCounter(prev => timeCounter + 45);
  //         setSelectedSlot(dateObj1);
  //
  //         slotsArray.push({
  //             timeslot,
  //             booking_date,
  //             court,
  //             maincourt,
  //             price,
  //             maincourt_name,
  //         });
  //         setslotData(setslotData);
  //
  //     }
  // }

  const subMin = () => {
    // if (timeCounter) {
    if (!selectedCourt) {
      alert("Please Select Court");
      return;
    }
    slotData.splice(-1);
    let dateObj1 = moment(
      selectedDate + ", " + selectedSlot,
      "YYYY-MM-DD, hh:mm A"
    )
      .add(-45, "minutes")
      .format("hh:mm A");
    setSelectedSlot(dateObj1);
    dispatchSelectedSlot({ type: "set", itemDisplayVal: dateObj1 });
    dispatchAddSubMinutes("sub");
    // }
  };

  const PadelholicLevel = (games) => {
    GetPadelholicLevel().then((response) => {
      const padelHolicLevel = games;
      const padelLevels = response;
      let matchingPadelLevel = padelLevels.find(
        (element) => element.games >= padelHolicLevel
      );
      if (matchingPadelLevel == null) {
        matchingPadelLevel = padelLevels[padelLevels.length - 1];
      }
      setPadelholicLevel(matchingPadelLevel.level);
      localStorage.setItem("PadelHolicLeveIs", matchingPadelLevel.level);
    });
  };

  let userId = parseInt(localStorage.getItem("userId"));
  const UserToken = localStorage.getItem("Token");

  const handleSubmit = () => {
    try {
      let existingWithNewCartSlotData = userCartDetails;
      slotData.forEach((s) => {
        existingWithNewCartSlotData.push(s);
      });

      const addToCartPayload = {
        main_court_id: mainCourt,
        court_id: selectedCourtId,
        court_name: selectedCourt,
        timeslot: selectedSlotRange,
        date: selectedDate,
        price: selectedSlotPrice,
        selected_slot: JSON.stringify(existingWithNewCartSlotData),
        user: userId,
      };

      const addToCartUrl = `${environment.baseUrl}addtocart`;
      const result = axios.post(addToCartUrl, addToCartPayload).then(
        (response) => {
          // localStorage.setItem('selectionDetails', JSON.stringify(slotData));
          history.push({ pathname: "/booking-addons" });
        },
        (error) => {
          console.log(error);
        }
      );

      //Google Analytics
      ReactGA.plugin.require("ecommerce");
      ReactGA.plugin.execute("ecommerce", "addItem", {
        id: selectedCourtId + "--" + selectedDate,
        sku: selectedCourtId,
        name: mainCourtData?.courtName,
        price: selectedSlotPrice,
        category: "Court",
        quantity: "1",
        currency: "AED",
      });
      ReactGA.plugin.execute("ecommerce", "send");
      ReactGA.plugin.execute("ecommerce", "clear");
    } catch (error) {
      console.log("add to cart error: ", error);
    }
  };

  const goBack = () => {
    history.push({ pathname: "/booknow" });
  };
  const openModalHandler = () => {
    setmodalShow(!modalShow);
  };
  const closeModalHandler = () => {
    setUserData(JSON.parse(localStorage.getItem("LoggedUserDetails")));
    setmodalShow(!modalShow);
    window.location.reload();
  };

  const GotoMyBookings = () => {
    history.push("/booking-list");
  };

  return (
    <div className="root-container">
      <Navbar modal={modalShow} />
      {modalShow ? (
        <div className="back-shed"></div>
      ) : null}
      <div className="center-spinner">
        {spinner ? (
          <div className="backgroungSpinner">
            <Spinner />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="calender-Container">

        <GreetingUserDetails />

        <div className="booking-padel-name">
          <div className="selected-court">
            <h3
              class="page-title book-court"
              style={{
                fontFamily: "Poppins",
                fontSize: "medium",
                width: "100%"
              }}
            >
              <span> Book Padel Court -</span>
              <select
                className="selectcourt"
                value={`court/${mainCourtData?.courtName
                  ?.split(/[\s]+/)
                  .join("-")
                  .toLowerCase()}`}
                id=""
                onChange={handleRouteChange}
              >
                {filteredMainCourtData?.map((data, index) => {
                  return (
                    <option
                      value={`court/${data?.courtName
                        ?.split(/[\s]+/)
                        .join("-")
                        .toLowerCase()}`}
                    >
                      {data?.courtName}
                    </option>
                  );
                })}
              </select>
            </h3>
          </div>
        </div>
        <div className="inner-calender-booking">
          <div className="inner-lhs">
            <Calendar
              minDate={new Date()}
              value={value}
              onChange={handleDateSelection}
            />
          </div>

          <div className="inner-rhs">
            <div className="sel-and-time">
              <div className="select-time">
                <h2>Select Time</h2>
                <select
                  className="timeSlot"
                  value={selectedSlotRange}
                  onChange={handleTimeSelection}
                >
                  <option key="0">Select Time Slot</option>
                  {slotsArr &&
                    slotsArr.map((item, index) => (
                      <option
                        key={index}
                        value={item.time}
                        disabled={!item.available}
                        style={{ color: item.available ? "#000" : "#767676" }}
                      >
                        {item.time}
                      </option>
                    ))}
                </select>
              </div>
              <div className="select-inc-dec-container">
                {filteredCourts.length ? (
                  <div className="select-inc-dec">
                    <h2>Select Mins</h2>
                    <div className="time-inc-dec">
                      <div className="bg-fa-minuts">
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="faMinus"
                          onClick={timeSlotMinutes === 0 ? "" : () => subMin()}
                        />
                      </div>
                      {/* <div className="timeline__icon file" style={{ margin: '0px 5px', "color": timeCounter ? "#000" : "#767676" }} onClick={() => subMin()}><span className="lnr lnr-circle-minus" /></div> */}
                      {/* <h6>{timeCounter}Min</h6> */}
                      {/* <small>{timeCounter}Min{timeSlotMinutes}</small> */}
                      <small>{timeSlotMinutes} Mins</small>
                      <div className="bg-fa-minuts">
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="faPlus"
                          onClick={() => addMin()}
                        />
                      </div>
                      {/* <div className="timeline__icon file" style={{ margin: '0px 5px' }} onClick={() => addMin()}><span className="lnr lnr-plus-circle" /></div> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="cart-container">
              <h2>Select Available Courts</h2>
              <div className="cart">
                {filteredCourts.length ? (
                  filteredCourts.map((court) =>
                  (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                      className="clild-cart"
                      onClick={() => handleCourtSelection(court)}
                      style={{
                        backgroundColor:
                          court.id == selectedCourtId ? "#ffc107" : "#efeeee",
                      }}
                    >
                      {/* ripe market samsung changes */}
                      {/* {(court.main_court === 5 && court.courtname === '3') ? (<h5
                        style={{
                          color: court.id == selectedCourtId ? "#fff" : "#000",
                          fontSize: 14,
                          fontWeight: 550,
                        }}
                        className="number"
                      >
                        {'Samsung Court'}
                      </h5>) : (<h5
                        style={{
                          color: court.id == selectedCourtId ? "#fff" : "#000",
                          fontSize: 17,
                          fontWeight: 500,
                        }}
                        className="number"
                      >
                        {court.courtname}
                      </h5>)} */}
                      <h5
                        style={{
                          color: court.id == selectedCourtId ? "#fff" : "#000",
                          fontSize: 17,
                          fontWeight: 500,
                        }}
                        className="number"
                      >
                        {court.display_name}
                      </h5>
                      <h5
                        style={{
                          color: court.id == selectedCourtId ? "#fff" : "#000",
                          textTransform: "uppercase",
                          fontSize: 14,
                        }}
                        className="doors"
                      >
                        {court.courttype}
                      </h5>
                      <div>
                        {court.ladiesOnly === "True" ? (
                          <h5
                            className="ladies-only"
                            style={{
                              textTransform: "uppercase",
                              color: "#ff0000",
                              textAlign: "center",
                              fontSize: 12,
                              fontWeight: "bold",
                            }}
                          >
                            Ladies Only
                          </h5>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <h3 className="choose-code-text">Choose time slots to view the available courts.</h3>
                )}
              </div>
            </div>
            <div>
              <Modal
                className="modal"
                show={modalShow}
                close={closeModalHandler}
              ></Modal>
            </div>
          </div>
        </div>

        <div className="court-submit-btns">
          {userId !== 1 && UserToken !== "" ?
            <>
              <button
                className="add"
                onClick={handleSubmit}
                disabled={courtSelected}>
                Proceed to cart
              </button>
              <button className="cancel" onClick={goBack}>
                Cancel
              </button>
            </>
            : ""
          }

        </div>
      </div>
      <FooterMap />
    </div>
  );
}

export default BookongList;