import React from 'react'
import { Col, Card, CardBody } from 'reactstrap'
import Navbar from '../Navbar/Navbar'
import FooterMap from "../Footer/FooterMap"

const PayementError = () => {
  return (
    <div>
      <Navbar />
      <Col sm={12} md={12} >
        <Card>
          <CardBody>
            <h2 style={{ textAlign: "center", padding:40 }}>Payment Unsuccessful!</h2>
          </CardBody>
        </Card>
      </Col>
      <FooterMap />
    </div>
  )
}

export default PayementError