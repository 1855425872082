import React, { useState, useEffect, Fragment } from 'react';
import { GetPadelholicLevel } from '../HOC/logicWithCustomAxios';
import { Link, useHistory } from 'react-router-dom';
import EditProfileModal from './EditProfileModal';
import proImg from '../../Assets/Images/just_padel.png';
import BookingListModal from '../BookingHistory/BookingListModal';
import { environment } from '../../env'
import axios from '../../CustomAxios';
import userPic from '../../Assets/Images/userPic.png';
import './GreetingUserDetails.css'

const GreetingUserDetails = () => {
  const history = useHistory();
  const [padelholicLevel, setPadelholicLevel] = useState();
  const [modalShow, setmodalShow] = useState(false);
  const [BookingModalShow, setbookingModalShow] = useState(false);
  const [profileImage, setProfileImage] = useState(false);
  const [editData, setEditData] = useState(false);



  let userId = parseInt(localStorage.getItem("userId"));
  let userEmail = JSON.parse(localStorage.getItem("LoggedUserDetails"))
  let Token = localStorage.getItem("Token")

  const PadelholicLevel = (games) => {
    if (userEmail?.no_of_games) {
      GetPadelholicLevel().then((response) => {
        const padelHolicLevel = games;
        const padelLevels = response;

        if(response !== undefined){
          let matchingPadelLevel = padelLevels.find(
            (element) => element?.games >= padelHolicLevel
          );
          if (matchingPadelLevel == null) {
            matchingPadelLevel = padelLevels[padelLevels.length - 1];
          }
          setPadelholicLevel(matchingPadelLevel.level);
          localStorage.setItem("PadelHolicLeveIs", matchingPadelLevel.level);
        }
      }, (error) => {
        console.log("Padelholic lvel", error);
      });
    }else{
      if(Token){
        GetPadelholicLevel().then((response) => {
          const padelHolicLevel = games;
          const padelLevels = response;
  
          if(response !== undefined){
            let matchingPadelLevel = padelLevels?.find(
              (element) => element?.games >= padelHolicLevel
            );
            if (matchingPadelLevel == null) {
              matchingPadelLevel = padelLevels[padelLevels.length - 1];
            }
            setPadelholicLevel(matchingPadelLevel.level);
            localStorage.setItem("PadelHolicLeveIs", matchingPadelLevel.level);
          }
        }, (error) => {
          console.log("Padelholic lvel", error);
        });
      }
      
    }
  };

  const GotoMyBookings = () => {
    history.push("/booking-list");
  };

  const openModalHandler = () => {
    setmodalShow(!modalShow);
  };


  const closeModalHandler = () => {
    setmodalShow(!modalShow);
  };
  const openBookingModalHandler = () => {
    setbookingModalShow(!BookingModalShow);
  };
  const closeBookingModalHandler = () => {
    setbookingModalShow(!BookingModalShow);
  };

  const handleProfilePic = (editData) => {
    setProfileImage(editData.profile_pic)
    setEditData(editData)
  }

  const getUserDetails = async () => {
    if (userEmail?.email) {
      try {
        const getUserDetailsUrl = `${environment.baseUrl}getUserDetails/${userEmail?.email}/`
        const result = await axios(getUserDetailsUrl).then((res) => {
          setEditData(res.data)
        })
          .catch((error) => {
            console.log("Error", error);
          })

      } catch (err) {
        console.log("Error", err);
      }
    }
  };

  useEffect(() => {
    getUserDetails();
    PadelholicLevel(userEmail?.no_of_games);
  }, [])



  return (
    <Fragment>

      {modalShow ? (
        <div className="back-shed">
          <EditProfileModal
            className="modal"
            show={modalShow}
            close={closeModalHandler}
            EditData={handleProfilePic}
          />
        </div>
      ) : null}

      {BookingModalShow ? (
        <div className="back-shed">
          <BookingListModal
            className="modal"
            show={BookingModalShow}
            close={closeBookingModalHandler}
          />
        </div>
      ) : null}


      {userId !== 1 && editData && padelholicLevel ? (
        <div className="UserGreetingMainContainerlist">
          <div className="UserGreetingContainer">
            <div className="UserGreetingContainer-lhs">
              <h4 className="greetingName">
                <div className='greetingProfileImageContainer'>
                  <img src={editData?.profile_pic ? editData?.profile_pic : userPic} alt="profile Image" className='greetingProfileImage' />
                </div>
                Dear{" "}
                {editData?.first_name === "null" ? "Player" : editData?.first_name},
              </h4>
              <span className="greetingEmail">
                {editData?.email} | {padelholicLevel}{" "}
              </span>
            </div>
            <div className="UserGreetingContainer-rhs">

              <button
                className="UserGreetingContainer-btn "
                onClick={openModalHandler}
              >
                Edit Profile
              </button>
              <button
                className="UserGreetingContainer-btn"
                onClick={openBookingModalHandler}
              >
                My Bookings
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  )
}

export default GreetingUserDetails;
