import React, { useState, useEffect } from 'react';
import './BookingListModal.css';
import BookingHistory from './BookingHistory';
import { AiOutlineClose } from 'react-icons/ai';
import UserBooking from './UserBooking';




const BookingListModal = (props) => {
  const [isContentOneON, setContentOneON] = useState(true);
  const [isContentTwoON, setContentTwoON] = useState(false);

  // onClick= {() => selectContent("1")}
  const selectContent = (val) => {
    setContentOneON(false);
    setContentTwoON(false);

    if (val === "1") {
      setContentOneON(true);
    }
    if (val === "2") {
      setContentTwoON(true);
    }

  }

  return (
    <div>
      <div
        className="booling__list-modal-wrapper"
        style={{
          transform: props.show ? "translateY(0vh)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0",
          zIndex: props.show ? "1" : "-1",
        }}
      >
        <div className="modal-header">
          <span className="close-modal-btn">
            Booking History
          </span>
          <span className="close-modal-btn" onClick={props.close}>
            <AiOutlineClose />
          </span>
        </div>

        <div className="modal-body">

          <div className='inner_modal-body'>
            <div className='toggleButtons'>
              <button onClick={() => selectContent("1")} className={isContentOneON ? "toggle__btn-active" : "toggle__btn-diactive"}>My Booking</button>
              <button onClick={() => selectContent("2")} className={isContentTwoON ? "toggle__btn-active" : "toggle__btn-diactive"}>Manual</button>
            </div>
            <div className='toggle__body_content'>
              {/* -----------------My Bookings------------------ */}
              {isContentOneON && <div>
                <BookingHistory />
              </div>}
              {/* ----------------------Manual-------------------------- */}
              {isContentTwoON && <div>
                <UserBooking />
              </div>}

            </div>
          </div>

        </div>
        {/* <div className="modal-footer">
                  <p>This is Modal footer</p>
              </div> */}
      </div>
    </div>
  )
}

export default BookingListModal