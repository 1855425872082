import React from 'react';
import Navbar from '../Navbar/Navbar'

function ContactUs() {
    return (
        <div>
            <Navbar />
            contact us
        </div>
    )
}

export default ContactUs
