import React, { useEffect, useState } from "react";
import axios from "../../../CustomAxios";
import { environment } from "../../../env";

const LoyaltyPointsEarned = ({ bookingId }) => {
    const [loyaltyPointsDetails, setLoyaltyPointsDetails] = useState();

    const GetLoyaltyPoints = () => {
      setLoyaltyPointsDetails();
        const LoyaltyPointsURL = `${environment.baseUrl}loyaltypoint/loyalty-points-by-booking/${bookingId}`;
        axios.get(LoyaltyPointsURL).then((loyaltyPointsResponse)=>{
            setLoyaltyPointsDetails(loyaltyPointsResponse?.data)
        }).catch((error)=>{
            console.log(error);
        })
    };

    const LoyaltyPoints = (loyaltyPointsData) => {
        if (loyaltyPointsData?.type === "earned") {
          return loyaltyPointsData?.loyaltypoints;
        } else if (loyaltyPointsData?.type === "error") {
          return loyaltyPointsData?.loyaltypoints;
        } else {
          return 0;
        }
      };

      useEffect(() => {
        GetLoyaltyPoints();
    }, []);

  return (
    <>
    {LoyaltyPoints(loyaltyPointsDetails) > 0 && (
        <div style={{ padding: "10px", alignSelf:'center'}}>
        <span>Loyalty Points Earned: </span>
        <span style={{color:'#FDAE00'}}>{LoyaltyPoints(loyaltyPointsDetails)}</span>
      </div>
    )}
    </>
    
  );
};

export default LoyaltyPointsEarned;
