import React, { useEffect, useState } from 'react'
import './navbar.css'
import { Link, NavLink, useHistory } from 'react-router-dom'
import Logo from '../images/Just-Padel-Logo.png';
import { environment } from '../../env';
import Modal from "../Modal";
import { LoginMethod } from '../HOC/logic';


function Navbar({modal}) {
  const [modalShow, setmodalShow] = useState(false);
  const [userData, setUserData] = useState();
  const history = useHistory();
  const redirectAboutUss = () => {
    window.location = `https://justpadel.ae/about-us/`;
  }
  const redirectContactUs = () => {
    window.location = `https://justpadel.ae/contact-us/`;
  }
  const redirectEvents = () => {
    window.location = `https://justpadel.ae/browse-events/`;
  }
  const redirectCourts = () => {
    window.location = `${environment.siteUrl}courts/`;
  }
  const redirectHome = () => {
    window.location = `https://justpadel.ae/courts/`;
  }

  const UserId = parseInt(localStorage.getItem("userId"));
  const UserToken = localStorage.getItem("Token");

  const Logout = () => {       
    if (UserId !== 1) {
      localStorage.removeItem("userId");
      localStorage.removeItem("Token");
      localStorage.removeItem("LoggedUserDetails");
      history.push( "/courts");
      window.location.reload();      
    }
  };

  const openModalHandler = () => {
       setmodalShow(!modalShow);
       
  };
  const closeModalHandler = () => {
    setUserData(JSON.parse(localStorage.getItem("LoggedUserDetails")));
    setmodalShow(!modalShow);
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setUserData(JSON.parse(localStorage.getItem("LoggedUserDetails")));
  }, [])

  const handleLogin = () => {
    const loginDetails = { loginEmail: 'justpadel_admin@gmail.com', loginPassword: 'Justpadel$2022#', source:'website' };
    LoginMethod(loginDetails.loginEmail, loginDetails.loginPassword).then((response) => {
      if (response.error_code === 24) {
        console.log({ ErrorMessage: "Please Provide Valid Credentials", error: true });
      } else if (response.error_code === 23) {
        console.log({ ErrorMessage: response.message, error: true });
      } else {
        console.log({ ErrorMessage: "Logged Successful", error: true });
        window.location.reload();
      }
    });
  };

  return (
    <div>
      {modalShow ? (
        <div onClick={closeModalHandler} className="back-shed"></div>
      ) : null}
      <Modal
        className="modal"
        show={modalShow}
        close={closeModalHandler}
      />

      <div className="nav-components">
        <div className="nav-inner">
          <div className="logo-container">
            <a href="https://justpadel.ae/">
              <img className='img-logo' src={Logo} alt="justpadel.logo" width={200} />
            </a>
          </div>
          <div className="Navbar" >
            <ul style={{ marginBottom: 0 }}>
              <li>
                <Link onClick={redirectHome} className="navName" >Home</Link>
              </li>
              <li>
                <Link onClick={redirectAboutUss} className="navName">About Us</Link>
              </li>
              <li>
                <Link exact to="/courts" className="navName">Courts</Link>
              </li>
              <li>
                <Link onClick={redirectEvents} className="navName"  >Events</Link>
              </li>
              <li>
                <Link onClick={redirectContactUs} className="navName" >Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="nav-btn-container">

          {UserId !== 1 && UserToken !== null ?
           <a className="bookNow-btn" onClick={Logout}>LOGOUT</a>
            :
           <a className="bookNow-btn" onClick={modal ? "" :openModalHandler}>LOGIN</a>
        }

          {/* <Link to="/booknow" className="bookNow-btn">BOOK NOW!</Link> */}

            <input id="click" type="checkbox" />
            <label for="click" className="bar">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </label>
          </div>
        </div>

        
      </div>

      


    </div>
  )
}

export default Navbar

