import React from 'react'
import Navbar from '../Navbar/Navbar'


function Aboutus() {
    return (
        <div>
        <Navbar/>
            <h1>About Us</h1>
        </div>
    )
}

export default Aboutus;
