import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { AiFillEye } from "react-icons/ai";
import "./BookingModal.css";
import moment from "moment";
import { environment } from "../../../env";
import axios from "../../../CustomAxios";
import LoyaltyPointsEarned from "../../BookingHistory/LoyaltyPointsEarned/LoyaltyPointsEarned";

const BookingTimeslot = ({ booked_slots, courtName, padelcourt }) => {
  if (typeof booked_slots === "string") {
    var newbooked_slots = JSON.parse(booked_slots.replace(/'/g, '"'));
  } else {
    var newbooked_slots = booked_slots;
  }
  return (
    <div className="bookedSlotsContainer">
      {newbooked_slots.map((data) => {
        return (
          <>
            <div>{data?.maincourt_name ? data?.maincourt_name : courtName} Court-{padelcourt} </div>
            <div className="bookedSlots">
              {data?.Price}{" "}AED {" "} {"-"} {" "}{data?.date}{" "}, {data.time}
            </div>
          </>
        );
      })}
    </div>
  );
};

export const BookingModal = ({
  bookingId,
  invoice_date,
  singleslot,
  booked_slots,
  addons,
  Coupon,
  amountPercentage,
  amountPaid,
  courtName,
  padelcourt,
  cancelled,
  booking_date,
  packages,
}) => {
  const [modal, setModal] = useState(false);
  const [addonList, setAddonList] = useState([]);
  const [totalCourtAmount, setTotalCourtAmount] = useState(0);
  const [totalAddonAmount, setTotalAddonAmount] = useState(0);
  const [couponPercentage, setCouponPercentage] = useState();

  let vat = 0;
  let FinalAmount = 0;
  let balaceAmount = 0;

  const toggle = () => {
    setModal((prevState) => !prevState);
    addonFunction(addons);
    getCoupon();
  };

  var newbookedSlotBooked;

  if (typeof booked_slots === "string") {
    newbookedSlotBooked = JSON.parse(booked_slots.replace(/'/g, '"'));
  } else {
    newbookedSlotBooked = booked_slots;
  }

  const addonFunction = (data) => {
    let TotlaCourtPrice = 0;
    // ---------------------------------------- court price calculation starts here -------------------------------
    if (typeof booked_slots === "string") {
      var newbooked_slots = JSON.parse(booked_slots.replace(/'/g, '"'));
    } else {
      var newbooked_slots = booked_slots;
    }
    if (newbooked_slots.length === 1) {
      TotlaCourtPrice = newbooked_slots[0].Price;
      console.log("TotlaCourtPrice", TotlaCourtPrice);
      if (packages > 0) {
        setTotalCourtAmount(0);
      } else {
        setTotalCourtAmount(TotlaCourtPrice);
      }

    } else {
      TotlaCourtPrice = newbooked_slots.reduce(function (a, b) {
        return { Price: a.Price + b.Price };
      });
      console.log("TotlaCourtPrice", TotlaCourtPrice);
      if (packages > 0) {
        setTotalCourtAmount(0);
      } else {
        setTotalCourtAmount(TotlaCourtPrice?.Price);
      }
    }
    // ---------------------------------------- court price calculation starts here -------------------------------

    if (data === "null" || data === null) {
      setAddonList([]);
      return;
    }

    var match = data;
    let seperateComma = match.split(",");
    var arrays = [];
    var addonArray = [];
    for (var i = 0; i < seperateComma.length; i++) {
      arrays = seperateComma[i].replace(/'/gi, "").split("-");
      let objs = {};
      objs["id"] = parseInt(arrays[0]);
      objs["quantity"] = parseInt(arrays[1]);
      addonArray.push(objs);
    }
    const getAddonUrl = `${environment.baseUrl}addon`;
    axios.get(getAddonUrl).then((response) => {
      let addonNewList = response.data;
      let purchaseAddonList = addonArray;
      const result = addonNewList?.map(function (el) {
        var o = Object.assign({}, el);
        const quantity = purchaseAddonList?.find((e) => e.id === el.id);
        o.qty = quantity?.quantity === undefined ? 0 : quantity?.quantity;
        return o;
      });
      setAddonList(result);
      console.log("result", result);
      // ------------------------------------------Addon price calculation starts from here ------------------------------------
      let TotlaAddonPrice = 0;
      if (result.length === 0) {
        setTotalAddonAmount(TotlaAddonPrice);
      } else if (result.length === 1) {
        TotlaAddonPrice = result[0].price * result[0].qty;
        setTotalAddonAmount(TotlaAddonPrice);
      } else {
        TotlaAddonPrice = result.reduce(function (a, b) {
          return { price: a.price * a.qty + b.price * b.qty };
        });
        setTotalAddonAmount(TotlaAddonPrice?.price);
      }
      // ------------------------------------------Addon price calculation ends here ------------------------------------------
    });
  };

  const getCoupon = () => {
    const GetCouponUrl = `${environment.baseUrl}getcoupan`;
    axios.get(GetCouponUrl).then((response) => {
      // if(Coupon !== 'null'){
      let couponApplied = response.data.find((o) => o.name.toLowerCase() === Coupon.toLowerCase());
      console.log("couponApplied", couponApplied);
      couponApplied = couponApplied ? couponApplied : 0;
      setCouponPercentage(couponApplied);
      vat = (parseInt(totalCourtAmount + totalAddonAmount) / 100 * 5).toFixed(2);
      FinalAmount =
        totalCourtAmount +
        totalAddonAmount +
        vat -
        (totalCourtAmount + totalAddonAmount + vat) *
        parseInt(couponPercentage?.percentage_of_discount / 100).toFixed(2);
      balaceAmount = FinalAmount - amountPaid;
      // }
    });
  };


   // ---------------- Calculation for discount admount starts here ---------
   const calculateDiscountAmount = (courtamount, addonAmount, couponDetails, coupon) => {
    if(coupon === ("null" || "" || undefined)){
      return 0.00;
    }else{
      if(couponPercentage?.apply_for_addons){
        return Math.ceil(((courtamount+addonAmount)/100)*couponDetails?.percentage_of_discount);
      }else{
        return Math.ceil((courtamount/100)*couponDetails?.percentage_of_discount);
      }      
    }
  };

  // ---------------- Calculation for discount admount ends here -----------

  // ---------------- Calculation for vat code starts here --------------
  const VatCalculation = (courtamount, addonAmount, couponDetails, coupon) => {
    if(coupon === ("null" || "" || undefined)){
      return Math.ceil((courtamount + addonAmount) * 0.05);
    }else{
      if(couponPercentage?.apply_for_addons){
        return Math.ceil(((courtamount + addonAmount) - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon))*0.05)
      }else{
        return Math.ceil(((courtamount - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon))+addonAmount)* 0.05) 
      }
    }
  };

  // ---------------- Calculation for vat code ends here --------------

  // ---------------- Calculate total amount code starts here ---------
    const CalculateTotalAmount = (courtamount, addonamount, coupondetails, coupon) => {
      if(coupon === ("null" || "" || undefined)){
        return courtamount + addonamount + VatCalculation(courtamount, addonamount, coupondetails, coupon);
      }else{
        if(couponPercentage?.apply_for_addons){
          return courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon)-calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon));
        }else{
          return courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon)-calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon));
        }
      }
    };
  // ---------------- Calculate total amount code starts here ---------

  // ---------------- Paid to faculty calculation code starts here -------
    const PaidToFacultyCalculation = (courtamount, addonamount, coupondetails, Coupon, amountPaid) => {
      return CalculateTotalAmount(courtamount, addonamount, coupondetails, Coupon) - amountPaid;
    }
  // ---------------- Paid to faculty calculation code ends here ---------

  return (
    <div style={{ overflow: 'hidden' }}>
      <div
        style={{
          border: " 1px solid #110bcc",
          padding: 8,
          borderRadius: 4,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 1
        }}
        onClick={toggle}
      >
        <AiFillEye style={{ color: "#110bcc", fontSize: 20 }} />
      </div>
      <Modal isOpen={modal} toggle={toggle} className="modal-content">
        <div className="bookdetail">
          <ModalHeader toggle={toggle} className="ModalTitle text-center">
            <div className="div-modal-book">Booking Details</div>
          </ModalHeader>
        </div>
        <ModalBody className="modalBodyContent">
          <div className="bookingid">#{bookingId}</div>
          <div className="input-due" style={{ marginLeft: '10px' }}>
            <div>
              Invoice Date :
            </div>
            <div>
              {moment(invoice_date?.split("T")[0]).format("DD-MM-YYYY")}{" "}
              {moment(invoice_date?.split("T")[1].split(".")[0], [
                "HH.mm.ss",
              ]).format("hh:mm A")}
            </div>
          </div>

          <div className="input-due" style={{ marginLeft: '10px' }}>
            <div>
              Due Date :
            </div>
            <div>
              {moment(booking_date?.split("T")[0]).format("DD-MM-YYYY")}{" "}
              {newbookedSlotBooked[0]?.time.split("-")[0]}
            </div>
          </div>

          &nbsp;
          <div className="text-bold">Court Details : </div>
          <BookingTimeslot booked_slots={booked_slots} courtName={courtName} padelcourt={padelcourt} />
          {addonList.length > 0 ? (
            <>
              <div style={{ marginTop: "10px" }} className="text-bold">Add on's Details : </div>
              <table style={{ marginTop: "5px" }}>
                <thead className="trans-total-table-header">
                  <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Price </th>
                    <th>Quantity </th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {addonList.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{data?.addonname}</td>
                        <td>{data?.price}{" "}AED</td>
                        <td>{data?.qty}</td>
                        <td>{data?.price * data?.qty}{" "}AED</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <div className="addons ">Add on's Not Booked:</div>
          )}
          <div className="spacing space" style={{ marginTop: "10px", }}>
            <p className="spacing-modal">
              VAT 5% :
            </p>
            {VatCalculation(totalCourtAmount, totalAddonAmount, couponPercentage, Coupon).toFixed(2)}
            {/* {
              Coupon === ("null" || "" || undefined) ?
                Math.round(
                  ((totalCourtAmount + totalAddonAmount) / 100) * 5
                ).toFixed(2)
                : couponPercentage?.apply_for_addons ?
                  Math.round(((totalCourtAmount + totalAddonAmount) - (((totalCourtAmount + totalAddonAmount) / 100) * (couponPercentage?.percentage_of_discount))) * 0.05).toFixed(2)
                  : Math.round(((totalCourtAmount) - (((totalCourtAmount) / 100) * (couponPercentage?.percentage_of_discount)) + totalAddonAmount) * 0.05).toFixed(2)
            } */}
            {" "}AED

          </div>
          <div className="total-paid space">
            <span className="spacing-modal">
              Total Amount :
            </span>
            {CalculateTotalAmount(totalCourtAmount, totalAddonAmount, couponPercentage, Coupon).toFixed(2)}
            {/* {Coupon === ("null" || "" || undefined)
              ? Math.round(
                totalCourtAmount +
                totalAddonAmount +
                ((totalCourtAmount + totalAddonAmount) / 100) * 5
              ).toFixed(2)
              : couponPercentage?.apply_for_addons ?
                Math.round(
                  totalCourtAmount +
                  totalAddonAmount +
                  (((totalCourtAmount + totalAddonAmount) - (((totalCourtAmount + totalAddonAmount) / 100) * (couponPercentage?.percentage_of_discount))) * 0.05)
                ).toFixed(2)
                :
                Math.round(
                  totalCourtAmount +
                  totalAddonAmount +
                  (((totalCourtAmount) - (((totalCourtAmount) / 100) * (couponPercentage?.percentage_of_discount)) + totalAddonAmount) * 0.05)
                )
            } */}
            {" "}
            AED
            {/* {Coupon === "null"
              ? parseInt(
                totalCourtAmount +
                totalAddonAmount +
                ((totalCourtAmount + totalAddonAmount) / 100) * 5
              ).toFixed(2)
              : parseInt((totalCourtAmount + totalAddonAmount + ((totalCourtAmount + totalAddonAmount) / 100) * 5).toFixed(2) - (((totalCourtAmount + totalAddonAmount + ((totalCourtAmount + totalAddonAmount) / 100) * 5) / 100) * couponPercentage?.percentage_of_discount).toFixed(2)).toFixed(2)}{" "}

            AED */}

            {/* // : parseInt(
              //   (totalCourtAmount +
              //   totalAddonAmount +
              //   ((totalCourtAmount + totalAddonAmount) / 100) * 5).toFixed(2) -
              //   ((totalCourtAmount +
              //     totalAddonAmount +
              //     ((totalCourtAmount + totalAddonAmount) / 100) * 5) /
              //     100) *
              //   couponPercentage?.percentage_of_discount
              // ).toFixed(2)} */}
          </div>
          <div className="space"> <span className="spacing-modal"> Coupon Applied :
          </span>
            {Coupon === "null" ? "No " : Coupon}</div>
          <div className="space">
            <span className="spacing-modal">
              Discounted Price :
            </span>
            {calculateDiscountAmount(totalCourtAmount, totalAddonAmount, couponPercentage, Coupon).toFixed(2)}
            {/* {
              Coupon === ("null" || "" || undefined)
                ? (0.0).toFixed(2)
                : couponPercentage?.apply_for_addons ?

                  Math.round(
                    ((totalCourtAmount +
                      totalAddonAmount) /
                      100) *
                    couponPercentage?.percentage_of_discount
                  ).toFixed(2)

                  : Math.round(
                    ((totalCourtAmount / 100) *
                      couponPercentage?.percentage_of_discount
                    )).toFixed(2)
            } */}
            {" "}
            AED
            {/* {Coupon === "null"
              ? 0.0
              : Math.round(
                ((totalCourtAmount +
                  totalAddonAmount +
                  ((totalCourtAmount + totalAddonAmount) / 100) * 5) /
                  100) *
                couponPercentage?.percentage_of_discount
              ).toFixed(2)}{" "}
            AED */}
          </div>
          <div className="space">
            <p className="spacing-modal">
              Packages Used:
            </p>
            {((packages == 0) || (packages == "") || (packages == undefined)) ? "No" : packages}
          </div>

          <div className="space">
            <p className="spacing-modal">
              Amount Paid :
            </p>
            {amountPercentage === 1 || amountPercentage === 100 ? (
              <span>Paid 100%</span>
            ) : (
              <span style={{ marginBottom: 0 }}>Paid 50%</span>
            )}
          </div>
          <div className="space">
            <p className="spacing-modal">

              Amount Paid: </p> {amountPaid.toFixed(2)}{" "}AED

          </div>
          <div className="space">
            <p className="spacing-modal">
              To Be Paid :
            </p>
            {/* {Coupon === "null"
              ? parseInt(
                totalCourtAmount +
                totalAddonAmount +
                ((totalCourtAmount + totalAddonAmount) / 100) * 5 -
                amountPaid
              ).toFixed(2)
              : parseInt(
                (totalCourtAmount +
                  totalAddonAmount +
                  ((totalCourtAmount + totalAddonAmount) / 100) * 5).toFixed(2) -
                (((totalCourtAmount +
                  totalAddonAmount +
                  ((totalCourtAmount + totalAddonAmount) / 100) * 5) /
                  100) *
                  couponPercentage?.percentage_of_discount).toFixed(2) -
                amountPaid
              ).toFixed(2)}{" "}
            AED */}
            {PaidToFacultyCalculation(totalCourtAmount, totalAddonAmount, couponPercentage, Coupon, amountPaid).toFixed(2)}
            {/* {Coupon === ("null" || "" || undefined)
              ? Math.round(
                totalCourtAmount +
                totalAddonAmount +
                ((totalCourtAmount + totalAddonAmount) / 100) * 5 -
                amountPaid
              ).toFixed(2)
              : couponPercentage?.apply_for_addons ?
                Math.round((((totalCourtAmount + totalAddonAmount) - ((((totalCourtAmount + totalAddonAmount) / 100) * couponPercentage?.percentage_of_discount))) + ((totalCourtAmount + totalAddonAmount) - ((((totalCourtAmount + totalAddonAmount) / 100) * couponPercentage?.percentage_of_discount))) / 100 * 5) - amountPaid).toFixed(2)
                :
                Math.round((((totalCourtAmount + totalAddonAmount) - (((totalCourtAmount / 100) * couponPercentage?.percentage_of_discount))) + ((totalCourtAmount + totalAddonAmount) - (((totalCourtAmount / 100) * couponPercentage?.percentage_of_discount))) / 100 * 5) - amountPaid).toFixed(2)
            } */}
            {" "}
            AED
          </div>
          <LoyaltyPointsEarned bookingId={bookingId} />
          {/* className="BookingStatusCanceledText" */}
          <div className="ModalBookingStatusContaioner">
            <div className={cancelled ? "BookingStatusCanceledText" : "BookingStatusSucessText"} >
              {cancelled ? "Booking Cancelled" : "Booking successful"}
            </div>
          </div>
        </ModalBody>
        <div>

        </div>
      </Modal>
    </div>
  );
};

export default BookingModal;
