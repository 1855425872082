import React, { useEffect,useState } from 'react'
import { useLocation,useHistory } from "react-router-dom";
import axios from '../../CustomAxios';
import { environment } from '../../env';
import { LoginMethod } from '../HOC/logic';
import { TournamentPaymentMethods } from '../HOC/logicWithCustomAxios';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../Redux/Action';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TournamentPayment = () => {
    const [loginError, setLoginError] = useState({ ErrorMessage: "Please Provide Valid Credentials", error: false });
    const dispatch = useDispatch();
    const history = useHistory();
    const [signature, setSignature] = useState();
    
    const amountVerification = (amount) => {
      console.log(amount);
      if (amount.includes(",")) {        
        return amount.replace(/,/g, "");
      } else {
        return amount;
      }
    };

    let query = useQuery();
    // console.log(query.get(`amount`).split('.')[0]);
    let name = query.get("player1name");
    let lastname = query.get("player2name");
    let amount = amountVerification(query.get(`amount`).split('.')[0]);
    let email = query.get("email");
    let phone_no = query.get("phone_no");
    let tournament = query.get("tournament");
    let category = query.get("category");

    // console.log(name, lastname, amount, email, phone_no, tournament, category);

    const handleLogin = () => {
        const loginDetails = { loginEmail: 'justpadel_admin@gmail.com', loginPassword: 'Justpadel$2022#', source:'website' };
        LoginMethod(loginDetails.loginEmail, loginDetails.loginPassword).then((response) => {
          if (response.error_code === 24) {
            setLoginError({ ErrorMessage: "Please Provide Valid Credentials", error: true });
          } else if (response.error_code === 23) {
            setLoginError({ ErrorMessage: "Logged Successful", error: true });
            dispatch(loginUser(response.access, response.role, response.userid, response.username));
            TournamnetsPaymentsDataPost();
          } else {
            setLoginError({ ErrorMessage: "Logged Successful", error: true });
            dispatch(loginUser(response.access, response.role, response.userid, response.username));
            TournamnetsPaymentsDataPost();
          }
        });
      };
    

    const TournamnetsPaymentsDataPost = () =>{
        let SenderDetails = {
            first_name: name,
            last_name: lastname,
            amount: parseInt(amountVerification(amount)),
            email: email,
            phone:phone_no,
            tournament:tournament,
            category: category,
            transaction_log:'NO Transacation Log',
        }
        // console.log("SenderDetails",SenderDetails);
        
        TournamentPaymentMethods(SenderDetails).then((response)=>{            
            if(response.Messgae === "Data is saved successfuly"){
                localStorage.setItem("TournamentsPaymentID",response.id)
                TournamentPayments(response.id);
            }else{
                console.log(response)
            }
        });
    }

    const TournamentPayments = (ids) => {
        const genSignatureUrl = `${environment.baseUrl}checkout`;
        const params = JSON.stringify({
            "currency": "AED",
            "amount": `${amount * 100}`,
            "customer_email": `${email}`,
            "language": "en",
            "order_description": `JustPadelEventsPay${ids}`,
            //"order_description": "Mina Rashid 4",
            // "merchant_reference": `${name}`,
            "merchant_reference": `TournamentBookingId-${ids}`,
            //Testing payment gateway starts here
            // "access_code": 'MTkAuRmgVl270B82kqey',
            // "merchant_identifier": 'fc9fe2bb',
            // "parse_phrase": '51MO1sxGDZPtHyGATVpuyG!@',
            //Testing payment gateway end here
            "command": "PURCHASE",
            "return_url": "https://api.justpadelae.com/tournaments-payment-return"
        });
        const userToken = localStorage.getItem('Token');

        axios.post(genSignatureUrl, params, {
            "headers": {
                "content-type": "application/json",
                'Authorization': 'Bearer ' + userToken
            },
        })
            .then(function (data) {
                if (data.status === 200 || data.status === 201) {
                    setSignature(data?.data.signature);
                    setTimeout(() => {
                        PaymentLoad();
                    }, 2000);
                } else {
                    alert('Server error')

                }
            })
            .catch(function (error) {
                console.log(error)

            });
    };

    const PaymentLoad = () => {
        document.getElementById("load_payment").submit();
    }

    useEffect(() => {
        handleLogin();        
    }, []);
    



    return (
        <div>{
            signature ?
                <form action="https://checkout.payfort.com/FortAPI/paymentPage" method='post' name='frm' target="_self" id="load_payment" >
    
                    <input type='hidden' name='currency' value='AED' />
    
                    <input type='hidden' name='amount' value={amount * 100} />
    
                    <input type='hidden' name='customer_email' value={email} />
    
                    <input type='hidden' name='command' value='PURCHASE' />
    
                    <input type='hidden' name='language' value='en' />
    
                    <input type='hidden' name='merchant_identifier' value='TXGKcfsS' />
    
                    <input type='hidden' name='access_code' value='9NKhRXOyuU6C1vrXB4rL' />
    
                    <input type='hidden' name='signature' value={signature} />
    
                    <input type='hidden' name='merchant_reference' value={`TournamentBookingId-${parseInt(localStorage.getItem("TournamentsPaymentID"))}`} />
    
                    <input type='hidden' name='order_description' value={`JustPadelEventsPay${parseInt(localStorage.getItem("TournamentsPaymentID"))}`} />
    
                    <input type='hidden' name='return_url' value='https://api.justpadelae.com/tournaments-payment-return' />
    
                </form>
                : ''
        }</div>
    )
}

export default TournamentPayment