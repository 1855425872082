import React, { useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Button, Row } from 'reactstrap';
import { environment } from "../../env";
import { useHistory } from "react-router";
import Navbar from "../Navbar/Navbar";
import FooterMap from "../Footer/FooterMap";
import 'react-calendar/dist/Calendar.css';
import "../Modal/Modal.css";
import { Spinner } from "reactstrap";




const Validateemail = () => {
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [spinner, setSpinner] = useState(false);
  

  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const email = watch("email");

  const onSubmit = (data) => {
    console.log(data);
    // return;
    localStorage.setItem("forgetEmail", data.email);
    const forgotPasswordUrl = `${environment.baseUrl}forgetpassword`;
    axios.post(forgotPasswordUrl, data).then((response) => {
      console.log("response.status", response);
      
      if (response.status === 200) {
      setSpinner(true);
       
      setTimeout(() => {
        setSpinner(false);
      
      // }, 2000).then(() => {
      // history.push("/verify_otp");
      history.push("/verify_otp");

    },2000);

       
      } else if (response.data.Message === "Email Is Not Registered") {
        setEmailInvalid(true);
      } else {
        console.log("Error");
      }
    });
  };

  return (
    <div className="back-shed validate-email-back-shed">
      <Navbar />
      <div className="center-spinner">
        {spinner ? (
          <div className="backgroungSpinner">
            <Spinner />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="account">
        <div className="account__wrapper">
          <div className="account__card" style={{ backgroundColor: "#fff", padding: "45px 55px" }}>
            <div className="account__head" style={{ marginBottom: "20px" }}>
              <h3 className="account__title">Welcome to
                <span className="account__logo"> JUST
                  <span className="account__logo-accent"> PADEL</span>
                </span>
              </h3>
              <h4 className="account__subhead subhead">Padel Courts of Choice</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>

                <div className="form login-form">
                  <div className="form__form-group" style={{ width: "100%" }}>
                    {/* <span className="form__form-group-label" style={{ fontFamily: 'sans-serif', fontSize: 15 }}>
                    {fieldUser}Email Id
                  </span> */}
                    <div className="form__form-group-field" style={{ paddingLeft: 0, textAlign: "initial" }}>
                      <label htmlFor="Email" className="email-label" style={{  }}>Email Id</label>
                      <div style={{ display: "flex", width: "100%" }}>
                        <div className="form__form-group-icon email-icon"
                          style={{
                           
                          }}>
                          <FaRegUser style={{ fontSize: "12px", color: "646777" }} />
                        </div>
                        <input
                          type="email"
                          {...register("email",
                            { required: true })}
                          name="email"
                          component="input"
                          placeholder="Email Id"
                          className="input-without-border-radius email-input"
                        style={{height: "35px",
                        borderRadius: "4px",
                        paddingLeft: "8px"
                       
                      }}
                        />
                      </div>
                    </div>
                    {errors.email && <span style={{color: "red", fontSize:12}}>Please enter the email</span>}

                  </div>
                  <div className="form__form-group" style={{ marginTop: 30 }}>

                    <Button className="account__btn" type="submit" color="warning"  style={{width:"414px", height:41}}>Verify Email</Button>
                    {emailInvalid ? <span className="text-danger">{email} is not registered email</span> : ""}
                  </div>
                </div>
              </Row>
            </form>

          </div>
        </div>
      </div>
      <FooterMap />
    </div>

  );
};

export default Validateemail;
