import React, { useState, useEffect,useMemo } from 'react'
import PhoneInput, {
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import './EditProfileModal.css';
import proImg from '../../Assets/Images/just_padel.png';
import { BsFillCameraFill } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import S3FileUpload from "react-s3";
import swal from "sweetalert";
import { environment } from '../../env';
import axios from '../../CustomAxios';
import { useForm } from "react-hook-form";
import userPic from '../../Assets/Images/userPic.png'
import country_data from './country_data';
import skilllevel_data from './skilllevel_data';



const config = {
  // bucketName: 'test-padel',
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const EditProfileModal = (props) => {
  const [spinner, setSpinner] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [profilePicData, setProfilePicData] = useState();
  const [userData, setUserData] = useState();
  const [cityData, setCityData] = useState([{ label: "Dubai", value: "Dubai" }]);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const user = JSON.parse(localStorage.getItem("LoggedUserDetails"))
  // console.log("user",user);

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const getUserDetails = async () => {
    try {
      const getUserDetailsUrl = `${environment.baseUrl}getUserDetails/${user?.email}/`
      const result = await axios(getUserDetailsUrl).then((res) => {
        console.log("res.data", res.data);
        if(res.data.first_name === "null"){
          res.data.first_name = ""
        }
        if(res.data.last_name === "null"){
          res.data.last_name = ""
        }
        if(res.data.address === "null"){
          res.data.address = ""
        }
        if(res.data.country === "null"){
          res.data.country = ""
        }
        if(res.data.state === "null"){
          res.data.state = ""
        }
        if(res.data.city === "null"){
          res.data.city = ""
        }
        if(res.data.phone_no === "null"){
          res.data.phone_no = ""
        }
        setUserData(res.data)
        props.EditData(res.data)
        localStorage.setItem("LoggedUserDetails",JSON.stringify(res.data))
        reset(res.data)
      })
        .catch((error) => {
          console.log("Error", error);
        })

    } catch (err) {
      console.log("Error", err);
    }
  };

  

  const ProfilePostMethod = (profileData) => {
    const profileURL = `${environment.baseUrl}update_user_details/${user?.email}/`;
    const result = axios
      .put(profileURL, profileData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
    return result;
  };

  const handleImageUpload = e => {
    setProfilePicData();
    const [file] = e.target.files;
    // console.log("file",e.target.files);

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      // console.log("file",file);
      setProfilePicData(file);
    }
  };


  const onSubmit = async (profileData) => {

    if (countryCode && phoneNumber) {
      var countrycode = countryCode.split(" ")[0];
      var phonenumber = phoneNumber.split(countrycode)[1];
      var newContact = countrycode + "-" + phonenumber;
    }

    if (profilePicData === undefined) {
      profileData.profile_pic = userData?.profile_pic;
      profileData.email = userData?.email;
      profileData.phone_no = newContact;
      ProfilePostMethod(profileData).then((profileResponse) => {
        // console.log("profileData.Message", profileResponse);
        if (profileResponse.Message === 'User Updated Successfully') {
          // setSpinner(false);
          swal({
            title: "Success",
            text: 'Profile is updated successfully',
            icon: "success",
            button: "Ok",
          })
            .then(() => {
              getUserDetails();
              props.close();
            })
        }
      });

    } else {

      await S3FileUpload.uploadFile(profilePicData, config)
        .then((data) => {
          console.log("re-update img", data.location);
          if (data.location) {
            profileData.profile_pic = data.location;
            profileData.email = userData?.email;
            profileData.phone_no = newContact;

            ProfilePostMethod(profileData).then((profileResponse) => {
              if (profileResponse.Message === 'User Updated Successfully') {
                swal({
                  title: "Success",
                  text: 'Profile is updated successfully',
                  icon: "success",
                  button: "Ok",
                })
                  .then(() => {
                    props.close();
                  })
              }
            });


          }
        })
        .catch((err) => {
          console.log("Error Info: ", err);
        });
      reset();
    }
  };



  useEffect(() => {
    getUserDetails();
  }, [])


  return (
    <div>
      <div
        className="modal-wrapper"
        style={{
          transform: props.show ? "translateY(0vh)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0",
          zIndex: props.show ? "1" : "-1",
        }}
      >
        <div className="modal-header">
          <span className="close-modal-btn">
            Edit Your Details
          </span>
          <span className="close-modal-btn" onClick={props.close}>
            <AiOutlineClose />
          </span>
        </div>

        <div className="modal-body">
          <form className="inner-login" onSubmit={handleSubmit(onSubmit)}>
            <div className='profileUpload'>
              <div className='ProfileImageContainer'>
                <img ref={uploadedImage? uploadedImage : userPic} src={userData?.profile_pic} alt="profile Image" className='ProfileImage' />
              </div>
              <div className='uploadContainer'>
                <input
                  type="file"
                  name='profile_pic'
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={imageUploader}
                  onChange={handleImageUpload}
                // {...register("profile_pic", { required: false})}
                />
                <BsFillCameraFill
                  className='uploadCameraIcon'
                  onClick={() => imageUploader.current.click()}
                />
              </div>
            </div>
            <div className="Login-Registration-Form">

              <div className="width-left">
                {/* <div className="sign-title">
                <h2>Already existing user? Login Here</h2>
              </div> */}

                {/* <div className="inner-login"> */}
                <div className="inner-lhs" style={{ width: "100%" }}>
                  <div className="textarea-rhs">
                    <div className="input-feilds">
                      <label htmlFor="first_name" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="First Name"
                        {...register("first_name", { required: true })}
                      />
                      {errors.first_name && <span className="text-danger">Please enter First Name</span>}

                    </div>
                    <div className="input-feilds">
                      <label htmlFor="last_name" className="form-label">
                        Last Name 
                      </label>
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        {...register("last_name", { required: true })}
                      />
                      {errors.last_name && <span className="text-danger">Please enter Last Name</span>}
                    </div>
                    
                    <div className="input-feilds">
                      <label htmlFor="phone_no" className="form-label">
                        Phone
                      </label>
                      
                      <PhoneInput
                        defaultCountry="AE"
                        name="phone_no"
                        className="phone-input"
                        value={userData?.phone_no ? userData?.phone_no : ''}
                        onChange={(text) => {
                          setCountryCode(formatPhoneNumberIntl(text));
                          setPhoneNumber(text);
                        }}
                      />

                      {errors.phone_no && <span className="text-danger">Please enter Phone Number</span>}
                    </div>

                    <div className="input-feilds">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Example@gmail.com"
                        readOnly
                        {...register("email", { required: false })}
                      />
                      {errors.email && <span className="text-danger">Please enter email address</span>}
                    </div>
                    

                    <div className="input-feilds">
                        <label htmlFor="gender" className="form-label">
                          Gender
                        </label>
                        <div className='genderContainer'>

                          <input
                            type="radio"
                            id="gender"
                            name="gender"
                            value="male"
                            placeholder="Example@gmail.com"
                            {...register("gender", { required: true })}
                          />

                          Male{"  "}
                          <input
                            type="radio"
                            id="gender"
                            name="gender"
                            value="female"
                            placeholder="Example@gmail.com"
                            {...register("gender", { required: true })}
                          />
                          Female
                        </div>
                        {errors.gender && <span className="text-danger">Please select gender</span>}
                      </div>
                  </div>
                </div>
              </div>

              <hr className="middle-name" />
              <div className="width-right">
                {/* <div className="sign-title">
                <h2>Register Here</h2>
              </div> */}
                <div className="inner-login">
                  <div className="inner-rhs" style={{ width: "100%" }}>

                    <div className="textarea-rhs">
                    
                    <div className="input-feilds">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>

                      <input
                        type="text"
                        id="address"
                        name="address"
                        placeholder='Address'
                        {...register("address", { required: true })}
                      />
                      {errors.address && <span className="text-danger">Please enter Address</span>}
                    </div>
                      <div className="input-feilds">
                        <label htmlFor="city" className="form-label">
                          City
                        </label>
                        <input 
                          type="text"
                          id='city' 
                          name='city'
                           placeholder='City'
                          {...register("city", { required: true })}
                          />
                        {errors.city && <span className="text-danger">Please select city</span>}
                      </div>

                      <div className="input-feilds">
                        <label htmlFor="state" className="form-label">
                          State
                        </label>
                        <input 
                        id='state' 
                        name='state' 
                        placeholder='State' 
                        {...register("state", { required: true })}
                        />
                        {errors.state && <span className="text-danger">Please select state</span>}
                      </div>
                      
                      <div className="input-feilds">
                        <label htmlFor="country" className="form-label">
                          Country 
                        </label>
                        <select
                         id='country' 
                         name='country' 
                         placeholder='Country' 
                         {...register("country", { required: true })}>
                          <option value="" selected="selected">Select Country</option>
                          {country_data.map((data, index) => {
                            return (
                              <option value={data?.value} key={index}>{data.text}</option>
                            )
                          })}

                        </select>
                        {errors.country && <span className="text-danger">Please select country</span>}
                      </div>

                      <div className="input-feilds">
                        <label htmlFor="skilllevel" className="form-label">
                          Skill Level 
                        </label>
                        <select
                         id='skilllevel' 
                         name='skilllevel' 
                         placeholder='skilllevel' 
                         {...register("skilllevel", { required: true })}>
                          <option value="" selected="selected">Select Skill level</option>
                          {skilllevel_data.map((data, index) => {
                            return (
                              <option value={data?.value} key={index}>{data.label}</option>
                            )
                          })}

                        </select>
                        {errors.skilllevel && <span className="text-danger">Please Select Skill level</span>}
                      </div>

                      {/* <div className="input-feilds">
                        <label htmlFor="state" className="form-label">
                        Skill level
                        </label>
                        <input 
                        id='Skill' 
                        name='Skill' 
                        placeholder='Skill' 
                        {...register("Skill", { required: true })}
                        />
                        {errors.state && <span className="text-danger">Select Skill level</span>}
                      </div> */}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <button className='save-Btn' type='submit'>
              Save
            </button>
          </form>
        </div>
        {/* <div className="modal-footer">
            <p>This is Modal footer</p>
        </div> */}
      </div>
    </div>
  )
}


export default EditProfileModal;