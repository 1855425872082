import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { AdminBookingDetails, LoggedOut } from "../HOC/logicWithCustomAxios";
import SVGStatusYellowIcon from "../../Assets/svg/booking_status_yellow.svg";
import SVGStatusBlueIcon from "../../Assets/svg/booking_status_blue.svg";
import SVGStatusCancelledIcon from "../../Assets/svg/booking_status_cancelled.svg";
import BookingModal from "../shared/Modals/BookingModal";
import './BookingHistory.css'
import { useHistory } from 'react-router-dom';
import FooterMap from "../Footer/FooterMap"
import { AiOutlineLeft } from 'react-icons/ai';
import ReactGA from 'react-ga';
import { Spinner } from "reactstrap";

const BookingStatus = (props) => {
    const renderBookingStatusIcon = () => {
        if (props.status.toLowerCase() === `pending` && !props.cancelled) {
            // // Booking Successful - Yellow
            return SVGStatusYellowIcon;
        }
        if (props.status.toLowerCase() === `completed` && !props.cancelled) {
            // Booking Successful -
            return SVGStatusBlueIcon;
        }
        if (props.cancelled) {
            // Booking Cancelled
            return SVGStatusCancelledIcon;
        }
    };

    const renderBookingStatusLabel = () => {
        if (props.status.toLowerCase() === `pending` && !props.cancelled) {
            // // Booking Successful - Yellow
            return `Pending`;
        }
        if (props.status.toLowerCase() === `completed` && !props.cancelled) {
            // Booking Successful -
            return `Booking Successful`;
        }
        if (props.cancelled) {
            // Booking Cancelled
            return `Booking Cancelled`;
        }
    };

    return (
        <div className="bookingStatus">
            <div>
                <img src={renderBookingStatusIcon()} alt={renderBookingStatusIcon()} className="bookingStatusIcon" />
                <span className="bookingStatusText">{renderBookingStatusLabel()}</span>
            </div>
            {/* <span className="TotalAmount">Total Amount: 30.00 AED</span> */}
        </div>
    );
};

const BookingTimeslot = ({ booked_slots, mainCourt }) => {
    // const newbooked_slots = JSON.parse(booked_slots.replace(/'/g, '"'));
    if (typeof booked_slots === "string") {
        var newbooked_slots = JSON.parse(booked_slots.replace(/'/g, '"'));
    } else {
        var newbooked_slots = booked_slots;
    }
    return (
        <div className="bookedSlotsContainer">
            {newbooked_slots.map((data) => {
                return (
                    <>
                        <span className="bookedSlots">
                            {data?.maincourt_name ? data?.maincourt_name : mainCourt} Court - {data?.courtname}, {data?.date}, {data.time}
                        </span>
                    </>
                );
            })}
        </div>
    );
};

const UserBooking = () => {
    const [spinner, setSpinner] = useState(false);
    const [bookingLists, setBookingLists] = useState([]);
    const [singleslot, setSingleslot] = useState();
    const history = useHistory()
    ReactGA.event({
        category: 'Booking History',
        action: 'Booking List',
        label: 'Booking List'
    });
    useEffect(() => {
        const UserId = parseInt(localStorage.getItem("userId"));
        const User = JSON.parse(localStorage.getItem("LoggedUserDetails"));
        try {
            if (UserId === 1 || UserId === null) {
              console.log("UserId", UserId);
            } else {
              setSpinner(true);
            AdminBookingDetails(User?.email).then((response) => {
                let data = response?.filter((data) => { return data?.payment_status === "completed"; });
                setBookingLists(data);
                let newData = data[0]?.booked_slots;
                let duedate = newData;
                if(data?.length){
                    duedate = duedate[0];
                }
                setSingleslot(duedate);
                console.log("duedate", duedate);
                console.log("AdminBookingDetails", response);
                setSpinner(false);
            });
            }
          } catch (err) {
            console.log("err", err);
            setSpinner(false);
          }
    }, []);

    const hitAnalytics = async (_bookingList) => {
        try {
            ReactGA.plugin.require('ecommerce');
            ReactGA.plugin.execute('ecommerce', 'setAction', 'purchase', {
                id: Math.random(),
                affiliation: "Web Booking",
                revenue: 0,
                shipping: 0,
                tax: 0,
                currency: 'AED',
            });
            ReactGA.pageview('/booking-list');
            ReactGA.plugin.execute('ecommerce', 'send');
            ReactGA.plugin.execute('ecommerce', 'clear');
        } catch (e) {

        }

    }

    const GotoMyBookings = () => {
        history.push("/booking-list");
    }
    const PadelholicLeveIs = localStorage.getItem("PadelHolicLeveIs");
    const LoggedUserDetails = JSON.parse(localStorage.getItem("LoggedUserDetails"));
    return (
        <div >
            {/* <Navbar /> */}

            <table className="historyCourtContainer">
                <thead>
                    <th >
                        {/* <div className='UserGreetingMainContainerhistory childgreeting'>
              <div className='UserGreetingContainer childgreeting'>
                <div className='UserGreetingContainer-lhs'>
                  <h4 className='greetingName'>Dear
                   {LoggedUserDetails.first_name == "null" ? "Player" : LoggedUserDetails.first_name},</h4>
                  <span className='greetingEmail'>{LoggedUserDetails.email} | {PadelholicLeveIs}</span>
                </div>
                <div className='UserGreetingContainer-rhs'>
                  <button className='UserGreetingContainer-btn' onClick={GotoMyBookings}>My Bookings</button>
                </div>
              </div>
            </div> */}
                    </th>
                </thead>
                {spinner && <div className="withoutBackgroungSpinner"><Spinner/></div>}
                {bookingLists.length > 0 ? bookingLists?.map((data, index) => {
                    return (
                        <>

                            <div className="inner-table-container">
                                <tbody>
                                    <td>
                                        <div className="HistorycourtDetails">
                                            <div className="court-name">
                                                <div className="court-image-container">
                                                    <div className="court-image">
                                                        <img src={data?.main_court?.maincourtimage} alt={data?.main_court?.maincourtimage} />
                                                    </div>
                                                    <div className="court-name-details">
                                                        <h4 className="">{data?.main_court?.courtName}</h4>
                                                        <h5>ID :{data?.booking_id}, Trans ID : {data?.booking_id}</h5>
                                                        <BookingTimeslot booked_slots={data?.booked_slots} mainCourt={data?.main_court?.courtName} />
                                                    </div>
                                                </div>

                                                <div className="btn-and-paymentStatus">
                                                    <h5><BookingStatus status={data?.payment_status} cancelled={data?.cancelled} amountPercentage={data?.amount_percentage} /></h5>
                                                    <div className="modal-btn">
                                                        <BookingModal
                                                            bookingId={data?.booking_id}
                                                            invoice_date={data.created_at}
                                                            singleslot={singleslot}
                                                            booked_slots={data?.booked_slots}
                                                            addons={data?.addons}
                                                            Coupon={data?.coupan_applied}
                                                            amountPercentage={data?.amount_percentage}
                                                            amountPaid={data?.amount_paid}
                                                            courtName={data?.main_court?.courtName}
                                                            padelcourt={data?.padel_court?.courtname}
                                                            cancelled={data?.cancelled}
                                                            booking_date={data?.booking_date}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>

                                </tbody>
                            </div>

                        </>
                    );
                })
                    : spinner ? "" :<h5 style={{ marginTop: '30px' }}>No Bookings</h5>}
            </table>
            {/* <div className="usrbtn">
                <button className='UserGreetingContainer-btn usrcont' onClick={history.goBack}>
                    <AiOutlineLeft />  Back</button>
            </div> */}

            {/* <div className='history-footer'>
        <FooterMap />
      </div> */}
        </div>


    );
};

export default UserBooking;