import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/Home/Home';
import AboutUs from './components/AboutUs/AboutUs';
import Courts from './components/Courts/Courts';
import Events from './components/Events/Events';
import ContactUs from './components/ContactUs/ContactUs';
import BookNow from './components/BookNow/BookNow';
import BookingList from './components/BookingList/BookingList';
import BookingAddons from './components/BookingAddons/BookingAddon';
import CustomerDetails from './components/CustomerDetailes/CustomerDetails';
import React from 'react'
import Payment from './components/Payment/payment';
import PaymentReturn from './components/PaymentReturn';
import Footer from './components/Footer/Footer';
import BookingHistory from './components/BookingHistory/BookingHistory';
import QuickPay from './components/QuickPay/QuickPay';
import ThankyouPage from './components/ThankyouPage/ThankyouPage';
import PayementError from './components/ThankyouPage/PayementError';
import TournamentPayment from './components/TournamentPayment/TournamentPayment';
import Validateemail from './components/ForgetPassword/Validateemail';
import OTPVerification from './components/ForgetPassword/OTPVerification';
import ResetPassword from './components/ForgetPassword/ResetPassword';
import FailedPayPage from './components/PaymentReturn/FailedPayPage';
import KidsSummerCamp from './components/KidsSummerCamp/KidsSummerCamp';
import AppShare from './components/AppShare/AppShare';
import GoogleSignIn from './components/GoogleSignIn/GoogleSignIn';
import GiftCard from './components/GiftCard/GiftCard';
import GiftCardThankYouPage from './components/ThankyouPage/GiftCardThankYouPage';
import SplitPay from './components/SplitPay/SplitPay';
import MatchShare from './components/MatchShare/MatchShare';
import EventShare from './components/EventShare/EventShare';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/" component={BookNow}/> */}
          <Route exact path="/"><Redirect to="/courts"/> : <BookNow/></Route>
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/courts" component={BookNow} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/contactus" component={ContactUs} />
          <Route exact path="/BookNow" component={BookNow} />
          <Route exact path="/court/:id" component={BookingList} />
          <Route exact path="/booking-addons" component={BookingAddons} />
          <Route exact path="/customer-details" component={CustomerDetails} />
          <Route exact path="/footer" component={Footer} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/payment_return/" component={PaymentReturn} />
          <Route exact path="/booking-list/" component={BookingHistory} />
          <Route exact path="/quick-pay" component={QuickPay} />
          <Route exact path="/thankyou" component={ThankyouPage} />
          <Route exact path="/payment-error" component={PayementError} />
          <Route exact path="/register-for-tournament" component={TournamentPayment} />
          <Route exact path="/validate-email" component={Validateemail} />
          <Route exact path="/verify_otp" component={OTPVerification} />
          <Route exact path="/reset_password" component={ResetPassword} />
          <Route exact path="/failed-return" component={FailedPayPage} />
          <Route exact path="/kids-summer-camp" component={KidsSummerCamp} />
          <Route exact path="/app-share" component={AppShare} />
          <Route exact path="/google-sign-in" component={GoogleSignIn} />
          <Route exact path="/e-gift-card" component={GiftCard} />
          <Route exact path="/e-gift-card-thankyou" component={GiftCardThankYouPage} />
          <Route exact path="/match-share" component={MatchShare} />
          <Route exact path="/event-share" component={EventShare} />
          <Route exact path="/:brandambassador" component={SplitPay} />
        </Switch> 
      </BrowserRouter>
    </div>
  );
}

export default App;
