import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import swal from '@sweetalert/with-react';
import Justpadel from '../../Assets/Images/just_padel.png';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../Footer/Footer';
import SVGStatusBlueIcon from "../../Assets/svg/booking_status_blue.svg";
import './PaymentReturn.css'
import { LoggedOut } from '../HOC/logicWithCustomAxios';
import ReactGA from 'react-ga';
import GreetingUserDetails from '../GreetingUserDetails/GreetingUserDetails';
import FooterMap from "../Footer/FooterMap"


const PaymentReturn = () => {
    const history = useHistory();
    const [data, setData] = useState([]);

    useEffect(() => {
        let BookedSlot = JSON.parse(localStorage.getItem('newPayload'));
        console.log("Booked Slots", BookedSlot.slots);
        setData(BookedSlot?.slots);


        try {
            ReactGA.initialize('UA-192903986-3');
            BookedSlot.slots.map((_data, index) => {
                let anDetails = _data?.maincourt_name + ' - ' + _data?.booking_date;
                ReactGA.plugin.require('ecommerce');
                ReactGA.plugin.execute('ecommerce', 'addItem', {
                    id: _data?.maincourt_name,
                    name: _data?.booking_date + " B- " + _data?.maincourt_name,
                    sku: _data?.booking_date,
                    price: _data?.price,
                    currency: 'AED',
                    quantity: "1",
                    category: "Booking Successful"
                });

                ReactGA.plugin.execute(
                    'ecommerce',
                    'addTransaction',
                    {
                        id: Math.random() + " V- " + _data?.maincourt_name,
                        affiliation: "Affiliation",
                        revenue: 10,
                        shipping: 20,
                        tax: 30,
                        currency: 'AED',
                    }
                );
                ReactGA.plugin.execute('ecommerce', 'send');
                ReactGA.plugin.execute('ecommerce', 'clear');

                ReactGA.event({
                    category: 'Booking Successful',
                    action: anDetails,
                    label: 'completed',
                    value: _data?.price
                });
                console.log("_data ", _data)
                console.log("_data?.price ", _data?.price);
                console.log("anDetails ", anDetails);
                ReactGA.event({
                    category: 'Payment Completed',
                    action: anDetails,
                    label: 'Payment completed',
                    value: _data?.price
                });


            })

        } catch (error) {
            console.log("React Error")
        }


    }, []);

    /*useEffect(() => {
        setData(data);
        let anDetails =data?.maincourt_name +' - '+ data?.booking_date ;
        ReactGA.initialize('UA-192903986-3');
        ReactGA.event({
            category: 'Payment Completed',
            action: anDetails,
            label: 'completed',
            value: data?.price
        });
    }, [data]);*/



    const successBooking = () => {
        swal({
            content: (
                <p>Please Login To View Your Booking List</p>
            )
        }
        )
        // .then((value) => {
        //     var link = "https://www.justpadelae.com/"
        //     window.location.href = link;
        //   });
    };
    const userId = parseInt(localStorage.getItem("userId"));
    const userData = JSON.parse(localStorage.getItem("LoggedUserDetails"));
    const padelholicLevel = localStorage.getItem("PadelHolicLeveIs");
    const slotData = JSON.parse(localStorage.getItem("newPayload"));
    const bookingId = localStorage.getItem("NewBookingId");
    const Amount = parseFloat(localStorage.getItem("finalAmount"));
    const GotoMyBookings = () => {
        history.push("/booking-list");
    };
    return (
        <div>
            <Navbar />
            <GreetingUserDetails />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <div className="thank-you-content">
                    {/* <h5 className='completedStatus'><img src={SVGStatusBlueIcon} alt={SVGStatusBlueIcon}/> &nbsp; Completed</h5> */}
                    <h4>Thank you for the booking!</h4>
                    <p className='payment-textGray'>Your payment is in Process... <br />
                        If your money is not debited, the current booking will be automatically cancelled and <br /> you can rebook the same slot in 15 minutes.
                        For any queries contact our <a href="https://justpadel.ae/contact-us/">customer support center</a> .
                    </p>

                    <h5 >ID: {bookingId} Trans ID: {bookingId}</h5>
                    <h5 >Amount Paid : {Amount} AED</h5>

                    <div className='PaymentMainCourt'>
                        {slotData.slots.map((data, index) => {
                            return (
                                <span className='payment-textGray'>{data?.maincourt_name} Court-{data?.court_name} {data?.booking_date}{" "} {data?.timeslot} </span>
                            )
                        })}
                        {/* <span className='payment-textGray'>Rent Rackets x 3. Rent Ball x 3 </span> */}

                    </div>

                    <div className='payment-btn-container'>
                        {/* {userId !== 1 ?
                        <Link to="/booking-list" className='paymentLinkBtn'>My Bookings</Link> 
                        : <button onClick={GotoMyBookings} className='paymenButtonBtn'> My Bookings</button>
                    } */}
                        <Link to="/" className='paymentLinkBtn'>Back to Homepage</Link>
                    </div>

                </div>

            </div>


            <FooterMap />

        </div>
    )
};
export default PaymentReturn;

