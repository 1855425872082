import axios from "../../CustomAxios";
import { environment } from '../../env';
import oldaxios from "axios";




//----------------------------Function to get main court---------------------------------------//
export const GetMainCourt = () => {
    const mainCourtURL = `${environment.baseUrl}maincourts-admin/`;
    const result = oldaxios.get(mainCourtURL)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
    return result;
};

//-----------------------Function to get booking details by User ID---------------------------//
export const BookingDetailsByID = (id) => {
    const usersBookingUrl = `${environment.baseUrl}get_booking_user/${id}`;
    const result = axios(usersBookingUrl)
        .then((response) => {
            return response.data.data
        })
        .catch((error) => {
            return error;
        })
    return result;
};


export const AdminBookingDetails = (email) => {
    // const adminBookingUrl = `${environment.baseUrl}admin-booking-email/${email}`;
    const adminBookingUrl = `${environment.baseUrl}admin-booking-email/${email}`;
    const result = axios.get(adminBookingUrl)
        .then((response) => {
            console.log(response);
            return response.data.data
        })
        .catch((error) => {
            return error;
        })
    return result;
}

//--------------------Function to get user details by user id-----------------------------//
export const GetUserDetails = (email) => {
    const userDetails = `${environment.baseUrl}getUserDetails/${email}/`;
    const result = axios.get(userDetails)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return error;
        })
    return result;
};

//------------------Function to get padelholic levels------------------------------------//
export const GetPadelholicLevel = () => {
    const getPadelholicDetails = `${environment.baseUrl}getPadelholicsLevel`;
    const result = axios.get(getPadelholicDetails)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return error;
        })
    return result;
}

//-------------------Function to logged out -------------------------------------------//
export const LoggedOut = () => {
    const userId = parseInt(localStorage.getItem("userId"));
    if (userId !== 1) {
        localStorage.removeItem("userId");
        localStorage.removeItem("Token");
        window.location = environment.siteUrl;
    }
};

// -----------------Function for tournament api --------------------------------------//
export const TournamentPaymentMethods = (TournamentPaymentsData) => {
    const TournamentPaymentsURL = `${environment.baseUrl}tournament-payment`;
    const result = axios.post(TournamentPaymentsURL, TournamentPaymentsData)
        .then((TournamentPaymentResponse) => {
            return TournamentPaymentResponse.data
        })
        .catch((error) => {
            return error;
        })
    return result;
};

// -----------------Function for kids summer camp api --------------------------------------//
export const KidsSummerCampMethod = (kidsSummerCampData) => {
    const KidsSummerCampPaymentsURL = `${environment.baseUrl}kids-summer-camp/`;
    const result = axios.post(KidsSummerCampPaymentsURL, kidsSummerCampData)
        .then((KidsSummerCampPaymentResponse) => {
            return KidsSummerCampPaymentResponse.data
        })
        .catch((error) => {
            return error;
        })
    return result;
};