import React, { useState, useEffect, useReducer, useMemo } from "react";
import { Spinner } from "reactstrap";

import Navbar from "../Navbar/Navbar";
import axios from "../../CustomAxios";
import { environment } from "../../env";
import GreetingUserDetails from "../GreetingUserDetails/GreetingUserDetails";
import PhoneInput, {
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import FooterMap from "../Footer/FooterMap";
import { AiOutlineDelete } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";

// ---- CSS Importes ----
import "./bookingAddon.css";
import "react-phone-number-input/style.css";
import "./toggleSwitch.css";
import "react-phone-number-input/style.css";

// ----- Reducer code starts here -----------
const initialState = {
  userCartDetails: [],
  slotLength: 0,
  advancePercentage: "0.5",
  payablePercentage: false,
  isPromotionThere: false,
  addonList: [],
  addonChanges:false,
  couponValue: 0,
  couponCodeValidationMsg:undefined,
  applyForAddon:false,
  coupon: 0,
  appliedCoupon:"",
  // ----------------- state for calculation ------------
  discountedPrice: 0,
  totalAmountWithoutVAT: 0,
  vatAmount: 0,
  finalAmount: 0,
  advanceAmount: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case "userCartDetails":
      return {
        ...state,
        userCartDetails: action.payload,
      };
    case "slotLenght":
      return {
        ...state,
        slotLength: action.payload,
      };
    case "advancePercentage":
      return {
        ...state,
        advancePercentage: action.payload,
      };
    case "payablePercentage":
      return {
        ...state,
        payablePercentage: action.payload
      };
    case "isPromotionThere":
      return{
        ...state,
        isPromotionThere: action.payload
      };

    case "addonList":
      return{
        ...state,
        addonList: action.payload
      };

    case "couponValue":
      return{
        ...state,
        couponValue: action.payload
      };
    
    case "couponCodeValidationMsg":
      return{
        ...state,
        couponCodeValidationMsg: action.payload
      };
    case "applyForAddon":
      return{
        ...state,
        applyForAddon: action.payload
      };
    
    case "coupon":
      return{
        ...state,
        coupon: action.payload
      };
    
      case "appliedCoupon":
        return{
          ...state,
          appliedCoupon: action.payload
        };

    case "discountedPrice":
      return{
        ...state,
        discountedPrice: action.payload
      };

    case "totalAmountWithoutVAT":
      return{
        ...state,
        totalAmountWithoutVAT: action.payload
      };

    case "vatAmount":
      return{
        ...state,
        vatAmount: action.payload
      };

    case "finalAmount":
      return{
        ...state,
        finalAmount: action.payload
      };

    case "advanceAmount":
      return{
        ...state,
        advanceAmount: action.payload
      };

    case "addonChanges":
      return{
        ...state,
        addonChanges: action.payload
      };

    default:
      throw new Error();
  }
}

const BookingAddon = () => {
  const userData = JSON.parse(localStorage.getItem("LoggedUserDetails"));
  const [state, dispatch] = useReducer(reducer, initialState);
  const [spinner, setSpinner] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [disabledPhone, setDisabledPhone] = useState(true);
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState(userData.phone_no);
  const [promotion2, setPromotion2] = useState(false);
  const [disableCoupon, setDisableCoupon] = useState(false);
  const [promotionalData, setPromotionalData] = useState();
  const history = useHistory();

  const getUserCartDetails = async () => {
    let userId = localStorage.getItem("userId");
    const userCartUrl = `${environment.baseUrl}get_cart_user/${userId}`;
    const result = await axios(userCartUrl).then(async (response) => {
      const cartTimeslots = JSON.parse(
        response?.data?.data[0].timeslot.replace(/'/g, '"')
      );
      

      let isPromotionAvailable = cartTimeslots.some(
        (slots) => slots["promotion"] === true
      );

      if (isPromotionAvailable) {
        dispatch({ type: "advancePercentage", payload: "1" });
        dispatch({ type: "payablePercentage", payload: true });
        dispatch({ type: "isPromotionThere", payload: true });
      }

        if (cartTimeslots.length > 1) {
        await Promotioncheck(cartTimeslots[0].booking_date, cartTimeslots[0].maincourt).then(async (promotioncheckResponse)=>{
          setPromotionalData(promotioncheckResponse.data);
          if(promotioncheckResponse.status === "success"){
            await VerifyPromotion2(userId, cartTimeslots[0].maincourt, promotioncheckResponse.data.times).then((verifyPromotionResponse)=>{
              if(!verifyPromotionResponse.promotionused){
                if(cartTimeslots.length>1){
                  setDisableCoupon(true);
                  setPromotion2(!verifyPromotionResponse.promotionused);
                  dispatch({ type: "advancePercentage", payload: "1" });
                  dispatch({ type: "payablePercentage", payload: true });
                  dispatch({ type: "isPromotionThere", payload: true });
                  cartTimeslots[cartTimeslots.length-1].price = promotioncheckResponse.data.additional_slot_price;
                }else{
                  setPromotion2(false);
                }
              }
            })
          }
        })
      }else{
        setPromotion2(false);
      }

      dispatch({ type: "userCartDetails", payload: cartTimeslots });
      dispatch({ type: "slotLenght", payload: cartTimeslots.length });

    });
  };

  const getAddons = async () => {
    const addonListUrl = `${environment.baseUrl}maincourt-addon/`;
    const GetAddonsPayload = {
      maincourt : state?.userCartDetails[0]?.maincourt ? state?.userCartDetails[0]?.maincourt : 10
    };
    const result = await axios.post(addonListUrl, GetAddonsPayload);
    var addonList = [];
    result.data.map((addons)=>{ 
      addonList.push({...addons, qty:0, isChecked: false})
     });
    dispatch({ type: "addonList", payload: addonList });
  };

  const handleCheck = (id) => {
    state.addonList.forEach((addonList)=>{
      if(addonList.id == id){
        addonList.isChecked = !addonList.isChecked;
        addonList.qty = addonList.isChecked ? 1 : 0
      }
    });
    dispatch({ type: "addonList", payload: state.addonList });
    dispatch({ type: "addonChanges", payload: !state.addonChanges });
  };

  const handleMinus = (id) => {
    state.addonList.forEach((addonList)=>{
      if(addonList.id == id && addonList.isChecked){
        if (addonList.qty <= 0) {
          addonList.qty = 0;
        } else {
          addonList.qty--;
        }
      }
    });
    dispatch({ type: "addonList", payload: state.addonList });
    dispatch({ type: "addonChanges", payload: !state.addonChanges });
  };

  const handlePlus = (id) => {
    state.addonList.forEach((addonList)=>{
      if(addonList.id == id && addonList.isChecked){
        addonList.qty++;
      }
    });
    dispatch({ type: "addonList", payload: state.addonList });
    dispatch({ type: "addonChanges", payload: !state.addonChanges });
  };

  const applyCouponCode = async () => {
    callCouponValidate(couponCode);
  };

  const callCouponValidate = async (cCode) => {
    const userId = parseInt(localStorage.getItem("userId"));
    const validateCouponUrl = `${environment.baseUrl}validate_coupon`;

    const validateCouponPayload = {
      name: cCode,
      slot: state.slotLength,
      user: userId,
      bookedUsing: "website",
      bookingDate: state.userCartDetails[0]?.booking_date,
      mainCourt: state.userCartDetails[0]?.maincourt
    };

    await axios.post(validateCouponUrl, validateCouponPayload).then((response)=>{

      if(response?.data?.percentage_of_discount === 100 || response?.data?.percentage_of_discount == "100"){
        dispatch({ type: "advancePercentage", payload: "1" });
        dispatch({ type: "payablePercentage", payload: true });
        dispatch({ type: "isPromotionThere", payload: true });
      }
      
      if (response?.data?.percentage_of_discount) {
        dispatch({ type:"applyForAddon", payload: response?.data?.apply_for_addons});
        dispatch({ type:"couponValue", payload: response?.data?.percentage_of_discount});

        let percentage_discount_jp = response?.data?.percentage_of_discount / 100;
        dispatch({ type:"coupon", payload: percentage_discount_jp});
        dispatch({ type:"appliedCoupon", payload: couponCode});
        dispatch({ type:"couponCodeValidationMsg", payload: "Discount applied successfully!!"});
      } else if (response?.data?.msg) {
        if (response.data.msg == "Coupon is not exist") {
          dispatch({ type:"couponCodeValidationMsg", payload: "Invalid Coupon"});
        } else if (
          response.data.msg ===
          "Coupon is already used or exceeded the limit"
        ) {
          dispatch({ type:"couponCodeValidationMsg", payload: "Coupon is already used or exceeded the limit"});
        } else if (response.data.msg === "Coupon is over") {
          dispatch({ type:"couponCodeValidationMsg", payload: "Coupon usage limit exceeded."});
        }else if (response.data.msg === "Coupon is expired") {
          dispatch({ type:"couponCodeValidationMsg", payload: "Coupon is expired"});
        }else if (response.data.msg === "Coupon limit is over") {
          dispatch({ type:"couponCodeValidationMsg", payload: "Coupon usage limit exceeded."});
        }else if (response.data.msg === "Discounts not applicable for offer price") {
          dispatch({ type:"couponCodeValidationMsg", payload: "Discounts not applicable for offer price"});
        }else if (response.data.msg === "Invalid location for coupon. Please try a different location.") {
          dispatch({ type:"couponCodeValidationMsg", payload: "Invalid location for coupon. Please try a different location."});
        }else {
          dispatch({ type:"couponCodeValidationMsg", payload: "Invalid Coupon"});
        }
      }    
    })
  };

  const payFullAmount = () => {
    if(state.userCartDetails.length>0){
      dispatch({ type: "advancePercentage", payload: !state.payablePercentage ? "1" : "0.5"  });
      dispatch({ type: "payablePercentage", payload: !state.payablePercentage });
    }
  };

  const removeCouponCode = async () => {
    setCouponCode("");
    dispatch({ type:"applyForAddon", payload: false});
    dispatch({ type:"couponValue", payload: 0});
    dispatch({ type:"couponCodeValidationMsg", payload: ""});
    dispatch({ type:"coupon", payload: 0});
    dispatch({ type:"appliedCoupon", payload: ""});
  };

  const onDeleteCourt = (mId, pdId, ts, dt, ct) => {
    console.log("mId, pdId, ts, dt, ct",mId, pdId, ts, dt, ct);
    let existingCartDetails = state.userCartDetails;
    const userId = localStorage.getItem("userId");
    if (ct !== "child") {
      existingCartDetails = existingCartDetails.filter((s) => {
        return s.maincourt !== mId;
      });
    } else {
      existingCartDetails = existingCartDetails.filter((s) => {
        return !(
          s.maincourt === mId &&
          s.court === pdId &&
          s.timeslot === ts &&
          s.booking_date === dt
        );
      });
    };

    dispatch({ type: "userCartDetails", payload: existingCartDetails });
    dispatch({ type: "slotLenght", payload: existingCartDetails.length });

    try {
      const deleteCartPayload = {
        main_court_id: mId,
        court_id: pdId,
        court_name: pdId,
        timeslot: ts,
        date: dt,
        price: "0",
        selected_slot: JSON.stringify(existingCartDetails),
        user: userId,
      };
      const addToCartUrl = `${environment.baseUrl}addtocart`;
      const result = axios.post(addToCartUrl, deleteCartPayload).then(
        (response) => {
          alert("Selected court deleted!");
          if (existingCartDetails.length !== 0) {       
            
            let isPromotionAvailable = existingCartDetails.some(
              (slots) => slots["promotion"] === true
            );
      
            if (isPromotionAvailable) {
              dispatch({ type: "advancePercentage", payload: "1" });
              dispatch({ type: "payablePercentage", payload: true });
              dispatch({ type: "isPromotionThere", payload: true });
            }

            getUserCartDetails();
          } else {
            state.addonList.forEach((addonList)=>{              
                addonList.isChecked = false;
                addonList.qty = 0              
            });

            dispatch({ type: "addonList", payload: state.addonList });

            getUserCartDetails();
            dispatch({ type: "advancePercentage", payload: "0.5" });
            dispatch({ type: "payablePercentage", payload: false });
            dispatch({ type: "isPromotionThere", payload: false });
          }
          
        },
        (error) => {
          console.log("Promise error: ", error);
        }
      );
    } catch (error) {
      console.log("add to cart error: ", error);
    }
  };

  const updatePhone = async () => {
    if (
      phoneNumber !== "" ||
      phoneNumber !== "null" ||
      phoneNumber !== undefined
    ) {
      let countrycode = countryCode.split(" ")[0];
      let phonenumber = countryCode.split(countrycode + " ")[1];
      if (
        !phonenumber ||
        phonenumber === "null" ||
        phonenumber === "" ||
        phonenumber === undefined ||
        phonenumber.length < 7
      ) {
        alert("Please enter valid phone number");
      } else {
        try {
          let newContact = countrycode + "-" + phonenumber;
          userData.phone_no = newContact;        
          const PhonePayload = {
            address: userData.address,
            city: userData.city,
            country: userData.country,
            email: userData.email,
            first_name: userData.first_name,
            gender: userData.gender,
            last_name: userData.last_name,
            phone_no: userData.phone_no,
            profile_pic: userData.profile_pic,
            state: userData.state,
            role:userData.role,
            default_court:userData.default_court,
            no_of_games:userData.no_of_games,
            skilllevel:userData.skilllevel
          };
          const phoneUrl = `${environment.baseUrl}update_user_details/${userData.email}/`;
          const result = await axios.put(phoneUrl, PhonePayload);
          localStorage.setItem("LoggedUserDetails", JSON.stringify(PhonePayload));
          alert("Phone number updated successsfully");
          setDisabledPhone(true);
          return result.data;
        } catch (error) {
          console.log("Error", error);
        }
      }
    } else {
      alert("please enter phone number");
    }
  };


  const handleCustomerDetailsPage = () => {
    setSpinner(true);
    if (countryCode.includes("-")) {
      let countrycode = countryCode.split("-")[0];
      let phonenumber = countryCode.split(countrycode + "-")[1];
      if (
        !phonenumber ||
        phonenumber === "null" ||
        phonenumber === "" ||
        phonenumber.length < 7
      ) {
        alert("Please update phone number");
        setDisabledPhone(true);
        setSpinner(false);
      } else {
        addToCartApiPayload();
      }
    } else if (countryCode.includes(" ")) {
      let countrycode = countryCode.split(" ")[0];
      let phonenumber = countryCode.split(countrycode + " ")[1];
      if (
        !phonenumber ||
        phonenumber === "null" ||
        phonenumber === "" ||
        phonenumber.length < 7
      ) {
        alert("Please update phone number");
        setDisabledPhone(true);
        setSpinner(false);
      } else {
        addToCartApiPayload();
      }
    } else {
      alert("Please enter phone number");
      setSpinner(false);
    }
  };

  const addToCartApiPayload = () => {
    const userId = localStorage.getItem("userId");
    const addToCartUrl = `${environment.baseUrl}addtocart`;
    localStorage.setItem("finalAmount", state.advanceAmount.toFixed(2));
    const payload = {
      court_id: state.userCartDetails[0].court,
      date: state.userCartDetails[0].booking_date,
      price: state.advanceAmount.toFixed(2),
      selected_slot: JSON.stringify([]), //emptying cart.
      user: userId,
    };
    let addOns = [];

    state.addonList?.map((item) => {
      if (item.isChecked) {
        addOns.push(`${item.id}-${item.qty}`);
      }
    });

    if (addOns?.length > 0) {
      payload["addon"] = addOns.join(",");
    }

    localStorage.setItem("cart_payload", JSON.stringify(payload));

    var result = axios
      .post(addToCartUrl, payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          bookCourt();
        } else {
          alert("Server error");
          setSpinner(false);
        }
      })
      .catch((error) => {
        alert(
          error + "Internal error occured. Please try again after sometime."
        );
        setSpinner(false);
      });
  };

  const bookCourt = async () => {
    let loggedUserID = localStorage.getItem("userId");
    const bookCourtUrl = `${environment.baseUrl}courtbooking`;
    let amt = localStorage.getItem("finalAmount");
    const payload = {
      slots: state.userCartDetails,
      user: loggedUserID,
      coupon_code: state.appliedCoupon,
      amount_paid: parseFloat(amt),
      payment_status: "Pending",
      amount_percentage: state.couponValue === 1 ? "1" : state.advancePercentage,
      boooked_using: "website",
    };

    let addOns = [];
    state.addonList?.map((item) => {
      if (item.isChecked) {
        addOns.push(`${item.id}-${item.qty}`);
      }
    });
    
    if (addOns?.length > 0) {
      payload["addon"] = addOns.join(",");
    };


    var result = await axios.post(bookCourtUrl, payload).then((response) => {
      if (response.status === 200 || response.status === 201) {
        
        if(response.data.Message === "Please update your phone no"){
          setSpinner(false);
          swal({
            title: "Warning",
            text: "Please Update Your Phone No, before booking",
            icon: "warning",
            button: "Close",
          }).then(() => {
            setSpinner(false);
            history.push("/booking-addons");
          });
        }
        if (response.data.Message === "Slot not available") {
          setSpinner(false);
          swal({
            title: "Warning",
            text: "Slots not available, try booking again.",
            icon: "warning",
            button: "Close",
          }).then(() => {
            setSpinner(false);
            history.push("/booking-addons");
          });
        }
        localStorage.setItem("NewBookingId", response.data.id);
        if(promotion2){
          UserPromotion(response?.data.id);
        }
        payload["price"] = parseFloat(localStorage.getItem("finalAmount"));
        payload["order_id"] = response?.data?.id;
        payload["court"] = 1;
        localStorage.setItem("newPayload", JSON.stringify(payload));
        let amt = parseFloat(localStorage.getItem("finalAmount"));
       
        if(response?.data?.id){
          if (amt == 0 || amt == 0.0 || amt == 0.00) {
            DiscountedPayment(amt, response?.data?.id);
          }else{
            setTimeout(() => {
              setSpinner(false);
              history.push("/payment");
          }, 1000);
          }
        }else{
          setSpinner(false);
          alert(
            response?.data?.Message +
            ", Please try booking again, Sorry for inconvenience caused."
          );
          
        }
      } else {
        setSpinner(false);
        alert("Server Error");
      }
    });
  };

  const DiscountedPayment = (amount, id) => {
    const paymentReturnPayload = {
      amount: amount,
      response_code: "14000",
      card_number: "000000******0000",
      card_holder_name: userData?.first_name,
      signature:
        "dummy42f1fea10b96091d1d7f356ac54f0c0addd14abeb86dcaded40f7e2e97297685dummy",
      merchant_identifier: "00000000",
      access_code: "MTkAuRmgVl0000000000",
      order_description: "1",
      payment_option: "MASTERCARD",
      expiry_date: "2500",
      customer_ip: "000.000.000.00",
      language: "en",
      eci: "ECOMMERCE",
      fort_id: "169996000000000000",
      command: "PURCHASE",
      response_message: "Success",
      merchant_reference: id,
      authorization_code: "563955",
      customer_email: userData?.email,
      currency: "AED",
      acquirer_response_code: "00",
      status: "14",
    };
    const paymentReturnUrl = `${environment.baseUrl}payment_return_web/`;
    axios.post(paymentReturnUrl, paymentReturnPayload).then((response) => {
      if (response.data.Message === "Booking is Succesfull For Padel Court") {
        setSpinner(false);
        history.push("payment_return/");
      }
    });
  };

  const UserPromotion = (bookingId) =>{
    let loggedUserID = localStorage.getItem("userId");
    const userPromotionUrl = `${environment.baseUrl}promotion-v2-users/`;    
    const UserPromotionPayload = {
      bookingId: bookingId,
      maincourt: state.userCartDetails[0].maincourt,
      bookingstatus: "Pending",
      user: loggedUserID,
    };
    axios.post(userPromotionUrl, UserPromotionPayload);
  };


  const VerifyPromotion2 = (userId, mainCourtId, times) =>{
    const VerifyPromotion2Url = `${environment.baseUrl}promotion-v2-verification/`;
    const VerifyPromotion2Payload = {userId: userId,maincourt: mainCourtId, times:times};
    const result = axios.post(VerifyPromotion2Url, VerifyPromotion2Payload).then((response)=>{
      return response.data
    });
    return result
  };

  const Promotioncheck = (date, maincourtId) => {
    const PromotioncheckUrl = `${environment.baseUrl}promotion-check/`;
    const PromotioncheckPayload = {date: date,maincourt:maincourtId};
    const result = axios.post(PromotioncheckUrl, PromotioncheckPayload).then((response)=>{
      return response.data
    });
    return result
  };


  
  useMemo(() => {
    
    var totalCourtAmount =  state.userCartDetails.reduce((prev, current) => prev + parseInt(current.price), 0);
    let newAddonTotal = state.addonList.filter((addon)=>addon.isChecked == true).reduce((prev, current) => prev + (current.price * current.qty), 0);
    
    
    if(state.applyForAddon){
      let courtAddonPrice = parseFloat(totalCourtAmount + newAddonTotal);
      let discountedPrice = parseFloat(courtAddonPrice * state.coupon);
      dispatch({ type:"discountedPrice", payload: Math.ceil(discountedPrice)});
      
      let totlaAmountsWithoutVat = parseFloat(courtAddonPrice - discountedPrice);      
      dispatch({ type:"totalAmountWithoutVAT", payload: Math.ceil(totlaAmountsWithoutVat)});

      let newVat = parseFloat(totlaAmountsWithoutVat * 0.05);
      dispatch({ type:"vatAmount", payload: Math.ceil(newVat)});

      let newFinalAmount = parseFloat(totlaAmountsWithoutVat + newVat);
      dispatch({ type:"finalAmount", payload: Math.ceil(newFinalAmount)});    
      dispatch({ type:"advanceAmount", payload: Math.ceil(newFinalAmount * state.advancePercentage)});

    }else{
      let discountedPrice = parseFloat(totalCourtAmount * state.coupon);
      dispatch({ type:"discountedPrice", payload: Math.ceil(discountedPrice)});
      
      let totlaAmountsWithoutVat = parseFloat(totalCourtAmount - discountedPrice + newAddonTotal);
      dispatch({ type:"totalAmountWithoutVAT", payload: Math.ceil(totlaAmountsWithoutVat)});

      let newVat = parseFloat(totlaAmountsWithoutVat * 0.05);
      dispatch({ type:"vatAmount", payload: Math.ceil(newVat)});

      let newFinalAmount = parseFloat(totlaAmountsWithoutVat + newVat);
      dispatch({ type:"finalAmount", payload: Math.ceil(newFinalAmount)});  
      dispatch({ type:"advanceAmount", payload: Math.ceil(newFinalAmount * state.advancePercentage)});      
    }
    
  }, [state.userCartDetails, state.addonList, state.couponValue, state.addonChanges, state.coupon, state.advancePercentage]);

  useMemo(() => {
    if(state.userCartDetails){
      var minarashidPresent = state.userCartDetails.some((court)=> court.maincourt == 2);
      if(!minarashidPresent){
        var add = state.addonList.filter((addon)=> addon.id !== 2);
        dispatch({ type: "addonList", payload: add });
      }      
    }

  }, [state.userCartDetails])
  

  useEffect(() => {
    getUserCartDetails();    
    setCountryCode(userData?.phone_no);
    setPhoneNumber(userData?.phone_no);
  }, []);

  useEffect(() => {
    if(state.userCartDetails){
      getAddons();
    }
  }, [state.userCartDetails])
  

  return (
    <div>
      <Navbar />
      <GreetingUserDetails />
      <div className="center-spinner">
        {spinner && (
          <div className="backgroungSpinner">
            {" "}
            <Spinner />{" "}
          </div>
        )}
      </div>

      <div className="calender-Container">
        <div className="inner-calender-addons">
          <div className="inner-lhs">
            <div className="inner-width-container">
              <div className="my-court-head">
                <h2>Just Padel - My Cart</h2>
              </div>
              <div className="my-court">
                {state.userCartDetails.length > 0 ? (
                  state.userCartDetails.map((addToCartPayload) => (
                    <div className="myCourt-inner-container">
                      <div className="myCourt-details">
                        {/* ripe market samsung changes */}
                        {/* {(addToCartPayload?.maincourt === 5 && addToCartPayload?.court_name === "3")? (<h5 className="court-numbers">
                          {addToCartPayload?.maincourt_name} - Court{" "}
                          {"Samsung"}
                        </h5>):(<h5 className="court-numbers">
                          {addToCartPayload?.maincourt_name} - Court{" "}
                          {addToCartPayload?.court_name}
                        </h5>)} */}
                        <h5 className="court-numbers">
                          {addToCartPayload?.maincourt_name} - Court{" "}
                          {addToCartPayload?.display_name}
                        </h5>
                        <h5 class="court-timing">
                          {addToCartPayload?.booking_date} |{" "}
                          {addToCartPayload?.timeslot}
                        </h5>
                      </div>
                      <div className="myCourt-details">
                        <h5 class="court-timing-amount">{`AED ${addToCartPayload?.price}`}</h5>
                      </div>
                      <div>
                        <button
                          className="remove-btn"
                          onClick={
                            () => 
                            onDeleteCourt(
                              addToCartPayload?.maincourt,
                              addToCartPayload?.court,
                              addToCartPayload?.timeslot,
                              addToCartPayload?.booking_date,
                              addToCartPayload?.court_type
                            )
                          }
                        >
                          <AiOutlineDelete className="faTrashAlt" />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="addon">
                    <div className="addon-details">
                      <h6
                        style={{
                          fontFamily: "Verdana, sans-serif",
                          fontSize: 15,
                        }}
                      >
                        No court added to cart.
                      </h6>
                    </div>
                  </div>
                )}
              </div>

              <div className="my-court-head">
                <h2>Add On's</h2>
              </div>
              <div className="addons">
                {state.addonList.map((data) => (

                    <div className="addons-content">
                      
                      <div style={{ paddingBottom: 15 }}>
                        <input
                          name="isGoing"
                          type="checkbox"
                          id="add-Checkbox"
                          checked={data.isChecked}
                          onClick={() => handleCheck(data.id)}
                        />
                      </div>
                      <div className="addons-inner-container">
                        <div className="addons-inner">
                          <div className="inner-addons">
                            <div className="rents">
                              <h2>{data.addonname}</h2>
                              <small>({data.description})</small>
                            </div>
                          </div>
                          <div className="addons-minuts">
                            <h4>AED {data.price}</h4>
                          </div>
                        </div>

                        <div className="addons-inner">
                          <div className="rents qty">
                            <h3>Qty.</h3>
                          </div>
                          <div className="select-minuts">
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              className="faMinus"
                              onClick={() => handleMinus(data.id)}
                            />
                            <small>{data.qty}</small>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="faPlus"
                              onClick={() => data.qty < 4  &&  handlePlus(data.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="inner-rhs">
            <div className="inner-width-container">
              <div className="my-court-head-rhs">
                <h2>Coupon Code </h2>
              </div>
              <div className="coupan-input-container">
                <h5>Coupon Code </h5>
                <div className="coupan-input">
                  <input type="text" placeholder="E.g ABHF4354" value={couponCode} onChange={(e)=>setCouponCode(e.target.value)}  />
                  {!state.couponValue ? (
                    <button onClick={applyCouponCode} disabled={ !couponCode || disableCoupon } > Apply </button>
                  ) : (
                    <button onClick={removeCouponCode} >Remove</button>
                  )}
                </div>
              </div>
              <div>
                {state.couponCodeValidationMsg && ( <div className="form__form-group-label" style={{ fontSize: 15 }} > {couponCode} - {state.couponCodeValidationMsg} </div>  )}
              </div>
              <div className="my-court-head-rhs">
                <h2>Total Amount</h2>
              </div>
              <div className="total-amount">
                <div className="total-details">
                  <div>Amount</div>
                  <div className="amount-text">AED {state.totalAmountWithoutVAT.toFixed(2)}</div>
                </div>
                <div className="total-details">
                  <div>VAT 5%</div>
                  <div className="amount-text">AED {state.vatAmount?.toFixed(2)}</div>
                </div>
                <div className="total-details">
                  <div>Total Amount</div>
                  <div className="amount-text">AED {state.finalAmount?.toFixed(2)}</div>
                </div>
                <div className="total-details">
                  <div>Discounted price</div>
                  <div className="amount-text">{state.discountedPrice?.toFixed(2)} AED</div>
                </div>
              </div>
              {/* ------------------------------Pay full amount code-----------Start--------------------------------- */}
              <div></div>
              <div className="my-court-head-rhs">
                <h2>Advance Payable Amount</h2>
              </div>
              <div className="total-amount">
                <div className="total-details">
                  <div>Pay full amount in advance</div>
                  <div>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="paycheck"
                        checked={state.payablePercentage}
                        onClick={state.isPromotionThere ? console.log("") : payFullAmount}
                      />
                      <label className="label" htmlFor="paycheck">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="total-details">
                  {state.advancePercentage == "1" ? (
                    <div>Advance Payable (100%)</div>
                  ) : (
                    <div>Advance Payable (50%)</div>
                  )}
                  <div style={{ fontWeight: "300", color: "#adadad" }}>
                    To be paid at facility
                  </div>
                </div>
                <div className="total-details">
                  <div>AED {state.advanceAmount.toFixed(2)}</div>
                  <div>AED {state.advanceAmount.toFixed(2)}</div>
                </div>
              </div>

              <div className="my-court-head-rhs">
                <h2>
                  Phone Number <span style={{ color: "red" }}>*</span>
                </h2>
              </div>

              <div className="coupan-input ">
                <PhoneInput
                  defaultCountry="AE"
                  value={userData?.phone_no ? userData?.phone_no : ""}
                  name="phone_no phone"
                  required
                  className="phone-input"
                  style={{ marginRight: "12px" }}
                  onChange={(text) => {
                    setDisabledPhone(false);
                    setCountryCode(formatPhoneNumberIntl(text));
                    setPhoneNumber(text);
                  }}
                />

                <button style={{ marginRight: "0px", height: "38px" }} disabled={disabledPhone} onClick={updatePhone}>
                  Update
                </button>
              </div>
              {/* ------------------------------Pay full amount code---------End----------------------------------- */}
            </div>
          </div>
        </div>
        <div className="total-Amount">
          <div>
            <h2>Total Payable : AED {state.advanceAmount.toFixed(2)}</h2>
          </div>
          <div className="payment-btn">
            <button className="payment" onClick={handleCustomerDetailsPage}>Proceed to Payment</button>
            <button className="cancel">Cancel</button>
          </div>
        </div>
      </div>
      <FooterMap />
    </div>
  );
};

export default BookingAddon;
